export const PRIMARY_COLOR = 'rgb(38, 38, 38)';
export const SECONDARY_COLOR = 'rgb(146,138,138)';

export const ACCENT_COLOR = '#1da66e';
export const INFO_COLOR = '#408EEF';
export const WARNING_COLOR = '#db4f00';

export const HIGHLIGHT_COLOR = '#3f3f3f';
export const HIGHLIGHT_BORDER_COLOR = '#1890ff';
export const HIGHLIGHT_INFORMATION_COLOR = 'rgba(59, 130, 246, 0.5)';

export const CARD_BACKGROUND = '#f8f3ee';
export const FILTER_COLOR = '#58595b';

export const BASE_COLOR = {
  white: '#ffffff',
  black: '#262626',
  gray: {
    100: '#f9f9f9',
    200: '#F7F7F7',
    300: '#f4f4f4',
    400: '#F3F3F3',
    500: '#f1f1f1',
    600: '#EdEdEd',
    700: '#E6E6E6',
    800: '#C2C3CC',
    900: '#bdbdbd',
    1000: '#919191',
    2000: '#3f3f3f',
  },
  text: {
    bold: PRIMARY_COLOR,
    medium: PRIMARY_COLOR,
    regular: PRIMARY_COLOR,
    light: PRIMARY_COLOR,
    label: PRIMARY_COLOR,
  },
  shadow: {
    base: '0 3px 6px rgba(0, 0, 0, 0.16)',
    medium: '0 6px 12px rgba(0, 0, 0, 0.16)',
    big: '0 21px 36px rgba(0, 0, 0, 0.16)',
    header: '0 1px 2px rgba(0, 0, 0, 0.06)',
  },
  transparent: 'transparent',
  primary: {
    regular: PRIMARY_COLOR,
    hover: PRIMARY_COLOR,
  },
  secondary: {
    regular: SECONDARY_COLOR,
    hover: PRIMARY_COLOR,
  },
  yellow: {
    regular: '#FFAD5E',
    hover: '#FFB369',
    alternate: '#f4c243',
  },
  blue: {
    regular: '#2e70fa',
    dark: '#161F6A',
    light: '#666D92',
    link: '#4285f4',
  },
  red: '#ea4d4a',
  success: '',
  warning: '',
  muted: '',
  headingsBackgroundColorMobile: '#fbfbfd',
};
