import { ACCENT_COLOR } from '@constants';
import styled from 'styled-components';

interface StatusProps {
  status: string;
}

// https://codepen.io/shra-one/pen/xxwqPgG
export const ContainerVerticalSteps = styled.div`
  display: inline-block;

  .steps {
    &__item {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const StepsLabel = styled.div<StatusProps>`
  --icon-size: 23px;

  position: relative;
  padding-left: calc(var(--icon-size) + 10px);
  margin: 16px 0;
  text-align: left;

  &:before,
  &:after {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
  }

  &:before {
    width: var(--icon-size);
    height: var(--icon-size);
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ccc;
  }

  &:after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
    left: 11px;
  }

  ${(props) =>
    props.status === '--complete'
      ? `
        font-weight: bold;
        color: ${ACCENT_COLOR};

        &:after {
          width: 5px;
          height: 11px;
          border-radius: 0;
          background: transparent;
          border: 2px solid white;
          border-top: transparent;
          border-left: transparent;
          transform: rotate(45deg) translateY(-50%);
          top: 36%;
          left: 6px;
        }

        &:before {
          background-color: ${ACCENT_COLOR};
          border:2px solid ${ACCENT_COLOR};
        }
    `
      : props.status === '--current' &&
        `
        font-weight: bold;

        &:before {
          background-color: #fff;
          border:2px solid ${ACCENT_COLOR};
        }
  `}
`;

export const StepsSpace = styled.div<StatusProps>`
  --icon-size: 30px;
  --line-min-height: 30px;

  position: relative;
  margin-left: calc(var(--icon-size) / 2);
  flex: 1 0 var(--line-min-height);
  padding: var(--line-min-padding);
  box-sizing: border-box;

  @at-root .steps__item:last-child & {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: ${(props) =>
      props.status === '--complete'
        ? ACCENT_COLOR
        : props.status === '--current'
        ? `var(--blue-500)`
        : '#ccc'};
  }
`;
