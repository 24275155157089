import { IS_MESSAGING } from '@constants';
import { SolutionBuilderActionEnum } from 'enums';
import { SolutionBuilderStateType } from 'types';
import { SolutionBuilderActionType } from 'types/action-types';

const INITIAL_STATE: SolutionBuilderStateType = {
  value: IS_MESSAGING,
};

export const solutionBuilder = (
  state: SolutionBuilderStateType = INITIAL_STATE,
  action: SolutionBuilderActionType
): SolutionBuilderStateType => {
  switch (action.type) {
    case SolutionBuilderActionEnum.SET_STATE:
      return { ...state, ...action.payload };
    case SolutionBuilderActionEnum.CLEAR_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};
