import { ExpertActionEnum } from 'enums';
import { ExpertType } from 'types';
import { ExpertActionType } from 'types/action-types';

const INITIAL_STATE: ExpertType = {
  id: '',
  isChosen: false,
  expert_id: '',
  category_id: '',
  expertise_id: '',
  description: '',
  image: '',
  name: '',
  designation: '',
  company: '',
  experience: 0,
  audio: '',
  questions: '',
};

export const expert = (
  state: ExpertType = INITIAL_STATE,
  action: ExpertActionType
): ExpertType => {
  switch (action.type) {
    case ExpertActionEnum.SET_EXPERT:
      return { ...state, ...action.payload };
    case ExpertActionEnum.CLEAR_EXPERT:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};
