import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { expertActions, RootState } from 'state';
import { useMemo } from 'react';
import { ExpertType } from 'types';
export const useExpert = () => {
  const _expert = useSelector((state: RootState) => state.expert);
  const dispatch = useDispatch();

  const { setExpert, clearExpert } = bindActionCreators(
    expertActions,
    dispatch
  );

  const expert = useMemo<ExpertType>(() => {
    return { ..._expert };
  }, [_expert]);

  return {
    expert: expert,
    setExpert,
    clearExpert,
  };
};
