import { bindActionCreators } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stepActions, RootState } from 'state';
import { StepType } from 'types';

export const useStep = () => {
  const _step = useSelector((state: RootState) => state.step);
  const dispatch = useDispatch();

  const { setStep, clearStep } = bindActionCreators(stepActions, dispatch);

  const step = useMemo<StepType>(() => {
    return { ..._step };
  }, [_step]);

  return {
    step: step,
    setStep,
    clearStep,
  };
};
