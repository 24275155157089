import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { solutionBuilderActions, RootState } from 'state';

export const useSolutionBuilder = () => {
  const state = useSelector((state: RootState) => state.solutionBuilder);
  const dispatch = useDispatch();

  const { setBuilderState, clearBuilderState } = bindActionCreators(
    solutionBuilderActions,
    dispatch
  );

  return {
    state: state,
    setBuilderState,
    clearBuilderState,
  };
};
