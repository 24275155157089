import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import FilterList from './filter-list';
import { SearchFilter, DoneButton } from 'components';

type ExpertsFilerDialogProps = {
  open: boolean;
  onHide: () => void;
  onFilterSelection: () => void;
};

export type FilterType = {
  id: number;
  position: number;
  label: string;
};

const filters: FilterType[] = [
  {
    id: 1,
    position: 1,
    label: 'Industry vet with depth of experience',
  },
  {
    id: 2,
    position: 2,
    label: 'Creative and innovative in their thinking',
  },
  {
    id: 3,
    position: 3,
    label: 'Demonstratable case studies',
  },
  {
    id: 4,
    position: 4,
    label: 'Practical and realistic',
  },
  {
    id: 5,
    position: 5,
    label: 'Small business focus  ',
  },
  {
    id: 6,
    position: 6,
    label: 'Enterprise focus',
  },
  {
    id: 7,
    position: 7,
    label: 'Start up experience',
  },
  {
    id: 8,
    position: 8,
    label: 'Budget conscious',
  },
  {
    id: 9,
    position: 9,
    label: 'Timeline fanatic',
  },
];

export const ExpertsFilerDialog = ({
  open,
  onHide,
  onFilterSelection,
}: ExpertsFilerDialogProps) => {
  return (
    <>
      <Dialog
        modal
        style={{ minHeight: '500px', maxWidth: '800px' }}
        visible={open}
        onHide={onHide}
        header={false}
        draggable={false}
        resizable={false}
        className="goals_modal"
        footer={<DoneButton onDone={onHide} />}
      >
        <div className="px-3">
          <div className="mb-5">
            <SearchFilter
              label="Select step(s) to add to your solution plan:"
              placeholder="Search a solution plan"
            />
          </div>

          <FilterList options={filters} onFilterSelection={onFilterSelection} />
        </div>
      </Dialog>
    </>
  );
};
