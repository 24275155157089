import { useReducer } from 'react';

import { ChatContext } from './chat.context';
import { chatReducer } from './chat.reducer';
import { ChatActionEnum } from 'enums';
import { ChatsType, ChatType, ChatAddMessageType } from 'types';

type ChatProviderProps = {
  children: React.ReactNode;
};

const initialState: ChatsType = {
  chats: [],
};

export const ChatProvider = ({ children }: ChatProviderProps) => {
  const [chats, dispatch] = useReducer(chatReducer, initialState);

  const addChat = (chat: ChatType) => {
    dispatch({ type: ChatActionEnum.ADD, payload: chat });
  };

  const addChatMessage = (message: ChatAddMessageType) => {
    dispatch({ type: ChatActionEnum.ADD_MESSAGE, payload: message });
  };

  return (
    <ChatContext.Provider value={{ chats, addChat, addChatMessage }}>
      {children}
    </ChatContext.Provider>
  );
};
