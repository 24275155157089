import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

import { AuthRedirectButton } from './auth-redirect-button';
import { CustomToolbar, FormContainer } from './login.style';
import { UserLogin } from 'types/dtos';
import { classNames } from 'primereact/utils';
import { ErrorMessage } from 'components';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit,
} from 'react-hook-form';

type LoginFormProps = {
  errors: FieldErrors<UserLogin>;
  control: Control<UserLogin, any>;
  handleSubmit: UseFormHandleSubmit<UserLogin>;
  rememberMe: boolean;
  loading: boolean;
  onSubmit: (user: UserLogin) => void;
  onRedirectSignUp: () => void;
  onChangeRememberMe: (value: boolean) => void;
  onPasswordRecovery: () => void;
};

export const LoginForm = ({
  control,
  errors,
  onChangeRememberMe,
  onPasswordRecovery,
  onRedirectSignUp,
  onSubmit,
  rememberMe,
  handleSubmit,
  loading,
}: LoginFormProps) => {
  return (
    <>
      <FormContainer>
        <form className="p-fluid" onSubmit={handleSubmit(onSubmit)}>
          <div className="field">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  className={classNames(errors.email && 'p-invalid')}
                  placeholder="Email"
                />
              )}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </div>

          <div className="field">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Password
                  {...field}
                  className={classNames(errors.password && 'p-invalid')}
                  placeholder="Password"
                  feedback={false}
                  toggleMask
                  ref={(ref) => field.ref({ focus: () => {} })}
                />
              )}
            />
            {errors.password && (
              <ErrorMessage>{errors.password.message}</ErrorMessage>
            )}
          </div>

          <CustomToolbar
            left={
              <div className="field-checkbox">
                <Checkbox
                  name="terms"
                  checked={rememberMe}
                  onChange={() => onChangeRememberMe(!rememberMe)}
                />
                <label className="checkbox-rememberme">Remember Me</label>
              </div>
            }
            right={
              <div className="field">
                <Button
                  className="p-button-text"
                  label="Forgot password?"
                  type="button"
                  onClick={onPasswordRecovery}
                />
              </div>
            }
          />

          <div className="field">
            <Button label="Sign In" type="submit" disabled={loading} />
          </div>
        </form>

        <AuthRedirectButton
          message="Don’t have an account?"
          linkLabel="Sign up now"
          onRedirect={onRedirectSignUp}
        />
      </FormContainer>
    </>
  );
};
