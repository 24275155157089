import { userSvg } from 'assets';

/**
 * Handle image loading/render errors
 */
export const handleImageError = (
  event: React.SyntheticEvent<HTMLImageElement, Event>
) => {
  /* Nullish to prevent loop */
  event.currentTarget.onerror = null;
  if (event.currentTarget.src !== userSvg) event.currentTarget.src = userSvg;
};

/**
 * Handle (Primereact Component) avatar image loading/render errors
 */
export const handleAvatarImageError = (event: React.SyntheticEvent) => {
  try {
    const e = event as React.SyntheticEvent<HTMLImageElement, Event>;

    /* Nullish to prevent loop */
    e.currentTarget.onerror = null;
    if (e.currentTarget.src !== userSvg) e.currentTarget.src = userSvg;
  } catch (error) {
    throw error;
  }
};
