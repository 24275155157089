import React, { useEffect, useRef, useState } from 'react';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { TabPanel } from 'primereact/tabview';

import { NoSelectedDisplay } from 'components';
import { ExpertType, MessageDto } from 'types';
import { ChatHeader, ChatTabView, SendIcon } from './expert-messaging.style';
import { userSvg } from 'assets';
import { handleAvatarImageError } from 'utilities';

type ExpertsChatProps = {
  expert: ExpertType;
  messages: MessageDto[];
  onSendMessage: (message: string) => void;
  openDeleteExpert: () => void;
  openExpertProfileDialog: (expert: ExpertType) => void;
};

const ExpertChat = ({
  expert,
  messages,
  onSendMessage,
  openDeleteExpert,
  openExpertProfileDialog,
}: ExpertsChatProps) => {
  const [message, setMessage] = useState<string>('');
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chat h-full">
      <ChatTabView className="h-full">
        <TabPanel
          className="h-full flex flex-column"
          headerClassName="hidden max-h-0 opacity-0"
          contentClassName="h-full"
        >
          <ChatHeader className="flex justify-content-between">
            <div className="flex align-items-center">
              <Avatar
                size="xlarge"
                shape="circle"
                image={expert?.image ? expert?.image : userSvg}
                imageAlt={expert?.name}
                onImageError={handleAvatarImageError}
              />
              <strong className="ml-2">{expert?.name}</strong>
            </div>
            <div>
              {/* <Button
                className="p-button-outlined p-button-rounded p-button-info mr-1"
                icon="pi pi-eye"
                onClick={() => openExpertProfileDialog(expert)}
              />
              <Button
                className="p-button-outlined p-button-rounded p-button-danger"
                icon="pi pi-trash"
                onClick={openDeleteExpert}
              /> */}
            </div>
          </ChatHeader>

          <div className="chat h-full flex flex-column flex-wrap">
            <span className="fade flex-grow-0"></span>

            <div className="chat-content">
              {/* <div className="chat-message">
                <span className="name">You</span>
                <div className="message">
                  <p>
                    Hey M. hope you are well. Our idea is accepted by the board.
                    Now it’s time to execute it.
                  </p>
                  <span>3 mins ago</span>
                </div>
                <div className="message">
                  <p>we did it! 🤠</p>
                  <span>3 mins ago</span>
                </div>
              </div> */}
              {messages.length > 0 ? (
                messages.map((msg, index) => {
                  return (
                    <div className="chat-message send" key={index}>
                      <span className="name">You</span>
                      <div className="message">
                        <p>{msg.message}</p>
                        <span>{msg.created}</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <NoSelectedDisplay
                  title={
                    <span>
                      You have no messages with
                      <span className="font-bold"> {expert.name}</span>
                    </span>
                  }
                  buttonIcon="pi pi-send"
                  buttonLabel="Send a greeting"
                  onSelect={() => {
                    onSendMessage('Hi ' + expert.name + ' 😃');
                  }}
                />
              )}
              <div ref={messagesEndRef} />
            </div>

            <div className="chat-input flex-grow-0">
              <form
                className="p-fluid"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSendMessage(message);
                  setMessage('');
                }}
              >
                <span className="p-input-icon-right">
                  <InputText
                    type="text"
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <SendIcon
                    className={`pi pi-send cursor-pointer text-4xl botom-50`}
                    onClick={() => {
                      onSendMessage(message);
                      setMessage('');
                    }}
                  />
                </span>
              </form>
            </div>

            <div>
              <Button
                label="View profile"
                className="p-button-text p-button-rounded p-button-secondary"
                icon="pi pi-eye"
                onClick={() => openExpertProfileDialog(expert)}
              />
              <Button
                label="Remove expert"
                className="p-button-text p-button-rounded p-button-secondary"
                icon="pi pi-trash"
                onClick={openDeleteExpert}
              />
            </div>
          </div>
        </TabPanel>
      </ChatTabView>
    </div>
  );
};

export default ExpertChat;
