import { Dialog } from 'primereact/dialog';

import { TwoButtons } from 'components';

type ConfirmDialogProps = {
  open: boolean;
  title?: string;
  type?: string;
  message?: string;
  acceptLabel?: string;
  rejectLabel?: string;
  onHide: () => void;
  onAccept: () => void;
  onReject?: () => void;
};

export const ConfirmDialog = ({
  open,
  title,
  message,
  onHide,
  onAccept,
  onReject,
  rejectLabel = 'No, cancel',
  acceptLabel = 'Yes, delete',
}: ConfirmDialogProps) => {
  const headerDialog = () => <div className="text-center">{title}</div>;

  const footerDialog = () => (
    <TwoButtons
      leftLabel={rejectLabel}
      rightLabel={acceptLabel}
      onLeftClick={onReject || onHide}
      onRightClick={() => {
        onAccept();
        onHide();
      }}
    />
  );
  return (
    <Dialog
      style={{ maxWidth: '400px' }}
      visible={open}
      header={headerDialog}
      footer={footerDialog}
      onHide={onHide}
    >
      <div className="text-center">{message}</div>
    </Dialog>
  );
};
