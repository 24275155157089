import { LoadingText } from './loading.style';

export const Loading = () => {
  return (
    <div className="flex justify-content-center align-items-center">
      <div>
        <div className="loading">
          <div className="circle1"></div>
          <div className="circle2"></div>
          <div className="circle3"></div>
          <div className="circle4"></div>
        </div>

        <br />

        <LoadingText>Loading...</LoadingText>
      </div>
    </div>
  );
};
