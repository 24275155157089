import { StepAvailableContainer } from 'components/goals/steps-selection/steps-selection.style';
import { FilterType } from './experts-filter-dialog';

type FilterItemProps = {
  option: FilterType;
  onFilterSelection: () => void;
};
const FilterItem = ({ option, onFilterSelection }: FilterItemProps) => {
  const { label } = option;
  return (
    <>
      <div className="col-6 md:col-4">
        <StepAvailableContainer
          className="flex flex-column cursor-pointer justify-content-between h-full shadow-2 border-round text-center p-3"
          onClick={() => onFilterSelection()}
        >
          <div className="title font-bold">{label}</div>
        </StepAvailableContainer>
      </div>
    </>
  );
};

export default FilterItem;
