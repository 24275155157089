type AuthRedirectButtonProps = {
  message: string;
  linkLabel: string;
  onRedirect: () => void;
};

/**
 * This FC refers a button to other option to auth
 */
export const AuthRedirectButton = ({
  linkLabel,
  message,
  onRedirect,
}: AuthRedirectButtonProps) => {
  return (
    <div>
      <p className="text-center pt-4 text-small text-dark">
        {message}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="p-link ml-1 underline" onClick={onRedirect}>
          {linkLabel}
        </a>
      </p>
    </div>
  );
};
