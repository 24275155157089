import React, { useEffect, useState } from 'react';
import App from './App';
import ReactGA from 'react-ga';
import jwtDecode from 'jwt-decode';
import { setLocale } from 'yup';
import { Toast } from 'primereact/toast';

import { withRouter, useLocation, Switch } from 'react-router-dom';
import { LIGHT_THEME_NAME, SITE_INFO } from './@constants';
import { PasswordRecovery, LoginPage, SignupPage } from 'pages';
import { PublicRoute, PrivateRoute } from './routes';
import { useAuth } from 'hooks';
import { useErrorToastMessage } from 'context/error/error';
import { ToastProvider, ChatProvider } from 'context';
import { yupLocaleMessages } from 'utilities';

export const ctx = React.createContext();

const AppWrapper = () => {
  const location = useLocation();

  const { toasErrorMessage } = useErrorToastMessage();
  const { isAuth, token, expiresIn, logout } = useAuth();
  const [colorScheme, setColorScheme] = useState(LIGHT_THEME_NAME);

  /**
   * Auto logout an expiration token
   */
  if (token && expiresIn) {
    const jwt = jwtDecode(token);

    if (jwt.exp) {
      const expirationDate = new Date(jwt.exp * 1000 - 60000);
      const currentDate = new Date();

      const time = expirationDate - currentDate;

      setTimeout(() => {
        logout();
      }, time);
    }
  }

  /**
   * react-hook-form gobal messages
   */
  setLocale(yupLocaleMessages);

  /**
   * Listeners
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  /**
   * Google Analytics, init
   */
  useEffect(() => {
    if (SITE_INFO.isProductionServer) {
      ReactGA.initialize(SITE_INFO.GA_TRACKING_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <ChatProvider>
      <ToastProvider>
        <Toast ref={toasErrorMessage} />

        <Switch>
          <PublicRoute
            exact
            path="/login"
            component={LoginPage}
            isAuthenticated={isAuth}
            colorScheme={colorScheme}
          />
          <PublicRoute
            exact
            path="/sign-up"
            component={SignupPage}
            isAuthenticated={isAuth}
          />
          <PublicRoute
            exact
            path="/password-recovery"
            component={PasswordRecovery}
            isAuthenticated={isAuth}
          />
          <PublicRoute
            exact
            path="/create-password/:code"
            component={PasswordRecovery}
            isAuthenticated={isAuth}
          />
          <PrivateRoute
            path="/"
            component={App}
            setColorScheme={setColorScheme}
            isAuthenticated={isAuth}
          />
        </Switch>
      </ToastProvider>
    </ChatProvider>
  );
};

export default withRouter(AppWrapper);
