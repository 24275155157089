import React, { useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

const MenuButton = ({ onEditGoal, onDuplicateGoal, onDeleteGoal, goal }) => {
  const menu = useRef(null);

  const toggleMenu = (event) => {
    menu.current.toggle(event);
  };

  const overlayMenuItems = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: (e) => {
        e.originalEvent.stopPropagation();
        onEditGoal(goal);
      },
    },
    {
      label: 'Duplicate',
      icon: 'pi pi-clone',
      command: (e) => {
        e.originalEvent.stopPropagation();
        onDuplicateGoal(goal);
      },
    },
    {
      separator: true,
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: (e) => {
        e.originalEvent.stopPropagation();
        onDeleteGoal(goal);
      },
    },
  ];

  return (
    <>
      <Menu ref={menu} model={overlayMenuItems} popup />
      <Button
        icon="pi pi-ellipsis-v"
        className="p-button-rounded p-button-plain p-button-text absolute top-0 right-0 m-3"
        onClick={(e) => {
          e.stopPropagation();
          toggleMenu(e);
        }}
      />
    </>
  );
};

export default MenuButton;
