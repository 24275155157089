import { applicationState } from './application-state.reducer';
import { auth } from './auth.reducer';
import { expert } from './expert.reducer';
import { combineReducers } from '@reduxjs/toolkit';
import { goal } from 'state/reducers/goal.reducer';
import { profile } from './profile.reducer';
import { step } from 'state/reducers/step.reducer';
import { solutionBuilder } from 'state/reducers/solution-builder.reducer';
import { trendingData } from './trending-data.reducer';
import { user } from './user.reducer';
import { userGoals } from './user-goal.reducer';

export const rootReducer = combineReducers({
  applicationState,
  auth,
  profile,
  user,
  userGoals,
  goal,
  solutionBuilder,
  step,
  expert,
  trendingData,
});
