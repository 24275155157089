import { httpClient } from 'state/actions/http-client';
import { ListIndustriesDto } from 'types';
import { IHttpClientRequestParams } from 'types/Interfaces/IHttpClientRequestParams';

import { listIndustriesUrl } from 'apis';
import { Dispatch } from 'redux';
import { RootState } from 'state';

export const listIndustries =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: listIndustriesUrl,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const response = await httpClient.get<ListIndustriesDto>(parameters);

      return response.categoriesByIndustries;
    } catch (error) {
      throw error;
    }
  };
