import { GoalActionEnum } from 'enums';
import { GoalStepsExpertsDto } from 'types';
import { GoalActionType } from 'types/action-types/goal-actions.types';

const INITIAL_STATE: GoalStepsExpertsDto = {
  id: '',
  businessChallenge: '',
  steps: [],
};

export const goal = (
  state: GoalStepsExpertsDto = INITIAL_STATE,
  action: GoalActionType
): GoalStepsExpertsDto => {
  let _state: GoalStepsExpertsDto;
  let _stepIndex: number;
  let _expertIndex: number;
  switch (action.type) {
    case GoalActionEnum.SET_GOAL:
      return { ...state, ...action.payload };
    case GoalActionEnum.ADD_GOAL_STEP:
      _state = { ...state };
      _stepIndex = _state.steps.findIndex((s) => s.id === action.payload.id);
      _state.steps[_stepIndex].isChosen = true;
      _state.steps[_stepIndex].solution_step_id =
        action.payload.solution_step_id;
      return { ...state, ..._state };

    case GoalActionEnum.REMOVE_GOAL_STEP:
      _state = { ...state };
      _stepIndex = _state.steps.findIndex((s) => s.id === action.payload.id);
      _state.steps[_stepIndex].isChosen = false;
      for (let i = 0; i < _state.steps[_stepIndex].experts.length; i++) {
        _state.steps[_stepIndex].experts[i].isChosen = false;
      }
      return { ...state, ..._state };
    case GoalActionEnum.ADD_STEP_EXPERT:
      _state = { ...state };
      _stepIndex = _state.steps.findIndex(
        (s) => s.id === action.payload.category_id
      );
      _expertIndex = _state.steps[_stepIndex].experts.findIndex(
        (e) => e.id === action.payload.id
      );
      _state.steps[_stepIndex].experts[_expertIndex].isChosen = true;
      _state.steps[_stepIndex].experts[_expertIndex].solution_expert_id =
        action.payload.solution_expert_id;
      return { ...state, ..._state };

    case GoalActionEnum.REMOVE_STEP_EXPERT:
      _state = { ...state };
      _stepIndex = _state.steps.findIndex(
        (s) => s.id === action.payload.category_id
      );
      _expertIndex = _state.steps[_stepIndex].experts.findIndex(
        (e) => e.id === action.payload.id
      );
      _state.steps[_stepIndex].experts[_expertIndex].isChosen = false;
      return { ...state, ..._state };

    case GoalActionEnum.CLEAR_GOAL:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};
