import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';

type ComunityTrendItemProps = {
  title: string;
  uses: number;
  activeBuyers: number;
};

const ComunityTrendItem = ({
  title,
  uses,
  activeBuyers,
}: ComunityTrendItemProps) => {
  return (
    <div className="mb-5">
      <div className="flex align-items-center mb-2">
        <span className="text-dark">{title}</span>
      </div>
      <div className="flex align-items-center">
        <AvatarGroup>
          <Avatar
            className="surface-ground border-f9"
            icon={<i className="pi pi-user"></i>}
            size="large"
            shape="circle"
          />
          <Avatar
            className="surface-ground border-f9 text-base"
            children={'+' + activeBuyers}
            size="large"
            shape="circle"
          />
        </AvatarGroup>

        <div className="ml-3">Active users</div>
      </div>
    </div>
  );
};

export default ComunityTrendItem;
