import { ApplicationStatesActionEnum } from 'enums';
import { ApplicationStatesType } from 'types';
import { ApplicationStatesActionsType } from 'types/action-types';

const INITIAL_STATE: ApplicationStatesType = {
  isPending: false,
};

export const applicationState = (
  state: ApplicationStatesType = INITIAL_STATE,
  action: ApplicationStatesActionsType
): ApplicationStatesType => {
  switch (action.type) {
    case ApplicationStatesActionEnum.SET_IS_PENDING:
      return { ...state, ...action.payload };
    case ApplicationStatesActionEnum.CLEAR_STATE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};
