import GoalListItem from './goal-list-item/goal-list-item';
import { GoalListContainerStyle } from './goal-list.style';
import { UserGoalSummary } from 'types';

type GoalListProps = {
  goalHighlight: string;
  goals: UserGoalSummary[];
  onEditGoal: (goal: UserGoalSummary) => void;
  onDuplicateGoal: (goal: UserGoalSummary) => void;
  onDeleteGoal: (goal: UserGoalSummary) => void;
  onSelectGoal: (id: string) => void;
};

const GoalsList = ({
  goals,
  onEditGoal,
  onDuplicateGoal,
  onDeleteGoal,
  onSelectGoal,
  goalHighlight,
}: GoalListProps) => {
  return (
    <div>
      <GoalListContainerStyle>
        <div className="overflow-y-auto px-3 goals_listings">
          {goals.length > 0 ? (
            goals.map((item: UserGoalSummary, index: number) => (
              <GoalListItem
                key={index}
                highlight={goalHighlight === item.id ? true : false}
                goalSummaryItem={item}
                onEditGoal={onEditGoal}
                onDuplicateGoal={onDuplicateGoal}
                onDeleteGoal={onDeleteGoal}
                onSelectGoal={onSelectGoal}
              />
            ))
          ) : (
            <>{'NO GOALS'}</>
          )}
        </div>
      </GoalListContainerStyle>
    </div>
  );
};

export default GoalsList;
