import { FilterType } from './experts-filter-dialog';
import FilterItem from './filter-item';

type FilterListProps = {
  options: FilterType[];
  onFilterSelection: () => void;
};

const FilterList = ({ options, onFilterSelection }: FilterListProps) => {
  return (
    <div className="grid">
      {options.map((f) => (
        <FilterItem
          key={f.id}
          option={f}
          onFilterSelection={onFilterSelection}
        />
      ))}
    </div>
  );
};

export default FilterList;
