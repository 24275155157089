import { Button } from 'primereact/button';

import GoalStepItem from './goal-steps-item';
import { StepType } from 'types';
import { StepsSelectedContainer } from './goal-steps.style';

type Props = {
  businessChallenge: string;
  stepsNotSelected: StepType[];
  selectedSteps: StepType[];
  chosenStepId: string;
  onChooseStep: (step: StepType) => void;
  onRemoveStep: (step: StepType) => void;
  openStepsSelectionDialog: () => void;
};

export const GoalStepsMenu = ({
  businessChallenge,
  stepsNotSelected,
  selectedSteps,
  onChooseStep,
  chosenStepId,
  onRemoveStep,
  openStepsSelectionDialog,
}: Props) => {
  // selectedSteps = [...selectedSteps, ...selectedSteps, ...selectedSteps];

  return (
    <>
      <div className="flex flex-column justify-content-between h-full">
        <div className="border-bottom-1 surface-border p-3 md:p-5">
          <div className="flex align-items-center text-gray-500">
            <i className="pi pi-stack"></i>
            <span className="ml-1">Solution Stack</span>
          </div>
          <div className="mt-2">
            <h5 className="text-black-alpha-80 mb-0">{businessChallenge}</h5>
          </div>
        </div>

        <StepsSelectedContainer className="flex flex-column h-full bg-gray-f4 m-0 px-3 py-4 md:px-5 md:py-6">
          {selectedSteps.length > 0 ? (
            selectedSteps.map((step) => (
              <GoalStepItem
                key={step.id}
                step={step}
                chosenStepId={chosenStepId}
                onChooseStep={onChooseStep}
                onRemoveStep={onRemoveStep}
              />
            ))
          ) : (
            <div className="font-bold text-gray-300 text-center text-base w-full m-auto">
              Your solution stack is empty!
            </div>
          )}
        </StepsSelectedContainer>

        <div className="border-top-1 surface-border p-3 md:p-5">
          <Button
            label="Add Steps"
            icon="pi pi-plus bg-green-100 p-1 border-round mr-3"
            className="p-button-text tertiary-icon"
            disabled={stepsNotSelected.length <= 0}
            onClick={openStepsSelectionDialog}
          />
        </div>
      </div>
    </>
  );
};
