import { LocaleObject } from 'yup/lib/locale';

export const yupLocaleMessages: LocaleObject = {
  mixed: {
    oneOf: 'Wrong option',
  },
  string: {
    email: 'Invalid email format',
    trim: 'Blank spaces are not accepted',
  },
};
