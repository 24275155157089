import { createMessageUrl, messagesUrl } from 'apis';
import { Dispatch } from 'redux';
import { RootState } from 'state';

import { httpClient } from 'state/actions/http-client';
import { IHttpClientRequestParams } from 'types/Interfaces/IHttpClientRequestParams';
import {
  CreateMessageDto,
  CreateMessageResponseDto,
  ListGoalMessagesType,
  ListMessagesDto,
} from 'types';

export const listGoalMessagesByExpert =
  (data: ListGoalMessagesType) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;
      const url = `${messagesUrl}?type=goal&typeId=${data.goal_id}&personId=${data.expert_id}`;

      const parameters: IHttpClientRequestParams = {
        url: url,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const response = await httpClient.get<ListMessagesDto>(parameters);

      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const createMessage =
  (body: CreateMessageDto) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: createMessageUrl,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
        payload: body,
      };

      const resp = await httpClient.post<
        CreateMessageDto,
        CreateMessageResponseDto
      >(parameters);

      return resp;
    } catch (error) {
      throw error;
    }
  };
