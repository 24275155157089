import { Box } from './box';

export const Svg = ({ width = '1em', height = '1em', ...restProps }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    {...restProps}
  />
);
