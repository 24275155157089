import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';

import { ImgUpload } from './profile-photo';
import { TextInfo } from './profile.style';
import { TwoButtons } from 'components';
import { work } from 'assets';
import { ProfileType } from 'types';

type ProfileCompanyFormProps = {
  values: ProfileType;
  editMode?: boolean;
  isOnBoard?: boolean;
  onUpdateCompany?: () => void;
  onCancel?: () => void;
  onActiveEdit?: () => void;
  handleInputChange: any;
};

export const ProfileCompanyForm = ({
  values,
  editMode = true,
  isOnBoard = false,
  onCancel,
  onActiveEdit,
  onUpdateCompany,
  handleInputChange,
}: ProfileCompanyFormProps) => {
  const businesses: string[] = [
    'Small Business',
    'Small Enterprise',
    'Large Enterprise',
    'Other',
  ];

  const operatingIndustries: string[] = [
    'Real Estate & Property',
    'Farming, Animals & Conservation',
    'Consulting & Strategy',
    'Sport & Recreation',
    'Banking & Financial Services',
    'Community Services & Development',
    'Trades & Services',
    'Information & Communication Technology',
    'Insurance & Superannuation',
    'Education & Training',
    'Legal',
    'Construction',
    'Mining, Resources & Energy',
    'Administration & Office Support',
    'Engineering',
    'Government & Defence',
    'Call Centre & Customer Service',
    'Design & Architechture',
    'Human Resources & Recruitment',
    'Advertising, Arts & Media',
    'Retail & Consumer Products',
    'Sales',
    'Manufacturing, Transport & Logistics',
    'Accounting',
    'Hospitality & Tourism',
    'Healthcare & Medical',
    'Science & Technology',
    'Marketing & Communications',
  ];

  const rolesTitle: string[] = [
    'CEO',
    'COO',
    'CTO',
    'CHRO',
    'CSO',
    'CPO',
    'President',
    'VP',
    'Sr. Director',
    'Director',
    'Manager',
    'Owner',
    'Consultant',
    'Partner',
    'Other',
  ];

  return (
    <div className="flex flex-column justify-content-between h-full goals_formContainer">
      <div className="relative">
        {!editMode && (
          <div className="absolute top-0 right-0">
            <Button
              icon="pi pi-pencil"
              className="p-component p-button"
              onClick={onActiveEdit}
              label="Edit"
            />
          </div>
        )}

        <ImgUpload
          editMode={editMode}
          src={
            !values.expertCompanyLogo
              ? work
              : values.expertCompanyLogo instanceof File
              ? URL.createObjectURL(values.expertCompanyLogo)
              : values.expertCompanyLogo
          }
          onChange={(e) => {
            handleInputChange({
              target: { name: 'expertCompanyLogo', value: e },
            });
          }}
        />

        <div className="grid p-fluid px-6">
          <div className="col-12 md:col-6 field">
            <label>Company name</label>
            {editMode ? (
              <InputText
                name="companyTitle"
                value={values.companyTitle}
                onChange={handleInputChange}
              />
            ) : (
              <TextInfo>{values.companyTitle}</TextInfo>
            )}
          </div>

          <div className="col-12 md:col-6 field">
            <label>Company zip code</label>
            {editMode ? (
              <InputText
                name="zipCode"
                value={values.zipCode}
                onChange={handleInputChange}
              />
            ) : (
              <TextInfo>{values.zipCode}</TextInfo>
            )}
          </div>

          <div className="col-12 field">
            <label>Role title</label>
            {editMode ? (
              <Dropdown
                filter
                name="title"
                value={values.title}
                options={rolesTitle}
                onChange={handleInputChange}
              />
            ) : (
              <TextInfo>{values.title}</TextInfo>
            )}
          </div>

          <div className="col-12 field">
            <label>Business type (select one):</label>
            <SelectButton
              className="block md:flex"
              value={values.businessType}
              options={businesses}
              disabled={editMode ? false : true}
              onChange={(e) =>
                handleInputChange({
                  target: { name: 'businessType', value: e.value },
                })
              }
            />
          </div>

          <div className="col-12 field">
            <label>Operating industry (select one):</label>
            {editMode ? (
              <Dropdown
                filter
                name="operatingIndustry"
                value={values.operatingIndustry}
                onChange={handleInputChange}
                options={operatingIndustries}
              />
            ) : (
              <TextInfo>{values.operatingIndustry}</TextInfo>
            )}
          </div>
        </div>
      </div>

      {editMode && !isOnBoard && (
        <TwoButtons
          className="goals_btm-btns"
          leftLabel="Cancel"
          rightLabel="Save"
          onLeftClick={onCancel}
          onRightClick={onUpdateCompany}
        />
      )}
    </div>
  );
};
