import { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';

import StepsAvailableList from './steps-available-list';
import { StepType } from 'types';
import { SearchFilter, DoneButton } from 'components';

type StepsSelectionDialogProps = {
  open: boolean;
  onHide: () => void;
  stepsAvailable: StepType[];
  onAddStepToSolution: (step: StepType) => void;
};

export const StepsSelectionDialog = ({
  open,
  onHide,
  stepsAvailable,
  onAddStepToSolution,
}: StepsSelectionDialogProps) => {
  const [steps, setSteps] = useState<StepType[]>([]);

  useEffect(() => {
    if (stepsAvailable.length > 0) setSteps(stepsAvailable);
  }, [stepsAvailable]);

  const onSearchChange = (value: string) => {
    if (value && value?.trim()) {
      try {
        setSteps(() => {
          return stepsAvailable.filter((_step) =>
            _step?.title
              .toLowerCase()
              .trim()
              .includes(value.toLowerCase().trim())
          );
        });
      } catch (error) {}
    } else {
      if (steps.length !== stepsAvailable.length) setSteps(stepsAvailable);
    }
  };

  return (
    <>
      <Dialog
        modal
        style={{ minHeight: '500px', maxWidth: '800px', width: '800px' }}
        visible={open}
        onHide={onHide}
        header={false}
        draggable={false}
        resizable={false}
        className="goals_modal"
        footer={<DoneButton onDone={onHide} />}
      >
        <div className="px-3">
          <div className="mb-5">
            <SearchFilter
              label="Select step(s) to add to your solution plan:"
              placeholder="Search for solution steps"
              onChange={onSearchChange}
            />
          </div>

          <StepsAvailableList
            stepsAvailable={steps}
            onAddStepToSolution={onAddStepToSolution}
          />
        </div>
      </Dialog>
    </>
  );
};
