import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dispatch } from '@reduxjs/toolkit';

import { ExpertType } from 'types';
import { IS_MESSAGING } from '@constants';
import { SolutionBuilderStateType } from 'types/goal-engagement-state';
import { DoneButton } from 'components';
import { userSvg } from 'assets';
import { handleAvatarImageError } from 'utilities';

type ExpertsSelectionProps = {
  allExperts: ExpertType[];
  stepTitle: string;
  onAddExpert: (expert: ExpertType) => void;
  openExpertProfileDialog: (expert: ExpertType) => void;
  openExpertDeleteDialog: (expert: ExpertType) => void;
  openExpertsFilterDialog: () => void;
  onDoneSelecting: (
    state: SolutionBuilderStateType
  ) => (dispatch: Dispatch) => void;
};

const ExpertsSelection = ({
  stepTitle,
  allExperts,
  onAddExpert,
  openExpertDeleteDialog,
  onDoneSelecting,
  openExpertsFilterDialog,
  openExpertProfileDialog,
}: ExpertsSelectionProps) => {
  const profileBodyTemplate = (expert: ExpertType) => {
    return (
      <Button
        icon="pi pi-eye"
        className="p-button-rounded p-button-info mr-2 mb-2"
        onClick={() => openExpertProfileDialog(expert)}
      />
    );
  };

  const actionBodyTemplate = (expert: ExpertType) => {
    const { isChosen } = expert;
    const classButton = isChosen ? 'p-button-danger' : 'p-button-success';
    const iconButton = isChosen ? 'pi-trash' : 'pi-plus';
    const action = isChosen ? openExpertDeleteDialog : onAddExpert;

    return (
      <Button
        icon={`pi ${iconButton}`}
        className={`p-button-rounded ${classButton} mr-2 mb-2`}
        onClick={() => {
          action(expert);
        }}
      />
    );
  };

  const expertBodyTemplate = (expert: ExpertType) => {
    console.log(expert);

    return (
      <>
        <Avatar
          image={expert?.image ? expert?.image : userSvg}
          shape="circle"
          size="large"
          imageAlt={expert?.name}
          onImageError={handleAvatarImageError}
        />
        <span className="image-text ml-2">{expert.name}</span>
      </>
    );
  };

  const isChosenLength = (): number => {
    return allExperts.filter((e) => e.isChosen === true).length;
  };

  const footerDatatable = () => (
    <div>
      <div>
        <span className="font-bold mb-2 inline-block">Selected Partners</span>
        {isChosenLength() > 0 && (
          <span className="text-gray-300 ml-1">(Added {isChosenLength()})</span>
        )}
      </div>
      <div>
        <AvatarGroup className="mt-1">
          {allExperts.map((expert, index) => {
            if (expert.isChosen) {
              return (
                <Avatar
                  key={index}
                  size="xlarge"
                  shape="circle"
                  imageAlt={expert?.name}
                  image={expert?.image ? expert?.image : userSvg}
                  onImageError={handleAvatarImageError}
                />
              );
            } else {
              return undefined;
            }
          })}
        </AvatarGroup>
      </div>
    </div>
  );

  return (
    <div className="flex flex-column h-full">
      <div className="flex justify-content-between flex-wrap">
        <div>
          <div className="font-weight-800 text-lg">{stepTitle}</div>
        </div>
        <div>
          {/* <Button
            icon="pi pi-filter"
            label="Filter Experts"
            onClick={() => openExpertsFilterDialog()}
          /> */}
        </div>
      </div>

      <div className="col-12">
        <DataTable
          scrollable
          value={allExperts}
          scrollDirection="both"
          className="mt-3"
          footer={footerDatatable()}
        >
          <Column
            field="name"
            header="Name"
            style={{ flexGrow: 1, flexBasis: '150px' }}
            body={expertBodyTemplate}
          />

          <Column
            field="company"
            header="Company"
            style={{ flexGrow: 1, flexBasis: '100px' }}
          />

          <Column
            field="experience"
            header="Experience"
            style={{
              flexGrow: 1,
              flexBasis: '80px',
              justifyContent: 'center',
            }}
          />

          <Column
            header="Profile"
            style={{
              flexGrow: 1,
              flexBasis: '50px',
              justifyContent: 'center',
            }}
            body={profileBodyTemplate}
          />

          <Column
            header="Action"
            body={actionBodyTemplate}
            style={{
              flexGrow: 1,
              flexBasis: '50px',
              justifyContent: 'center',
            }}
          />
        </DataTable>
      </div>

      <div className="text-right">
        <DoneButton onDone={() => onDoneSelecting({ value: IS_MESSAGING })} />
      </div>
    </div>
  );
};

export default ExpertsSelection;
