import { httpClient } from 'state/actions/http-client';
import { IHttpClientRequestParams } from 'types/Interfaces/IHttpClientRequestParams';
import { Dispatch } from 'redux';
import { RootState } from 'state';

import { profileUrl } from 'apis';
import { ProfileActionType } from 'types/action-types';
import { ProfileActionEnum } from 'enums';
import { SITE_INFO } from '@constants';
import { ProfileType } from 'types';

const baseUrl = SITE_INFO.APIS.USER_APP.URL;

export const updateProfile =
  (data: ProfileType) =>
  async (dispatch: Dispatch<ProfileActionType>, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const formData = new FormData();

      formData.append('businessType', data.businessType || '');
      formData.append('zipCode', data.zipCode || '');
      formData.append('title', data.title || '');
      formData.append('companyTitle', data.companyTitle || '');
      formData.append('operatingIndustry', data.operatingIndustry || '');
      formData.append('expertCompanyLogo', data.expertCompanyLogo || '');

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${profileUrl}`,
        requiresToken: true,
        payload: formData,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const resp = await httpClient.post<ProfileType, ProfileType>(parameters);

      dispatch({ type: ProfileActionEnum.SET_PROFILE, payload: resp });
    } catch (error) {
      throw error;
    }
  };

export const clearProfileData =
  () => (dispath: Dispatch<ProfileActionType>) => {
    try {
      dispath({ type: ProfileActionEnum.CLEAR_PROFILE });
    } catch (error) {
      throw error;
    }
  };
