import { TrendingDataActionEnum } from 'enums';
import { TrendingGoalType } from 'types';
import { TrendingDataActionType } from 'types/action-types';

const INITIAL_STATE: TrendingGoalType[] = [];

export const trendingData = (
  state: TrendingGoalType[] = INITIAL_STATE,
  action: TrendingDataActionType
): TrendingGoalType[] => {
  switch (action.type) {
    case TrendingDataActionEnum.SET_TRENDING_DATA:
      return [...action.payload];
    case TrendingDataActionEnum.CLEAR_TRENDING_DATA:
      return [...INITIAL_STATE];
    default:
      return state;
  }
};
