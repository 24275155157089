import React from 'react';
import { sameSideLogo } from 'assets';
import { Button } from 'primereact/button';
import { MenuContainer } from './auth.style';

type AuthMenuProps = {
  buttonRedirectLabel: string;
  label: string;
  onRedirect: () => void;
};

export const AuthMenu = React.memo(
  ({ buttonRedirectLabel, label, onRedirect }: AuthMenuProps) => {
    return (
      <MenuContainer className="shadow-2">
        <div className="block md:flex align-items-center">
          <div className="pb-2 md:pb-3 col-12 md:col-4 text-center">
            <img src={sameSideLogo} alt="sameside-logo" />
          </div>
          <div className="p-1 md:p-3 col-12 md:col-4 text-center">
            <span className="font-bold">{label}</span>
          </div>
          <div className="col-12 md:col-4 text-center">
            <Button
              className="btn-small"
              label={buttonRedirectLabel}
              onClick={() => {
                onRedirect();
              }}
            />
          </div>
        </div>
      </MenuContainer>
    );
  }
);
