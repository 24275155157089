import React, { useMemo } from 'react';
import { Button } from 'primereact/button';
import { FileUpload, FileUploadSelectParams } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { Slider, SliderChangeParams } from 'primereact/slider';

import { GoalBackground } from 'types';
import {
  MAX_BUDGET,
  MIN_BUDGET,
  STEP_BUDGET,
  DOCS_ACCEPT,
  DOCS_MAX_SIZE,
} from '@constants';
import { useToast } from 'hooks';

type GoalBackgroundFormProps = {
  values: GoalBackground;
  openListDialog: (isQuestion: boolean) => void;
  handleInputChange: (e: any) => void;
};

export const GoalBackgroundForm = React.memo(
  ({ values, openListDialog, handleInputChange }: GoalBackgroundFormProps) => {
    const { showErrorToast } = useToast();

    /**
     * @param e -> event params
     * This function handle slide range (min and max) changes
     */
    const handleSliderChange = (e: SliderChangeParams): void => {
      try {
        // Transform to string, because array value isn't available
        const stringValue = e.value.toString();
        const [min, max] = stringValue.split(',');

        // Use a if statement, because this update only one value
        if (values.minBudget !== parseInt(min)) {
          handleInputChange({
            target: { name: 'minBudget', value: parseInt(min) },
          });
        }

        if (values.maxBudget !== parseInt(max)) {
          handleInputChange({
            target: { name: 'maxBudget', value: parseInt(max) },
          });
        }
      } catch (error) {
        throw error;
      }
    };

    const onSelectFile = ({ files }: FileUploadSelectParams) => {
      if (files[0]?.size > DOCS_MAX_SIZE) {
        showErrorToast(
          'Document size error',
          'Document size exceeds limit (5MB)'
        );
        return;
      }
      handleInputChange({ target: { name: 'document', value: files[0] } });
    };

    const onClearFile = () => {
      handleInputChange({ target: { name: 'document', value: undefined } });
    };

    /**
     * Templates
     */

    const questionsCompletedClass: string = useMemo((): string => {
      if (values.question1 || values.question2 || values.question3) {
        return 'goals_backgroundBlocks--completed';
      } else {
        return '';
      }
    }, [values.question1, values.question2, values.question3]);

    const outcomesCompletedClass: string = useMemo((): string => {
      if (values.outcome1 || values.outcome2 || values.outcome3) {
        return 'goals_backgroundBlocks--completed';
      } else {
        return '';
      }
    }, [values.outcome1, values.outcome2, values.outcome3]);

    const uploadClass: string = useMemo((): string => {
      if (values.document) return 'goals_backgroundBlocks--completed';
      else return '';
    }, [values.document]);

    return (
      <div className="goals_formContainer">
        <div className="text-center mb-5">
          <h4 className="text-dark">
            Provide further details behind your goal for the most optimized
            results
          </h4>
        </div>

        <div className="p-fluid">
          <div className="field">
            <label>What’s your estimated budget?</label>

            <div className="flex align-content-center mx-auto">
              <div className="col-5">
                <div className="field">
                  <label className="text-sm mb-1">Min</label>
                  <InputNumber
                    showButtons
                    prefix="$"
                    name="minBudget"
                    max={MAX_BUDGET}
                    step={STEP_BUDGET}
                    value={values.minBudget}
                    onValueChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-2 text-center flex justify-content-center align-items-center">
                <i className="pi pi-minus"></i>
              </div>

              <div className="col-5">
                <div className="field">
                  <label className="text-sm mb-1">Max</label>
                  <InputNumber
                    showButtons
                    prefix="$"
                    name="maxBudget"
                    min={MIN_BUDGET}
                    step={STEP_BUDGET}
                    value={values.maxBudget}
                    onValueChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <Slider
              range
              value={[values.minBudget, values.maxBudget]}
              min={MIN_BUDGET}
              max={
                values.maxBudget > MAX_BUDGET ? values.maxBudget : MAX_BUDGET
              }
              step={STEP_BUDGET}
              onChange={handleSliderChange}
            />
          </div>
        </div>

        <hr className="my-5" />

        <div className="flex flex-wrap align-items-center">
          <div
            className={
              'field goals_backgroundBlocks surface-50 m-2 p-disabled cursor-not-allowed ' +
              uploadClass
            }
          >
            <FileUpload
              mode="basic"
              accept={DOCS_ACCEPT}
              maxFileSize={DOCS_MAX_SIZE}
              onSelect={onSelectFile}
              onClear={onClearFile}
              chooseOptions={{
                className: 'p-button-text p-button-info',
                label: 'Requirements doc or RFP (Feature Coming Soon)',
                icon: 'pi pi-upload',
              }}
            />
          </div>

          <div
            className={
              'field goals_backgroundBlocks surface-50 m-2 ' +
              questionsCompletedClass
            }
          >
            <Button
              icon="pi pi-list"
              className="p-button-text p-button-info"
              label="Define your vetting process"
              onClick={() => openListDialog(true)}
            />
          </div>

          <div
            className={
              'field goals_backgroundBlocks surface-50 m-2 ' +
              outcomesCompletedClass
            }
          >
            <Button
              icon="pi pi-file"
              className="p-button-text p-button-info"
              label="Create a list of expected outcomes for your goal"
              onClick={() => openListDialog(false)}
            />
          </div>
        </div>
      </div>
    );
  }
);
