import React from 'react';
import { PrivacyPolicyContainer } from './legal.style';

export const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer>
      <div className="pt-4 bg-gray-100">
        <div className="min-h-screen flex flex-col items-center pt-6 sm:pt-0">
          <div className="w-full sm:max-w-2xl mt-6 p-6 bg-white shadow-md overflow-hidden sm:rounded-lg prose tracking-wider">
            <h1>Privacy Policy</h1>
            <p>Last updated: September 29th, 2021</p>
            <br />
            <p>
              Thank you for using the sameside.io website (“SameSide”). We are
              committed to protecting your privacy and, for that reason, we have
              adopted this Privacy Policy to explain our data collection, use,
              and disclosure practices for the SameSide services (including this
              website, and web-based applications, and any other tools,
              products, or services provided by SaSi, LLC that link to or
              reference this Privacy Policy) (collectively, the “Services”). The
              Services are owned and operated by SaSi, LLC, a Delaware limited
              liability company (“SameSide,” “we,” “us,” or “our”).
            </p>
            <br />
            <p>
              This Privacy Policy applies to information SameSide collects
              through the Services, as well as other information provided to us
              online or offline by third parties, when we associate that
              information with customers or users of the Services; however, it
              does not apply to information collected from our employees,
              contractors, or vendors. It also does not apply to information
              that you ask us to share with third parties or is collected by
              Online Tool Providers (as further described below). You
              acknowledge and agree that SameSide is not responsible for the
              data collection or use practices of any third party service
              provider utilized in providing the Services. Additionally, this
              Privacy Policy does not apply to information collected through the
              sameside.io website. However, if your employer or business has a
              separate written agreement with us that provides for stricter
              treatment of personal information or other information, the
              applicable provisions of such agreement control with respect to
              personal information or other information.
            </p>
            <br />
            <p>This Privacy Policy describes, among other things:</p>
            <br />
            <ul>
              <li>Personal and other information we collect about you;</li>
              <br />
              <li>How we use your information;</li>
              <br />
              <li>How we may share your information with third parties; and</li>
              <br />
              <li>
                Your choices regarding the personal information we collect about
                you.
              </li>
              <br />
            </ul>
            <h3>1. Consent</h3>
            <p>
              By accessing or using the Services, you consent to this Privacy
              Policy. If you do not agree with this Privacy Policy, please do
              not access or use the Services. Information gathered through the
              Services may be transferred, used, and stored in the United States
              or in other countries where our service providers or we are
              located. If you use the Services, you agree to the transfer, use,
              and storage of your Personal Information (as defined below) in
              those countries. The data protection and other laws of the United
              States and other countries might not be as comprehensive as those
              in your country. You agree that all transactions relating to the
              Services or SameSide are deemed to occur in the United States,
              where our servers are located.
            </p>
            <h3>2. Collection of Your Personal and Other Information</h3>
            <p>
              When you register for, or use our Services, we collect or have
              access to Personal Information. By “Personal Information” we mean
              information that can identify or reasonably be linked to an
              individual, such as:
            </p>
            <ul>
              <li>your name;</li>
              <li>phone number;</li>
              <li>your business name or address;</li>
            </ul>
            <p>
              You may choose not to provide Personal Information but this may
              prevent you from receiving certain features of the Services.
            </p>
            <p>
              We also collect Non-Personal Information relating to the Services,
              that is, information that does not personally identify an
              individual (“Non-Personal Information”). The Non-Personal
              Information we collect includes how you interact with the
              Services, information generally collected or “logged” by Internet
              websites or Internet services when accessed or used by users, and
              information about your web browser or device accessing or using
              the Services.
            </p>
            <p>Examples of the Non-Personal Information we collect are:</p>
            <ul>
              <li>The pages of our website that you viewed during a visit;</li>
              <li>
                What information or content you view or interact with using the
                Services;
              </li>
              <li>
                The city and state in which you are located (but not your
                precise geographic location); and
              </li>
              <li>
                Unique identifiers that are not connected and cannot reasonably
                be connected to your identity.
              </li>
            </ul>
            <p>
              We will not use Non-Personal Information to try to identify you,
              and if we associate any Non-Personal Information with information
              that personally identifies you, then we will treat it as Personal
              Information. As discussed in more detail below, we sometimes use
              cookies and other automatic information gathering technologies to
              gather Non-Personal Information. Information collected by the
              Services may be collected by us or one of our Service Providers or
              Online Tool Providers.
            </p>
            <h3>3. Use of Your Information</h3>
            <p>We may use the information we collect to:</p>
            <ul>
              <li>
                Assist us in providing, maintaining, and protecting the Services
                and the systems that provide the Services;
              </li>
              <li>Improve our online operations;</li>
              <li>Provide customer service;</li>
              <li>
                Communicate with you, such as provide you with account- or
                transaction-related communications, or other newsletters, RSS
                feeds, and/or other communications relating to the Services; and
              </li>
              <li>Send or display offers; and</li>
              <li>
                Perform research and analysis aimed at improving our products
                and services and developing new products or services.
              </li>
            </ul>
            <h3>4. Disclosure of Your Information</h3>
            <p>
              We do not sell your Personal Information but we may disclose your
              Personal Information to third parties as described below.
            </p>
            <p>
              We may disclose Personal Information to provide the Services, or
              when you authorize or instruct us to do so. We may also disclose
              Personal Information and Non-Personal Information to Service
              Providers. By “Service Providers” we mean companies, agents,
              contractors, service providers, or others engaged to perform
              functions on our behalf (such as processing of payments, provision
              of data storage, hosting of our website, marketing of our products
              and services, and conducting audits). When we use a Service
              Provider, we require that the Service Provider use and disclose
              the Personal Information and Non-Personal Information received
              from us only to provide their services to us or as required by
              applicable law.
            </p>
            <p>
              We may also disclose Personal Information and Non-Personal
              Information to Online Tool Providers. By “Online Tool Provider” we
              mean a licensor of software that we include in, or use with, the
              Services, including an API or SDK, that provides a specialized
              function or service to us and that requires the transmission of
              Personal Information and/or Non-Personal Information to the Online
              Tool Provider. Online Tool Providers may have the right to use
              Personal Information and Non-Personal Information about you for
              their own business purposes. Use and disclosure of Personal
              Information and Non-Personal Information by an Online Tool
              Provider is described in its privacy policy. See Section 5 below
              for some of the key Online Tool Providers we use.
            </p>
            <p>
              We may also disclose your Personal Information to third parties
              when we believe, in good faith and in our sole discretion, that
              such disclosure is reasonably necessary to (a) enforce or apply
              the terms and conditions of the Services, including investigation
              of potential violations thereof, (b) comply with legal or
              regulatory requirements or an enforceable governmental request,
              (c) protect the rights, property or safety of us, our users or
              other third parties, (d) prevent a crime or protect national
              security, or (e) detect, prevent or otherwise address fraud,
              security or technical issues.
            </p>
            <p>
              We reserve the right to transfer information (including your
              Personal Information) to a third party in the event of a sale,
              merger, or transfer of all or substantially all of the assets of
              our company relating to the Services, or in the unlikely event of
              a bankruptcy, liquidation, or receivership of our business. We
              will use commercially reasonable efforts to notify you of such
              transfer, for example via email or by posting notice on our
              website.
            </p>
            <p>
              Lastly, we may also disclose Non-Personal Information, aggregated
              with information about our other users, to our clients, business
              partners, merchants, advertisers, investors, potential buyers and
              other third parties if we deem such disclosure, in our sole
              discretion, to have sound business reasons or justifications.
            </p>
            <h3>5. Cookies and Automatic Information Gathering Technologies</h3>
            <p>
              Every time you use the Services (e.g., access a Service webpage),
              we collect Personal Information and Non-Personal Information
              (discussed above in Section 2) regarding that use. For example, to
              improve our Services, we collect how, when, and which parts of the
              Services or its features you use.
            </p>
            <p>
              To assist us in collecting and storing this Non-Personal
              Information, we may employ a variety of technologies, including
              “Cookies,” local browser storage, and “web beacons,” “pixels,” or
              “tags.” A “Cookie” is a small amount of data a website operator,
              or a third party whose content is embedded in that website, may
              store in your web browser and that the website operator or, as
              applicable, the third party, can access when you visit the
              website. A web beacon, pixel or tag is a small,
              usually-transparent image or script placed on a web page that
              allows the operator of that image or script, which may be the
              operator of the website you visit or a third party, to read or
              write a Cookie.
            </p>
            <p>
              Your operating system and web browser may allow you to erase
              information stored in Cookies and local browser storage. But if
              you do so, you may be forced to login to the Services again and
              you may lose some preferences or settings. You may also be able to
              set your browser to refuse all website storage or to indicate when
              it is permitted, but some features of our Services may not
              function properly without it.
            </p>
            <p>
              More information about managing Cookies is available here. To
              learn how to manage privacy and storage settings for your local
              browser storage, please refer to the end user documentation for
              your browser.
            </p>
            <h3>6. Transparency and Choice; Do Not Track Signals</h3>
            <p>
              You may request access to your Personal Information covered by
              this Privacy Policy by sending an email to{' '}
              <a href="mailto:niketa@sameside.io">niketa@sameside.io</a>. We
              will try to locate and provide you with your Personal Information
              and give you the opportunity to correct this data, if it is
              inaccurate, or to delete it, at your request. But, in either case,
              we may need to retain it for legal reasons or for legitimate
              business purposes. However, we (and you) are not able to control
              information that you have already shared with other users or made
              available to third parties through the Services.
            </p>
            <p>
              We ask individual users to identify themselves and the information
              requested to be accessed, corrected, or removed before processing
              such requests, and we may decline to process requests that are
              unreasonably repetitive or systematic, require disproportionate
              technical effort, jeopardize the privacy of others, would be
              extremely impractical (for instance, requests concerning
              information residing on backups), or relate to information that is
              not associated with your Personal Information. In any case, where
              we provide information access and correction, we perform this
              service free of charge, except if doing so would require a
              disproportionate effort.
            </p>
            <p>
              Please be aware that if you ask us to delete your Personal
              Information, you may not be able to continue to use the Services.
              Also, even if you request that we delete your Personal
              Information, we may need to retain certain information for a
              limited period of time to satisfy our legal, audit and/or dispute
              resolution requirements.
            </p>
            <p>
              We support the development and implementation of a standard
              &quot;do not track&quot; browser feature that provides customers
              with control over the collection and use of information about
              their web-browsing activities. Once a standardized &quot;do not
              track&quot; feature is released, we intend to adhere to the
              browser settings accordingly.
            </p>
            <p>
              You can opt out of receiving marketing e-mails from us by clicking
              on the “unsubscribe” link in the e-mails. Please note that it may
              take up to ten (10) business days for your opt-out request to be
              processed. Also, even if you opt out of marketing e-mails, we may
              continue to send you certain account-related e-mails, such as
              notices about your account and confirmations of transactions you
              have requested.
            </p>
            <h3>7. Residents of California, Nevada, or Canada</h3>
            <p>Residents of California</p>
            <p>
              You may have heard of the California Consumer Privacy Act which
              provides certain rights to California (CCPA) residents in
              connection with their Personal Information. Our Services are not
              currently subject to the CCPA. However, we do provide notice and
              transparency about our collection and use of Personal Information
              as described in Privacy Policy.
            </p>
            <p>Residents of Canada</p>
            <p>
              If you have an objection to the use of your Personal Information
              as described in this Privacy Policy, you may file a complaint by
              sending an email to{' '}
              <a href="mailto:niketa@sameside.io">niketa@sameside.io</a>. We
              will attempt to accommodate your objection or complaint, but you
              understand that, to the extent you object to our processing of
              Personal Information that is necessary for us to provide the
              Services to you, certain features and functionalities of the
              Services may no longer be available to you. Nothing in this
              Privacy Policy prejudices your rights to file a complaint with the
              Office of the Privacy Commissioner of Canada, and/or with any
              other applicable data protection authorities.
            </p>
            <p>Residents of Nevada</p>
            <p>
              We do not sell your Personal Information. However, you may contact
              us at <a href="mailto:niketa@sameside.io">niketa@sameside.io</a>{' '}
              with questions.
            </p>
            <h3>8. Children</h3>
            <p>
              The Services are not intended for users under 13 years of age. We
              do not knowingly collect Personal Information from users under 13
              years of age. We do not authorize users under 13 years of age to
              use the Services.
            </p>
            <h3>9. Information Security</h3>
            <p>
              We utilize reasonable information security measures to safeguard
              your Personal Information against unauthorized access,
              modification, or destruction. For example, we utilize Secure
              Socket Layer (SSL), Transport Layer Security (TLS), or similar
              encryption technology when sensitive data is transmitted over the
              Internet, and use firewalls to help prevent external access into
              our network. However, no data transmission over the Internet and
              no method of data storage can be guaranteed to be 100% secure.
              Therefore, while we strive to use commercially acceptable means to
              protect your Personal Information, we cannot guarantee its
              security.
            </p>
            <p>
              We restrict access to Personal Information in our possession to
              our employees, Service Providers, and Online Tool Providers who
              need to know that information in order to operate, develop,
              improve or support our Services. If we share Personal Information
              with Service Providers, we require that they also use reasonable
              information security measures to safeguard your Personal
              Information.
            </p>
            <h3>10. Third Party Websites</h3>
            <p>
              Please note that the Services may link or integrate with
              third-party sites, services or apps. We are not responsible for
              the privacy or security policies or practices or the content of
              such third parties. Accordingly, we encourage you to review the
              privacy and security policies and terms of service of those third
              parties so that you understand how those websites collect, use,
              share and protect your information.
            </p>
            <h3>11. Changes to this Policy</h3>
            <p>
              We may modify or update this Privacy Policy periodically with or
              without prior notice by posting the updated policy on this page.
              You can always check the “Last Updated” date at the top of this
              document to see when the Privacy Policy was last changed. If we
              make any material changes to this Privacy Policy, we will notify
              you by reasonable means, which may be by e-mail or posting a
              notice of the changes on our website prior to the changes becoming
              effective. We encourage you to check this Privacy Policy from time
              to time. IF YOU DO NOT AGREE TO CHANGES TO THIS PRIVACY POLICY,
              YOU MUST STOP USING THE SERVICES AFTER THE EFFECTIVE DATE OF SUCH
              CHANGES (WHICH IS THE “LAST UPDATED” DATE OF THIS PRIVACY POLICY).
            </p>
            <h3>12. Questions</h3>
            <p>
              To ask questions about our Privacy Policy or to lodge a complaint,
              contact us at:
              <br />
              SaSi, LLC
            </p>
            <p>1199 Westcreek Ln</p>
            <p>Westlake Village CA 91362</p>
            <p>
              Email: <a href="mailto:niketa@sameside.io">niketa@sameside.io</a>
            </p>
          </div>
        </div>
      </div>
    </PrivacyPolicyContainer>
  );
};
