import { useState } from 'react';

export const useForm = <T extends Object>(initialState: T) => {
  const [values, setValues] = useState(initialState);

  const handleInputChange = ({ target }: any) => {
    const { name, value } = target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  return {
    values,
    handleInputChange,
    setValues,
    ...values,
  };
};
