import { Dispatch } from '@reduxjs/toolkit';
import { userSvg } from 'assets';
import React from 'react';

import { ExpertType } from 'types';
import { handleAvatarImageError } from 'utilities';
import { ExpertAvatar } from './expert-messaging.style';

type ExpertSelectedItemProps = {
  expert: ExpertType;
  expertSelected: ExpertType;
  onChooseExpertToMessage: (expert: ExpertType) => (dispatch: Dispatch) => void;
};

const ExpertSelectedItem = React.memo(
  ({
    expert,
    expertSelected,
    onChooseExpertToMessage,
  }: ExpertSelectedItemProps) => {
    const { id, name, image } = expert;

    return (
      <div
        onClick={() => onChooseExpertToMessage(expert)}
        className="flex flex-column cursor-pointer align-items-center mx-1 md:mx-0 md:mb-3"
      >
        <ExpertAvatar
          image={image ? image : userSvg}
          size="large"
          shape="circle"
          isSelected={id === expertSelected.id}
          onImageError={handleAvatarImageError}
        />
        <small>{name.split(' ')[0]}</small>
      </div>
    );
  }
);

export default ExpertSelectedItem;
