import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { FormContainer } from './login.style';

type PasswordCreateFormProps = {
  values: {
    password: string;
    confirmPassword: string;
  };
  onSubmit: () => void;
  onSignIn: () => void;
  onPasswordsChange: (value: string, type: 'p' | 'cp') => void;
};

export const PasswordCreateForm = ({
  values,
  onSubmit,
  onSignIn,
  onPasswordsChange,
}: PasswordCreateFormProps) => {
  return (
    <>
      <FormContainer>
        <form
          className="p-fluid"
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div className="field">
            <Password
              required
              toggleMask={true}
              placeholder="Password"
              autoComplete="new-password"
              value={values.password}
              onChange={(e) => onPasswordsChange(e.target.value, 'p')}
            />
          </div>

          <div className="field">
            <Password
              required
              toggleMask={true}
              feedback={false}
              placeholder="Re-enter password"
              autoComplete="new-password"
              value={values.confirmPassword}
              onChange={(e) => onPasswordsChange(e.target.value, 'cp')}
            />
          </div>

          <div className="field">
            <Button label="Create" type="submit" />
          </div>
        </form>
        <div className="text-center">
          <Button
            className="p-button-text"
            label="Back to sign in"
            icon="pi pi-arrow-left"
            onClick={onSignIn}
          />
        </div>
      </FormContainer>
    </>
  );
};
