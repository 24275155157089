import { ToolbarNoBorder } from 'components/toolbar';
import { Button } from 'primereact/button';

type TwoButtonsProps = {
  className?: string;
  leftLabel: string;
  rightLabel: string;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
  onLeftClick?: () => void;
  onRightClick?: () => void;
};

export const TwoButtons = ({
  className,
  leftLabel,
  rightLabel,
  leftDisabled,
  rightDisabled,
  onLeftClick,
  onRightClick,
}: TwoButtonsProps) => {
  return (
    <ToolbarNoBorder
      className={className}
      left={
        <Button
          className="p-button p-button-green-outline"
          disabled={leftDisabled}
          label={leftLabel}
          onClick={onLeftClick}
        />
      }
      right={
        <Button
          className="p-button"
          disabled={rightDisabled}
          label={rightLabel}
          onClick={onRightClick}
        />
      }
    />
  );
};
