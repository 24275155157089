import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { AddonBgInherit, TextInfo } from './profile.style';
import { ImgUpload } from './profile-photo';
import { userSvg } from 'assets';
import { TwoButtons } from 'components';
import { UserType } from 'types';

type ProfileUserFormProps = {
  values: UserType;
  editMode?: boolean;
  isOnBoard?: boolean;
  handleInputChange: any;
  onActiveEdit?: () => void;
  onCancel?: () => void;
  onUpdateUser?: () => void;
};

export const ProfileUserForm = ({
  values,
  onActiveEdit,
  editMode = false,
  isOnBoard = false,
  handleInputChange,
  onCancel,
  onUpdateUser,
}: ProfileUserFormProps) => {
  return (
    <div className="flex flex-column justify-content-between h-full goals_formContainer">
      <div className="relative">
        {!editMode && (
          <div className="absolute top-0 right-0">
            <Button
              icon="pi pi-pencil"
              className="p-component p-button"
              onClick={onActiveEdit}
              label="Edit"
            />
          </div>
        )}

        <ImgUpload
          editMode={editMode}
          src={
            !values.profilePath
              ? userSvg
              : values.profilePath instanceof File
              ? URL.createObjectURL(values.profilePath)
              : values.profilePath
          }
          onChange={(e) =>
            handleInputChange({ target: { name: 'profilePath', value: e } })
          }
        />

        <div className="grid p-fluid px-6">
          <div className="col-12 md:col-6 field">
            <label>First Name</label>
            {editMode ? (
              <InputText
                name="firstName"
                placeholder="Enter your first name"
                value={values.firstName}
                onChange={handleInputChange}
              />
            ) : (
              <TextInfo>{values.firstName}</TextInfo>
            )}
          </div>

          <div className="col-12 md:col-6 field">
            <label>Last Name</label>
            {editMode ? (
              <InputText
                name="lastName"
                placeholder="Enter your last name"
                value={values.lastName}
                onChange={handleInputChange}
              />
            ) : (
              <TextInfo>{values.lastName}</TextInfo>
            )}
          </div>

          <div className="col-12 md:col-6 field">
            <label>Phone Number</label>
            {editMode ? (
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">+1</span>
                <InputText
                  name="phone"
                  placeholder="Enter phone number"
                  value={values.phone}
                  onChange={handleInputChange}
                />
              </div>
            ) : (
              <TextInfo>{values.phone}</TextInfo>
            )}
          </div>

          <div className="col-12 md:col-6 field">
            <label>Linkedin</label>
            {editMode ? (
              <div className="p-inputgroup">
                <AddonBgInherit className="p-inputgroup-addon">
                  linkedin.com/in/
                </AddonBgInherit>
                <InputText
                  name="linkedinId"
                  placeholder="Enter your linkedin id"
                  value={values.linkedinId}
                  onChange={handleInputChange}
                />
              </div>
            ) : (
              <TextInfo>linkedin.com/in/{values.linkedinId}</TextInfo>
            )}
          </div>
        </div>
      </div>

      {editMode && !isOnBoard && (
        <TwoButtons
          className="goals_btm-btns"
          leftLabel="Cancel"
          rightLabel="Save"
          onLeftClick={onCancel}
          onRightClick={onUpdateUser}
        />
      )}
    </div>
  );
};
