import { httpClient } from 'state/actions/http-client';
import { GetUserGoalSummaryDto, GoalStepsExpertsDto } from 'types/dtos';
import { IHttpClientRequestParams } from 'types/Interfaces/IHttpClientRequestParams';

import {
  BackgroundType,
  CreateGoalDto,
  ProfessionalSolutionExpertType,
  ProfessionalSolutionStepType,
  UpdateGoalDto,
  UserGoalSummary,
} from 'types';
import {
  fetchUserGoalsUrl,
  matchSolutionPlanUrl,
  professionalSolutionSteps,
  solutionExpertsUrl,
  userGoalUrl,
} from 'apis';
import { SITE_INFO } from '@constants';
import { Dispatch } from 'redux';
import { RootState } from 'state';
import {
  AuthActionEnum,
  GoalActionEnum,
  StepActionEnum,
  TrendingDataActionEnum,
  UserGoalsActionEnum,
} from 'enums';
import {
  ExpertType,
  SolutionExpertPostDto,
  SolutionStepPostDto,
  StepType,
} from 'types';

const baseUrl = SITE_INFO.APIS.GOALS_APP.URL;

export const fetchUserGoals =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${fetchUserGoalsUrl}`,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const resp = await httpClient.get<GetUserGoalSummaryDto>(parameters);

      dispatch({
        type: UserGoalsActionEnum.SET_USER_GOALS,
        payload: resp.userGoals,
      });
      dispatch({
        type: TrendingDataActionEnum.SET_TRENDING_DATA,
        payload: resp.trendingData,
      });
    } catch (error) {
      throw error;
    }
  };

export const createUserGoal =
  (body: CreateGoalDto) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType, firstAuth } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${userGoalUrl}/n`,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
        payload: body,
      };

      const resp = await httpClient.post<CreateGoalDto, UserGoalSummary>(
        parameters
      );

      dispatch({
        type: UserGoalsActionEnum.ADD_USER_GOAL,
        payload: resp,
      });

      if (firstAuth) {
        dispatch({
          type: AuthActionEnum.UPDATE_FIRST_AUTH,
        });
      }

      return resp;
    } catch (error) {
      throw error;
    }
  };

export const updateUserGoal =
  (body: UpdateGoalDto) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${userGoalUrl}/n`,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
        payload: body,
      };

      // TODO: Wrong response data type. Compare with API
      const resp = await httpClient.put<UpdateGoalDto, BackgroundType>(
        parameters
      );

      dispatch({
        type: UserGoalsActionEnum.UPDATE_USER_GOAL,
        payload: resp,
      });

      return resp;
    } catch (error) {
      throw error;
    }
  };

export const deleteUserGoal =
  (userGoalId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${userGoalUrl}/${userGoalId}`,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const resp = await httpClient.delete(parameters);

      dispatch({
        type: UserGoalsActionEnum.REMOVE_USER_GOAL,
        payload: resp,
      });
    } catch (error) {
      throw error;
    }
  };

export const fetchGoalStepsExperts =
  (goalId: string) => async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${matchSolutionPlanUrl}/${goalId}`,
        // url: 'mock-data/goal-steps-match-data.json',
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const resp = await httpClient.get<GoalStepsExpertsDto>(parameters);

      dispatch({ type: GoalActionEnum.SET_GOAL, payload: resp });

      return resp;
    } catch (error) {
      throw error;
    }
  };

export const clearGoalSteps = () => (dispatch: Dispatch) => {
  dispatch({ type: GoalActionEnum.CLEAR_GOAL });
};

export const addStepToGoal =
  (step: StepType, userGoalId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const payloadData: SolutionStepPostDto = {
        categoryId: step.id,
        userGoalId: userGoalId,
      };

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${professionalSolutionSteps}`,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
        payload: payloadData,
      };

      const resp = await httpClient.post<
        SolutionStepPostDto,
        ProfessionalSolutionStepType
      >(parameters);

      // Need the id of the register in the db for future data handling
      let _step = { ...step };
      _step['solution_step_id'] = resp.id;

      dispatch({ type: GoalActionEnum.ADD_GOAL_STEP, payload: _step });
      dispatch({ type: StepActionEnum.SET_STEP, payload: _step });
    } catch (error) {
      throw error;
    }
  };

export const removeStepFromGoal =
  (step: StepType, userGoalId: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${professionalSolutionSteps}/${userGoalId}/${step.id}`,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const resp = await httpClient.delete(parameters);

      dispatch({ type: GoalActionEnum.REMOVE_GOAL_STEP, payload: step });
    } catch (error) {
      throw error;
    }
  };

export const addExpertToStep =
  (expert: ExpertType, step: StepType) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      if (!step.solution_step_id) {
        throw new Error('Missing solution step id');
      }

      const { token, tokenType } = getState().auth;

      const payloadData: SolutionExpertPostDto = {
        expertExpertiseId: expert.expertise_id,
        expertId: expert.id,
        stepId: step.solution_step_id,
      };

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${solutionExpertsUrl}`,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
        payload: payloadData,
      };

      const resp = await httpClient.post<
        SolutionExpertPostDto,
        ProfessionalSolutionExpertType
      >(parameters);

      // Need the id of the register in the db for future data handling
      expert['solution_expert_id'] = resp.id;
      dispatch({ type: GoalActionEnum.ADD_STEP_EXPERT, payload: expert });
    } catch (error) {
      throw error;
    }
  };

export const removeExpertFromStep =
  (expert: ExpertType) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      if (!expert.solution_expert_id) {
        throw new Error('Missing solution expert id');
      }

      const { token, tokenType } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: `${baseUrl}${solutionExpertsUrl}/${expert.solution_expert_id}`,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const resp = await httpClient.delete(parameters);

      dispatch({ type: GoalActionEnum.ADD_STEP_EXPERT, payload: expert });
    } catch (error) {
      throw error;
    }
    dispatch({ type: GoalActionEnum.REMOVE_STEP_EXPERT, payload: expert });
  };
