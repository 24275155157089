import { Dispatch } from '@reduxjs/toolkit';

import ExpertsSelectedList from './experts-selected-list';
import ExpertChat from './expert-chat';
import { ExpertType, MessageDto, SolutionBuilderStateType } from 'types';
import { IS_ADDING_EXPERTS } from '@constants';
import { ConfirmDialog, NoSelectedDisplay } from 'components';
import { useMemo, useState } from 'react';
import { NoExpertSelected } from 'components/ui/empty-displays/no-expert-selected';
import { Button } from 'primereact/button';

type ExpertsMessagingProps = {
  stepTitle: string;
  expertsSelected: ExpertType[];
  messages: MessageDto[];
  selectedExpert: ExpertType;
  openExpertProfileDialog: (expert: ExpertType) => void;
  onSendMessage: (message: string) => void;
  onRemoveExpert: (expert: ExpertType) => void;
  onChooseExpertToMessage: (expert: ExpertType) => (dispatch: Dispatch) => void;
  onSelectMoreExperts: (
    state: SolutionBuilderStateType
  ) => (dispatch: Dispatch) => void;
};
const ExpertsMessaging = ({
  stepTitle,
  messages,
  onSendMessage,
  expertsSelected,
  onSelectMoreExperts,
  selectedExpert,
  openExpertProfileDialog,
  onChooseExpertToMessage,
  onRemoveExpert,
}: ExpertsMessagingProps) => {
  const [openDelete, setOpenDelete] = useState(false);

  const haveExperts = useMemo((): boolean => {
    if (expertsSelected.length > 0) return true;
    else return false;
  }, [expertsSelected]);

  return (
    <div className="flex flex-column h-full">
      {/* <div className="flex justify-content-between flex-wrap">
        <div>
          <div className="font-weight-800 text-lg">{stepTitle}</div>
        </div>
        <div>
          <Button
            label="Add Experts"
            onClick={() => onSelectMoreExperts({ value: IS_ADDING_EXPERTS })}
          />
        </div>
      </div> */}

      <div className="grid mt-2 h-full">
        {haveExperts && (
          <div className="col-12 flex sm:flex-column align-items-center md:col-1">
            <ExpertsSelectedList
              expertSelected={selectedExpert}
              onChooseExpertToMessage={onChooseExpertToMessage}
              expertsSelected={expertsSelected}
              onSelectMoreExperts={() =>
                onSelectMoreExperts({ value: IS_ADDING_EXPERTS })
              }
            />
            {/* <Button
              icon="pi pi-plus m-auto"
              onClick={() => onSelectMoreExperts({ value: IS_ADDING_EXPERTS })}
            /> */}
          </div>
        )}

        <div className={haveExperts ? 'col-12 md:col-11' : 'col-12'}>
          <div className="card h-full border-round">
            {haveExperts ? (
              selectedExpert.id ? (
                <ExpertChat
                  messages={messages}
                  expert={selectedExpert}
                  openDeleteExpert={() => setOpenDelete(true)}
                  onSendMessage={onSendMessage}
                  openExpertProfileDialog={openExpertProfileDialog}
                />
              ) : (
                <NoExpertSelected />
              )
            ) : (
              <NoSelectedDisplay
                title={
                  <span>
                    <i className="pi pi-search mr-2"></i>
                    Find experts to help you integrate this step
                  </span>
                }
                buttonIcon="pi pi-plus"
                buttonLabel="Select experts here!"
                onSelect={() =>
                  onSelectMoreExperts({ value: IS_ADDING_EXPERTS })
                }
              />
            )}
          </div>
        </div>
      </div>

      <ConfirmDialog
        open={openDelete}
        title="Are you sure you want to remove this partner?"
        message={selectedExpert?.name}
        onHide={() => setOpenDelete(false)}
        onAccept={() => onRemoveExpert(selectedExpert)}
      />
    </div>
  );
};

export default ExpertsMessaging;
