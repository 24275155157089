import { breakPoints } from '../../@constants';
import { Toolbar } from 'primereact/toolbar';
import styled from 'styled-components';

/**
 * Login form styles
 */

export const FormContainer = styled.div`
  display: inline-block;
  width: 370px;

  @media screen and (max-width: ${breakPoints.sm}px) {
    width: 100% !important;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const CustomToolbar = styled(Toolbar)`
  border: 0px;
  background-color: inherit;
  padding: 0px;
`;
