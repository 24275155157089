import { Dialog } from 'primereact/dialog';

import { ExpertType } from 'types';
import { ThreeNineLayout } from 'layouts';
import { DoneButton } from 'components/ui/button/done-button';
import { ExpertDescription } from './profile.style';
import { userSvg } from 'assets';
import { handleImageError } from 'utilities';

type ExpertProfileProps = {
  open: boolean;
  expert: ExpertType;
  onHide: () => void;
};

export const ExpertProfileDialog = ({
  open,
  expert,
  onHide,
}: ExpertProfileProps) => {
  return (
    <Dialog
      visible={open}
      onHide={onHide}
      style={{ maxWidth: '800px', width: '700px' }}
      header={'Expert Profile'}
      contentClassName="pb-0"
      footer={<DoneButton onDone={onHide} />}
    >
      <ThreeNineLayout
        threeComponent={
          <div className="flex flex-column align-items-center">
            <img
              className="w-10rem h-10rem border-circle"
              src={expert?.image ? expert?.image : userSvg}
              alt="expert"
              onError={handleImageError}
            />
            <div className="text-xl font-bold mt-3">{expert?.name}</div>
          </div>
        }
        nineComponent={
          <div className="schedule">
            {expert.description && (
              <ExpertDescription className="mb-4" cite={expert?.name}>
                <i>{expert?.description}</i>
              </ExpertDescription>
            )}
            <ul className="grid">
              <li className="col-12">
                <div className="schedule-header">
                  <h6>{expert?.experience}</h6>
                  <span>
                    <span></span>
                  </span>
                </div>
                <span>Experience</span>
              </li>

              <li className="col-12">
                <div className="schedule-header">
                  <h6>{expert?.company}</h6>
                  <span>
                    <span></span>
                  </span>
                </div>
                <span>Company</span>
              </li>

              <li className="col-12">
                <div className="schedule-header">
                  <h6>{expert?.designation}</h6>
                  <span>
                    <span></span>
                  </span>
                </div>
                <span>Designation</span>
              </li>
            </ul>
          </div>
        }
      />
    </Dialog>
  );
};
