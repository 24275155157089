import { createContext } from 'react';
import { ChatAddMessageType, ChatsType, ChatType } from 'types';

type ChatContextProps = {
  chats: ChatsType;
  addChat: (chat: ChatType) => void;
  addChatMessage: (message: ChatAddMessageType) => void;
};

export const ChatContext = createContext<ChatContextProps>(
  {} as ChatContextProps
);
