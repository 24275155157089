import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, RootState } from 'state';

export const useAuth = () => {
  const state = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const { login, logout } = bindActionCreators(authActions, dispatch);

  return {
    ...state,
    login,
    logout,
    authData: state,
  };
};
