import { DividerContainer } from './layouts.style';

type Props = {
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  dividerComponent?: React.ReactNode;
  leftComponent: React.ReactNode;
  rightComponent: React.ReactNode;
};

export const SplitLayout: React.FunctionComponent<Props> = ({
  leftComponent,
  dividerComponent = 'or',
  rightComponent,
}) => {
  return (
    <>
      <div className="grid m-0">
        <div className="col-12 md:col-5 text-center md:text-right">
          {leftComponent}
        </div>

        <DividerContainer className="col-12 md:col-2">
          {dividerComponent}
        </DividerContainer>

        <div className="col-12 md:col-5 text-center md:text-left">
          {rightComponent}
        </div>
      </div>
    </>
  );
};
