import { useSelector } from 'react-redux';
import { RootState } from 'state';

export const useTrendingData = () => {
  const trends = useSelector((state: RootState) => state.trendingData);

  return {
    trends: [...trends],
  };
};
