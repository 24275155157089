import { useCallback, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router';

import GoalsList from 'components/goals/goals-list';
import ComunityTrends from 'components/comunity-trends/comunity-trends';
import { MainCardContainer } from './pages.style';
import { useGoal, useToast, useUserGoals } from 'hooks';
import { UserGoalSummary } from 'types';
import { Loading, GoalDialog, ConfirmDialog } from 'components';
import { useTrendingData } from 'hooks/use-trending-data';

const GoalsDashboardPage = () => {
  const history = useHistory();

  const { showSuccessToast, showErrorToast } = useToast();
  const { fetchGoalStepsExperts, deleteUserGoal } = useGoal();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [openDeleteGoal, setOpenDeleteGoal] = useState(false);
  const [goal, setGoal] = useState<UserGoalSummary | undefined>(undefined);
  const [goalHighlight, setGoalHighlight] = useState<string>('');
  const [goalDelete, setGoalDelete] = useState<UserGoalSummary | undefined>(
    undefined
  );

  const { trends } = useTrendingData();
  const { userGoals, businessChallenges, fetchUserGoals } = useUserGoals();

  const loadUserGoals = useCallback(async () => {
    try {
      setLoading(true);
      if (userGoals.length === 0) {
        await fetchUserGoals();
      }
    } catch (error) {
      showErrorToast(undefined, error);
    }
  }, []);

  useEffect(() => {
    loadUserGoals().then(() => setLoading(false));
  }, []);

  // TODO ASYNC
  const openDeleteGoalDialog = async (goal: UserGoalSummary) => {
    setOpenDeleteGoal(true);
    setGoalDelete(goal);
  };

  const onHideGoalDialog = (): void => {
    setOpen(false);
    setGoal(undefined);
    setEditMode(false);
  };

  const onHideDeleteGoal = () => {
    setOpenDeleteGoal(false);
    setGoalDelete(undefined);
  };

  const onSelectGoal = async (id: string) => {
    setLoading(true);
    try {
      await fetchGoalStepsExperts(id);
      history.push(`/goal/${id}`);
    } catch (error) {
      setLoading(false);
      showErrorToast(undefined, error);
    }
  };

  const onEditGoal = (goal: UserGoalSummary) => {
    setGoal(goal);
    setOpen(true);
    setEditMode(true);
  };

  const onDuplicateGoal = (goal: UserGoalSummary) => {
    setGoal(goal);
    setOpen(true);
    setEditMode(false);
  };

  const onDeleteGoal = async () => {
    if (goalDelete) {
      setLoading(true);
      try {
        await deleteUserGoal(goalDelete.id);
        showSuccessToast(
          'Goal Deleted',
          goalDelete.attributes.businessChallenge
        );
      } catch (error) {
        showErrorToast(undefined, error);
      } finally {
        setOpenDeleteGoal(false);
        setGoalDelete(undefined);
        setLoading(false);
      }
    }
  };

  const afterCreate = (goal: UserGoalSummary) => {
    setGoalHighlight(goal.id);
    setTimeout(() => setGoalHighlight(''), 3000);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <MainCardContainer>
      <div className="card p-0">
        <div className="grid m-0 h-full">
          <div className="col-12 lg:col-8">
            <div className="col-12 md:col-12 lg:col-12">
              <div className="flex justify-content-between p-3">
                <div>
                  <h2 className="text-dark">Your Goals</h2>
                </div>
                <div>
                  <Button
                    label="Create Goal"
                    icon="pi pi-plus"
                    className="text-base"
                    onClick={() => setOpen(true)}
                  />
                </div>
              </div>

              <GoalsList
                goalHighlight={goalHighlight}
                goals={userGoals}
                onEditGoal={onEditGoal}
                onDuplicateGoal={onDuplicateGoal}
                onDeleteGoal={openDeleteGoalDialog}
                onSelectGoal={onSelectGoal}
              />
            </div>
          </div>
          <div className="col-12 lg:col-4 p-0">
            <div className="h-full">
              <ComunityTrends trendingGoals={trends} />
            </div>
          </div>
        </div>
      </div>

      <GoalDialog
        goal={goal}
        open={open}
        editMode={editMode}
        businessChallenges={businessChallenges}
        afterCreate={afterCreate}
        onHide={onHideGoalDialog}
      />

      <ConfirmDialog
        open={openDeleteGoal}
        title="Are you sure to delete?"
        message="if you delete goal, you lose all entered data (Steps, Experts)."
        onHide={onHideDeleteGoal}
        onAccept={onDeleteGoal}
      />
    </MainCardContainer>
  );
};

export default GoalsDashboardPage;
