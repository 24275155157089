import React from 'react';
import { Redirect, Route } from 'react-router';

type PublicRouteProps = {
  isAuthenticated: boolean;
  component: React.ComponentType;
};

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}: PublicRouteProps) => {
  return (
    <Route
      {...rest}
      component={(props: any) =>
        !isAuthenticated ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
