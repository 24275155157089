import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { HAS_NO_STEPS_SELECTED, HAS_STEPS_SELECTED } from '@constants';
import { MainCardContainer } from './pages.style';
import { FourEightLayout } from 'layouts';
import { createMessage, listGoalMessagesByExpert } from 'state';
import {
  useGoal,
  useChat,
  useStep,
  useToast,
  useExpert,
  useApplication,
  useSolutionBuilder,
} from 'hooks';
import {
  GoalStepsMenu,
  Loading,
  ExpertsEngagement,
  NoStepsFound,
  ExpertProfileDialog,
  ExpertsFilerDialog,
  StepsSelectionDialog,
  ConfirmDialog,
  NoStepsSelected,
} from 'components';
import {
  StepType,
  GoalEngagementStepsStateType,
  ExpertType,
  emptyExpert,
  ListGoalMessagesType,
  MessageDto,
  CreateMessageDto,
} from 'types';

const GoalPage = () => {
  const dispatch = useDispatch();
  const { showErrorToast } = useToast();
  const [loading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [messages, setMessages] = useState<MessageDto[]>([]);
  const [openExpertDelete, setOpenExpertDelete] = useState(false);
  const [openExpertProfile, setOpenExpertProfile] = useState(false);
  const [expertActions, setExpertActions] = useState<ExpertType | undefined>();

  const { id: userGoalId } = useParams<{ id: string }>();
  const { step: selectedStep, setStep, clearStep } = useStep();
  const { clearAppState, setIsPending } = useApplication();
  const { expert: selectedExpert, setExpert, clearExpert } = useExpert();
  const { clearBuilderState } = useSolutionBuilder();
  const { retrieveChat, addChat, addChatMessage } = useChat();
  const {
    businessChallenge,
    stepsSelected,
    stepsNotSelected,
    addStepToGoal,
    removeStepFromGoal,
    currentStepsState,
    clearGoalSteps,
    removeExpertFromStep,
    addExpertToStep,
    expertsSelected,
    expertsNotSelected,
    allExperts,
  } = useGoal();

  // TODO
  // Rename document name

  const loadMessages = useCallback(async () => {
    try {
      const body: ListGoalMessagesType = {
        expert_id: selectedExpert.id,
        goal_id: userGoalId,
      };

      const chat = retrieveChat(body.expert_id, body.goal_id);

      if (chat) {
        setMessages(chat.messages);
      } else {
        const _messages = await dispatch<any>(listGoalMessagesByExpert(body));
        if (_messages) {
          setMessages(_messages);
          addChat({
            expert_id: body.expert_id,
            goal_id: body.goal_id,
            messages: _messages,
          });
        }
      }
    } catch (error) {
      // showError(error);
    }
  }, [selectedExpert, userGoalId]);

  /**
   * Listeners
   */

  useEffect(() => {
    if (stepsNotSelected.length === 0) {
      closeStepsSelectionDialog();
    }
  }, [stepsNotSelected]);

  // Selecting first step goal when mouting
  useEffect(() => {
    if (stepsSelected.length > 0 && selectedStep.id === '') {
      setStep(stepsSelected[0]);
    }
  }, [stepsSelected, selectedStep]);

  // Auto select a expert when step change, if it've selected experts
  useEffect(() => {
    if (selectedStep.experts.filter((e) => e.isChosen === true).length > 0) {
      const _expert = selectedStep.experts.find((e) => e.isChosen === true);
      if (_expert) setExpert(_expert);
    } else {
      clearExpert();
    }
  }, [selectedStep]);

  useEffect(() => {
    loadMessages();
  }, [selectedExpert, loadMessages]);

  // Cleaning related goal data in Store when unmounting
  useEffect(() => {
    return () => {
      clearBuilderState();
      clearGoalSteps();
      clearExpert();
      clearStep();
      clearAppState();
    };
  }, []);

  const closeStepsSelectionDialog = () => {
    setOpen(false);
  };

  const closeExpertsFilterDialog = () => {
    setOpenFilter(false);
  };

  const openStepsSelectionDialog = () => {
    setOpen(true);
  };

  const openExpertsFilterDialog = () => {
    setOpenFilter(true);
  };

  const openExpertProfileDialog = (expert: ExpertType) => {
    setOpenExpertProfile(true);
    setExpertActions(expert);
  };

  const openExpertDeleteDialog = (expert: ExpertType) => {
    setOpenExpertDelete(true);
    setExpertActions(expert);
  };

  /**
   * on events
   */

  const onHideExpertDeleteDialog = () => {
    setOpenExpertDelete(false);
    setExpertActions(undefined);
  };

  const onHideExpertProfileDialog = () => {
    setOpenExpertProfile(false);
    setExpertActions(undefined);
  };

  const onAddExpertToSolution = async (expert: ExpertType) => {
    try {
      setIsPending({ isPending: true });
      await addExpertToStep(expert, selectedStep);
      if (!selectedExpert.id) setExpert(expert);
    } catch (error) {
      showErrorToast(undefined, error);
    } finally {
      setIsPending({ isPending: false });
    }
  };

  const onRemoveExpertFromSolution = async (expert: ExpertType) => {
    try {
      setIsPending({ isPending: true });
      await removeExpertFromStep(expert);
    } catch (error) {
      showErrorToast(undefined, error);
    } finally {
      setIsPending({ isPending: false });
    }
  };

  const onRemoveExpert = () => {
    if (expertActions) {
      removeExpertFromStep(expertActions);
      setOpenExpertDelete(false);
      setExpertActions(undefined);
    }
  };

  const onAddStepToSolution = async (step: StepType) => {
    try {
      setIsPending({ isPending: true });
      await addStepToGoal(step, userGoalId);
    } catch (error) {
      showErrorToast(undefined, error);
    } finally {
      setIsPending({ isPending: false });
    }
  };

  const onRemoveStep = async (step: StepType) => {
    try {
      setIsPending({ isPending: true });
      await removeStepFromGoal(step, userGoalId);
      if (step.id === selectedStep.id) clearStep();
    } catch (error) {
      showErrorToast(undefined, error);
    } finally {
      setIsPending({ isPending: false });
    }
  };

  const onChooseStep = (step: StepType) => {
    if (selectedStep.id !== step.id) {
      setStep(step);
      // For showing the messaging screen again
      clearBuilderState();
    }
  };

  const onFilterSelection = () => {};

  const onSendMessage = async (message: string) => {
    try {
      if (selectedExpert.id && selectedExpert.solution_expert_id && message) {
        const body: CreateMessageDto = {
          content: message,
          sendTo: selectedExpert.id,
          solutionId: selectedExpert.solution_expert_id,
          step_id: selectedStep.id,
          type: 'message',
        };

        const _message = await dispatch<any>(createMessage(body));

        if (_message) {
          const _message: MessageDto = {
            created: '09:00 PM Yesterday',
            message: message,
            message_type: 'message',
            user: 'You',
            user_id: '',
            user_image: '',
          };
          addChatMessage({
            expert_id: selectedExpert.id,
            goal_id: userGoalId,
            message: _message,
          });
          setMessages((messages) => {
            return [...messages, _message];
          });
        }
      }
    } catch (error) {}
  };

  const stepsStateComponent = ({ value }: GoalEngagementStepsStateType) => {
    switch (value) {
      case HAS_NO_STEPS_SELECTED:
        return <NoStepsSelected addSteps={openStepsSelectionDialog} />;
      case HAS_STEPS_SELECTED:
        return (
          <ExpertsEngagement
            messages={messages}
            onSendMessage={onSendMessage}
            chosenStep={selectedStep}
            expertsSelected={expertsSelected}
            expertsAvailable={expertsNotSelected}
            allExperts={allExperts}
            selectedExpert={selectedExpert}
            openExpertsFilterDialog={openExpertsFilterDialog}
            openExpertProfileDialog={openExpertProfileDialog}
            onChooseExpertToMessage={setExpert}
            onAddExpert={onAddExpertToSolution}
            onRemoveExpert={onRemoveExpertFromSolution}
            openExpertDeleteDialog={openExpertDeleteDialog}
          />
        );
      default:
        return <NoStepsFound />;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <MainCardContainer>
      <div className="card p-0">
        <FourEightLayout
          spacing={false}
          border={true}
          threeComponent={
            <GoalStepsMenu
              businessChallenge={businessChallenge}
              stepsNotSelected={stepsNotSelected}
              selectedSteps={stepsSelected}
              chosenStepId={selectedStep.id}
              onChooseStep={onChooseStep}
              onRemoveStep={onRemoveStep}
              openStepsSelectionDialog={openStepsSelectionDialog}
            />
          }
          eightComponent={stepsStateComponent(currentStepsState)}
        />
      </div>

      <StepsSelectionDialog
        open={open}
        onHide={closeStepsSelectionDialog}
        stepsAvailable={stepsNotSelected}
        onAddStepToSolution={onAddStepToSolution}
      />

      <ExpertsFilerDialog
        open={openFilter}
        onHide={closeExpertsFilterDialog}
        onFilterSelection={onFilterSelection}
      />

      <ExpertProfileDialog
        open={openExpertProfile}
        onHide={onHideExpertProfileDialog}
        expert={expertActions || emptyExpert}
      />

      <ConfirmDialog
        open={openExpertDelete}
        title="Are you sure you want to remove this partner?"
        message={expertActions?.name}
        onHide={onHideExpertDeleteDialog}
        onAccept={onRemoveExpert}
      />
    </MainCardContainer>
  );
};

export default GoalPage;
