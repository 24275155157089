import { Dispatch } from '@reduxjs/toolkit';

import { sendEmailUrl } from 'apis';
import { IHttpClientRequestParams, SendEmailDto } from 'types';
import { httpClient } from './http-client';

export const sendEmail = (data: SendEmailDto) => async (dispatch: Dispatch) => {
  try {
    const parameters: IHttpClientRequestParams = {
      url: sendEmailUrl,
      requiresToken: false,
      payload: data,
    };

    const resp = await httpClient.post<SendEmailDto, any>(parameters);

    return resp;
  } catch (error) {
    throw error;
  }
};
