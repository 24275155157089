import { MenuItem, MenuItemCommandParams } from 'primereact/menuitem';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import { useProfile, useForm as useCForm, useUser, useToast } from 'hooks';
import { ThreeNineLayout } from 'layouts';
import { CreatePasswordType, ProfileType, UserType } from 'types';
import { MainCardContainer } from './pages.style';
import {
  MenuNoBorder,
  ProfileCompanyForm,
  ProfileSecurityForm,
  ProfileUserForm,
} from 'components';
import { useApplication } from 'hooks/use-application';
import { useDispatch } from 'react-redux';
import { updateSecurity } from 'state';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const menuKeys = {
  userDetails: 'user',
  companyDetails: 'company',
  securityDetails: 'security',
};

export const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, updateUser } = useUser();
  const { profile, updateProfile } = useProfile();

  const { setIsPending, clearAppState } = useApplication();

  const [userEdit, setUserEdit] = useState<boolean>(false);
  const [companyEdit, setCompanyEdit] = useState<boolean>(false);
  const [securityEdit, setSecurityEdit] = useState<boolean>(false);
  const [menuKey, setMenuKey] = useState<string>(menuKeys.userDetails);
  const { showSuccessToast, showErrorToast } = useToast();

  const { values: companyValues, handleInputChange: companyHandler } =
    useCForm<ProfileType>(profile);

  const { values: userValues, handleInputChange: userHandler } =
    useCForm<UserType>(user);

  const { values: securityValues, handleInputChange: securityHandler } =
    useCForm<CreatePasswordType>({
      password: '',
      confirmPassword: '',
    });

  const companySchema = object({
    companyTitle: string().trim().required('Name is required').default(''),
    zipCode: string().trim().required('Zip code is required').default(''),
    title: string().oneOf(rolesTitle).required('Rol is required').default(''),
    businessType: string()
      .oneOf(businesses)
      .required('Business type is required')
      .default(''),
    operatingIndustry: string()
      .oneOf(operatingIndustries)
      .required('Operating industry is required')
      .default(''),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileType>({
    defaultValues: companySchema.cast({}),
    resolver: yupResolver(companySchema),
  });

  const onCommand = ({ item }: MenuItemCommandParams) => {
    if (item.key) {
      setMenuKey(item.key);
    }
  };

  const menuitems: MenuItem[] = [
    {
      label: 'Settings',
      disabled: true,
      className: 'menu_side',
    },
    {
      key: menuKeys.userDetails,
      label: 'User details',
      icon: 'pi pi-user',
      command: onCommand,
      style: {
        borderLeft:
          menuKey === menuKeys.userDetails && '3px solid var(--primary-color)',
      },
    },
    {
      key: menuKeys.companyDetails,
      label: 'Company details',
      icon: 'pi pi-building',
      command: onCommand,
      style: {
        borderLeft:
          menuKey === menuKeys.companyDetails &&
          '3px solid var(--primary-color)',
      },
    },
    {
      key: menuKeys.securityDetails,
      label: 'Password & Security',
      icon: 'pi pi-lock',
      command: onCommand,
      style: {
        borderLeft:
          menuKey === menuKeys.securityDetails &&
          '3px solid var(--primary-color)',
      },
    },
    {
      separator: true,
    },
    {
      label: 'Go back',
      icon: 'pi pi-arrow-left',
      command: () => history.push('/'),
    },
  ];

  /*
   * on events functions
   */

  // Cleaning related goal data in Store when unmounting
  useEffect(() => {
    return () => {
      clearAppState();
    };
  }, []);

  const onActiveUserEdit = () => {
    setUserEdit(true);
  };

  const onActiveCompanyEdit = () => {
    setCompanyEdit(true);
  };

  const onActiveSecutiryEdit = () => {
    setSecurityEdit(true);
  };

  const onUpdateUser = async () => {
    try {
      setIsPending({ isPending: true });

      await updateUser(userValues);
      setUserEdit(false);
      showSuccessToast(undefined, 'User Data Updated');
    } catch (error) {
      showErrorToast(undefined, error);
    } finally {
      setIsPending({ isPending: false });
    }
  };

  const onUpdateCompany = async () => {
    try {
      setIsPending({ isPending: true });

      await updateProfile(companyValues);
      showSuccessToast(undefined, 'Company Data Updated');
      setCompanyEdit(false);
    } catch (error) {
      showErrorToast(undefined, error);
    } finally {
      setIsPending({ isPending: false });
    }
  };

  const onUpdateSecurity = async () => {
    if (
      !securityValues.password.trim() ||
      !securityValues.confirmPassword.trim()
    ) {
      showErrorToast('Validation error', 'Empty field(s)');
      return;
    }

    if (
      securityValues.password.trim() !== securityValues.confirmPassword.trim()
    ) {
      showErrorToast('Validation error', "Passwords aren't equals");
      return;
    }

    try {
      const _user: UserType = user;
      _user.password = securityValues.password;
      await dispatch<any>(updateSecurity(_user));
      setSecurityEdit(false);
      showSuccessToast(undefined, 'Updated password');
    } catch (error) {
      showErrorToast(undefined, error);
    }
  };

  /*
   * Templates
   */

  const switchComponent = (): React.ReactNode => {
    switch (menuKey) {
      case menuKeys.userDetails:
        return (
          <ProfileUserForm
            values={userValues}
            handleInputChange={userHandler}
            onCancel={() => setUserEdit(false)}
            onActiveEdit={onActiveUserEdit}
            editMode={userEdit}
            onUpdateUser={onUpdateUser}
          />
        );
      case menuKeys.companyDetails:
        return (
          <ProfileCompanyForm
            values={companyValues}
            handleInputChange={companyHandler}
            onCancel={() => setCompanyEdit(false)}
            onActiveEdit={onActiveCompanyEdit}
            editMode={companyEdit}
            onUpdateCompany={onUpdateCompany}
          />
        );
      case menuKeys.securityDetails:
        return (
          <ProfileSecurityForm
            email={userValues.email}
            values={securityValues}
            handleInputChange={securityHandler}
            onCancel={() => setSecurityEdit(false)}
            onActiveEdit={onActiveSecutiryEdit}
            editMode={securityEdit}
            onSave={onUpdateSecurity}
          />
        );
      default:
        break;
    }
  };

  const componentWrapper = () => {
    return <div className="h-full">{switchComponent()}</div>;
  };

  return (
    <MainCardContainer>
      <div className="card">
        <ThreeNineLayout
          threeComponent={<MenuNoBorder model={menuitems} />}
          nineComponent={componentWrapper()}
        />
      </div>
    </MainCardContainer>
  );
};

const businesses: string[] = [
  'Small Business',
  'Small Enterprise',
  'Large Enterprise',
  'Other',
];

const operatingIndustries: string[] = [
  'Real Estate & Property',
  'Farming, Animals & Conservation',
  'Consulting & Strategy',
  'Sport & Recreation',
  'Banking & Financial Services',
  'Community Services & Development',
  'Trades & Services',
  'Information & Communication Technology',
  'Insurance & Superannuation',
  'Education & Training',
  'Legal',
  'Construction',
  'Mining, Resources & Energy',
  'Administration & Office Support',
  'Engineering',
  'Government & Defence',
  'Call Centre & Customer Service',
  'Design & Architechture',
  'Human Resources & Recruitment',
  'Advertising, Arts & Media',
  'Retail & Consumer Products',
  'Sales',
  'Manufacturing, Transport & Logistics',
  'Accounting',
  'Hospitality & Tourism',
  'Healthcare & Medical',
  'Science & Technology',
  'Marketing & Communications',
];

const rolesTitle: string[] = [
  'CEO',
  'COO',
  'CTO',
  'CHRO',
  'CSO',
  'CPO',
  'President',
  'VP',
  'Sr. Director',
  'Director',
  'Manager',
  'Owner',
  'Consultant',
  'Partner',
  'Other',
];
