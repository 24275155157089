import { Dispatch } from 'redux';
import { SolutionBuilderActionEnum } from 'enums';
import { SolutionBuilderStateType } from 'types';

export const setBuilderState =
  (state: SolutionBuilderStateType) => (dispatch: Dispatch) => {
    dispatch({ type: SolutionBuilderActionEnum.SET_STATE, payload: state });
  };

export const clearBuilderState = () => (dispatch: Dispatch) => {
  dispatch({ type: SolutionBuilderActionEnum.CLEAR_STATE });
};
