import React from 'react';
import { Redirect, Route } from 'react-router';

type PrivateRouteProps = {
  isAuthenticated: boolean;
  component: React.ComponentType;
  setColorScheme: () => void;
};

export const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  setColorScheme,
  ...rest
}: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      component={(props: any) =>
        isAuthenticated ? (
          <Component setColorScheme={setColorScheme} {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
