import { StepType } from 'types';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { GoalStepsItemContainer } from './goal-steps.style';
import { useMemo, useState } from 'react';
import { ConfirmDialog } from 'components';

type GoalStepItemProps = {
  step: StepType;
  chosenStepId: string;
  onRemoveStep: (step: StepType) => void;
  onChooseStep: (step: StepType) => void;
};
const GoalStepItem = ({
  step,
  onChooseStep,
  chosenStepId,
  onRemoveStep,
}: GoalStepItemProps) => {
  const { id, title, experts } = step;
  const [openDelete, setOpenDelete] = useState(false);

  const isActive = useMemo((): boolean => {
    if (id === chosenStepId) return true;
    else return false;
  }, [id, chosenStepId]);

  return (
    <GoalStepsItemContainer
      className="border-round cursor-pointer mb-3 p-4"
      isActive={isActive}
      onClick={() => {
        /**
         * This condition, cancel the section of the deleted step
         */
        if (!openDelete) {
          onChooseStep(step);
        }
      }}
    >
      {/* {isActive && (
        <Badge
          className="border-circle bg-blue-500 right-100"
          size="large"
          value={<i className="pi pi-check"></i>}
        />
      )} */}

      <div className="flex justify-content-between align-items-center font-bold">
        <div className={isActive ? 'text-green-600' : ''}>{title}</div>

        {isActive && (
          <Button
            className="p-button-text p-button-rounded p-button-success"
            icon="pi pi-trash"
            onClick={(e) => {
              e.stopPropagation();
              setOpenDelete(true);
            }}
          />
        )}
      </div>

      <ConfirmDialog
        title="Delete selected solution step?"
        message="By deleting a solution step you are removing it from your solution plan
        and will lose contact with any previous partners."
        open={openDelete}
        onHide={() => setOpenDelete(false)}
        onAccept={() => onRemoveStep(step)}
      />
    </GoalStepsItemContainer>
  );
};

export default GoalStepItem;
