/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useDispatch } from 'react-redux';

import { ThreeNineLayout } from 'layouts';
import {
  useForm,
  useProfile,
  useUser,
  useAuth,
  useToast,
  useUserGoals,
} from 'hooks';
import {
  listIndustries,
  listProblemSolutionByCategories,
  createUserGoal,
  updateUserGoal,
} from 'state';
import {
  GoalListDialog,
  ContentContainer,
  GoalScopeForm,
  GoalBackgroundForm,
} from '.';
import {
  VerticalSteps,
  TwoButtons,
  ProfileCompanyForm,
  ProfileUserForm,
  ConfirmDialog,
} from 'components';
import {
  GoalBackground,
  GoalScope,
  ProfileType,
  StepsItem,
  IndustryDto,
  CategoryDto,
  ProblemSolutionDto,
  ListByCategoriesParams,
  UserType,
  emptyGoalScope,
  emptyGoalBackground,
  CreateGoalDto,
  UserGoalSummary,
  UpdateGoalDto,
} from 'types';

/**
 *
 */

type GoalDialogProps = {
  open: boolean;
  editMode?: boolean;
  goal?: UserGoalSummary;
  businessChallenges?: string[];
  onHide: () => void;
  afterCreate?: (goal: UserGoalSummary) => void;
  afterUpdate?: (goal: UserGoalSummary) => void;
};

export const GoalDialog = React.memo(
  ({
    open,
    goal,
    onHide,
    afterCreate,
    editMode = false,
    businessChallenges = [],
  }: GoalDialogProps) => {
    const dispatch = useDispatch();

    const { firstAuth } = useAuth();
    const { userGoals } = useUserGoals();
    const { user, updateUser } = useUser();
    const { profile, updateProfile } = useProfile();
    const { showSuccessToast, showErrorToast } = useToast();

    const [loading, setLoading] = useState(false);
    const [submited, setSubmited] = useState(false);
    const [isQuestion, setIsQuestion] = useState(true);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [openListDialog, setOpenListDialog] = useState(false);
    const [openCloseDialog, setOpenCloseDialog] = useState(false);
    const [industries, setIndustries] = useState<IndustryDto[]>([]);
    const [categories1, setCategories1] = useState<CategoryDto[]>([]);
    const [categories2, setCategories2] = useState<CategoryDto[]>([]);
    const [categories3, setCategories3] = useState<CategoryDto[]>([]);
    const [problemSolutions, setProblemSolutions] = useState<
      ProblemSolutionDto[]
    >([]);

    /**
     * Values and Input change handlers (Handler)
     */

    const {
      values: scopeValues,
      setValues: setScopeValues,
      handleInputChange: scopeHandler,
    } = useForm<GoalScope>(emptyGoalScope);

    const {
      values: backgroundValues,
      setValues: setBackgroundValues,
      handleInputChange: backgroundHandler,
    } = useForm<GoalBackground>(emptyGoalBackground);

    const {
      values: companyValues,
      setValues: setCompanyValues,
      handleInputChange: companyHandler,
    } = useForm<ProfileType>(profile);

    const {
      values: userValues,
      setValues: setUserValues,
      handleInputChange: userHandler,
    } = useForm<UserType>(user);

    /**
     * Init/Load values
     */

    const loadIndustries = useCallback(async () => {
      try {
        const industries = await dispatch<any>(listIndustries());
        setIndustries(industries);
      } catch (error) {
        showErrorToast(undefined, error);
      }
    }, []);

    const loadProblemStatements = async () => {
      if (scopeValues.category1 && scopeValues.category2) {
        const params: ListByCategoriesParams = {
          primary: scopeValues.category1.id,
          secondary: scopeValues.category2.id,
          tertiary: scopeValues.category3?.id,
        };

        try {
          const _problemSolutions: ProblemSolutionDto[] = await dispatch<any>(
            listProblemSolutionByCategories(params)
          );
          if (editMode) {
            setProblemSolutions(_problemSolutions);
          } else {
            const __problemSolutionsFiltered = _problemSolutions.filter(
              (p) => !businessChallenges.includes(p.name)
            );
            setProblemSolutions(__problemSolutionsFiltered);
          }
        } catch (error) {
          showErrorToast(undefined, error);
        }
      }
    };

    /**
     * Listeners
     */

    // TODO
    // Category 2 dont deleting at to change category 1

    useEffect(() => {
      if (goal && goal.id) {
        const { attributes } = goal;
        const {
          questions,
          wishlist,
          maxBudget,
          minBudget,
          challengeSummarize,
        } = attributes;

        setScopeValues({
          ...scopeValues,
          problemDescription: challengeSummarize,
        });

        setBackgroundValues({
          maxBudget: parseInt(maxBudget),
          minBudget: parseInt(minBudget),
          question1: questions ? questions[0] : '',
          question2: questions ? questions[1] : '',
          question3: questions ? questions[2] : '',
          outcome1: wishlist ? wishlist[0] : '',
          outcome2: wishlist ? wishlist[1] : '',
          outcome3: wishlist ? wishlist[2] : '',
        });
      }
    }, [goal]);

    useEffect(() => {
      if (goal) {
        setScopeValues({
          ...scopeValues,
          category1: categories1.find((c) => c.id === goal.keywords[0]),
        });
      }
    }, [categories1]);

    useEffect(() => {
      if (goal) {
        setScopeValues({
          ...scopeValues,
          category2: categories2.find((c) => c.id === goal.keywords[1]),
        });
      }
    }, [categories2]);

    useEffect(() => {
      if (goal) {
        setScopeValues({
          ...scopeValues,
          category3: categories3.find((c) => c.id === goal.keywords[2]),
        });
      }
    }, [categories3]);

    useEffect(() => {
      if (goal) {
        setScopeValues({
          ...scopeValues,
          problemStatement: problemSolutions.find(
            (c) => c.name === goal.attributes.businessChallenge
          ),
        });
      }
    }, [problemSolutions]);

    /**
     *
     */

    useEffect(() => {
      if (industries && industries.length > 0) {
        scopeHandler({ target: { name: 'industry', value: industries[0] } });
      }
    }, [industries]);

    useEffect(() => {
      if (open && industries && industries.length === 0) {
        loadIndustries();
      }
    }, [open, loadIndustries]);

    useEffect(() => {
      if (scopeValues.industry?.id) {
        const _industry = industries.find(
          (industry) => industry.id === scopeValues.industry?.id
        );
        if (_industry?.children) {
          setCategories1(_industry.children);
        }
      } else {
        setCategories1([]);
      }
      scopeHandler({ target: { name: 'category1', value: undefined } });
    }, [scopeValues.industry]);

    useEffect(() => {
      if (scopeValues.category1?.id) {
        const _category1 = categories1.find(
          (category) => category.id === scopeValues.category1?.id
        );
        if (_category1?.children_categories) {
          setCategories2(_category1.children_categories);
        }
      } else {
        setCategories2([]);
      }
      scopeHandler({ target: { name: 'category2', value: undefined } });
    }, [scopeValues.category1]);

    useEffect(() => {
      if (scopeValues.category2?.id) {
        const _category2 = categories2.find(
          (category) => category.id === scopeValues.category2?.id
        );
        if (_category2?.children_categories) {
          setCategories3(_category2.children_categories);
        }
      } else {
        setCategories3([]);
      }
      scopeHandler({ target: { name: 'category3', value: undefined } });
    }, [scopeValues.category2]);

    useEffect(() => {
      loadProblemStatements();
      scopeHandler({ target: { name: 'problemStatement', value: undefined } });
    }, [scopeValues.category1, scopeValues.category2, scopeValues.category3]);

    const MIN_LENGTH: number = 0;
    const MAX_LENGTH: number = firstAuth && userGoals.length <= 0 ? 3 : 1;

    const stepsItems: StepsItem[] = [
      {
        label: 'Create goal',
        icon: 'pi pi-cog',
        items: [
          {
            index: 0,
            label: 'Scope',
            command: (e) => setActiveIndex(e.item.index),
          },
          {
            index: 1,
            label: 'Background',
            optional: true,
            command: (e) => setActiveIndex(e.item.index),
          },
        ],
      },
      firstAuth && userGoals.length <= 0
        ? {
            label: 'Onboard profile',
            icon: 'pi pi-user',
            items: [
              {
                index: 2,
                label: 'User details',
                command: (e) => setActiveIndex(e.item.index),
              },
              {
                index: 3,
                label: 'Company details',
                command: (e) => setActiveIndex(e.item.index),
              },
            ],
          }
        : {},
    ];

    const clear = () => {
      setBackgroundValues(emptyGoalBackground);
      setScopeValues({ ...emptyGoalScope, industry: industries[0] });
      setCompanyValues(profile);
      setUserValues(user);
      setActiveIndex(0);
      setSubmited(false);
      setLoading(false);
      setProblemSolutions([]);
      setCategories1([]);
      setCategories2([]);
      setCategories3([]);
      setIndustries([]);
      onHide();
    };

    const createGoal = async () => {
      if (
        scopeValues.industry &&
        scopeValues.category1 &&
        scopeValues.category2 &&
        scopeValues.problemStatement
      ) {
        setLoading(true);

        const body: CreateGoalDto = {
          background: {
            businessChallenge: scopeValues.problemStatement.name,
            minBudget: backgroundValues.minBudget,
            maxBudget: backgroundValues.maxBudget,
            document: backgroundValues.document,
            challengeSummarize: scopeValues.problemDescription.trim(),
            questions: [
              backgroundValues?.question1 || '',
              backgroundValues?.question2 || '',
              backgroundValues?.question3 || '',
            ],
            wishlist: [
              backgroundValues?.outcome1 || '',
              backgroundValues?.outcome2 || '',
              backgroundValues?.outcome3 || '',
            ],
          },
          scope: {
            // TODO: Fix this with dynamic value
            industry_id: '9e132bf2-8cbf-4dcb-ba62-5849c50165f6',
            problem_statement_id: scopeValues.problemStatement?.id,
            keywords: [
              scopeValues.category1?.id,
              scopeValues.category2?.id,
              scopeValues.category3?.id || '',
            ],
          },
        };

        try {
          // Create goal
          const createdGoal: UserGoalSummary = await dispatch<any>(
            createUserGoal(body)
          );

          showSuccessToast('Goal Created', scopeValues.problemStatement.name);

          if (firstAuth) {
            try {
              // Update user
              await updateUser(userValues);
              showSuccessToast(undefined, 'User Data Updated');
            } catch (error) {
              showErrorToast("User don't updated", error);
            }

            try {
              // Update profile
              await updateProfile(companyValues);
              showSuccessToast(undefined, 'Company Data Updated');
            } catch (error) {
              showErrorToast("Company don't updated", error);
            }
            clear();
          } else {
            clear();
          }

          if (afterCreate) afterCreate(createdGoal);
        } catch (error) {
          showErrorToast("Goal don't created", 'Server error');
          throw error;
        } finally {
          setLoading(false);
        }
      }
    };

    const updateGoal = async () => {
      if (scopeValues.problemStatement) {
        setLoading(true);

        const body: UpdateGoalDto = {
          userGoalId: goal?.id,
          background: {
            businessChallenge: scopeValues.problemStatement.name,
            minBudget: backgroundValues.minBudget,
            maxBudget: backgroundValues.maxBudget,
            document: backgroundValues.document,
            challengeSummarize: scopeValues.problemDescription,
            questions: [
              backgroundValues?.question1 || '',
              backgroundValues?.question2 || '',
              backgroundValues?.question3 || '',
            ],
            wishlist: [
              backgroundValues?.outcome1 || '',
              backgroundValues?.outcome2 || '',
              backgroundValues?.outcome3 || '',
            ],
          },
        };

        try {
          await dispatch<any>(updateUserGoal(body));
          showSuccessToast('Goal Updated', scopeValues.problemStatement.name);
          clear();
        } catch (error) {
          showErrorToast("Goal don't updated", 'Server error');
          throw error;
        } finally {
          setLoading(false);
        }
      }
    };

    /**
     * ON events functions
     */

    const onListDialogHide = (): void => {
      setOpenListDialog(false);
    };

    const onOpenListDialog = (_isQuestion: boolean): void => {
      if (_isQuestion) setIsQuestion(true);
      else setIsQuestion(false);
      setOpenListDialog(true);
    };

    const onPrevious = () => {
      setActiveIndex(activeIndex - 1);
    };

    const onNext = async () => {
      setSubmited(true);

      if (
        !scopeValues.industry ||
        !scopeValues.category1 ||
        !scopeValues.category2 ||
        !scopeValues.problemStatement ||
        !scopeValues.problemDescription.trim()
      ) {
        showErrorToast('Validation error', 'Some empty fields');
        return;
      }

      if (activeIndex === MAX_LENGTH) {
        if (editMode) {
          updateGoal();
        } else {
          createGoal();
        }
      } else setActiveIndex(activeIndex + 1);
    };

    const renderComponent = () => {
      switch (activeIndex) {
        case 0:
          return (
            <GoalScopeForm
              submited={submited}
              editMode={editMode}
              values={scopeValues}
              industries={industries}
              categories1={categories1}
              categories2={categories2}
              categories3={categories3}
              problemSolutions={problemSolutions}
              handleInputChange={scopeHandler}
            />
          );
        case 1:
          return (
            <GoalBackgroundForm
              values={backgroundValues}
              openListDialog={onOpenListDialog}
              handleInputChange={backgroundHandler}
            />
          );
        case 2:
          return (
            <ProfileUserForm
              editMode
              isOnBoard
              values={userValues}
              handleInputChange={userHandler}
            />
          );
        case 3:
          return (
            <ProfileCompanyForm
              editMode
              isOnBoard
              values={companyValues}
              handleInputChange={companyHandler}
            />
          );
        default:
          break;
      }
    };

    return (
      <>
        <Dialog
          modal
          header={false}
          draggable={false}
          resizable={false}
          className="goals_modal"
          contentStyle={{ height: '90%' }}
          style={{ width: '90%', height: '90%', maxWidth: '1500px' }}
          visible={open}
          onHide={() => setOpenCloseDialog(true)}
        >
          <ThreeNineLayout
            threeComponent={
              <VerticalSteps
                activeIndex={activeIndex}
                model={stepsItems}
                readOnly={!editMode}
              />
            }
            nineComponent={
              <ContentContainer className="card">
                {renderComponent()}
                <TwoButtons
                  className="goals_btm-btns"
                  leftDisabled={activeIndex === MIN_LENGTH}
                  leftLabel="Previous"
                  rightLabel={
                    activeIndex === MAX_LENGTH
                      ? editMode
                        ? 'Update'
                        : 'Create'
                      : 'Continue'
                  }
                  rightDisabled={loading}
                  onLeftClick={onPrevious}
                  onRightClick={onNext}
                />
              </ContentContainer>
            }
          />

          <GoalListDialog
            values={backgroundValues}
            open={openListDialog}
            isQuestion={isQuestion}
            onHide={onListDialogHide}
            handleInputChange={backgroundHandler}
          />

          <ConfirmDialog
            open={openCloseDialog}
            title="Are you sure to close?"
            message="if you close goal creation dialog you lose all entered data."
            acceptLabel="Yes, close"
            onHide={() => setOpenCloseDialog(false)}
            onAccept={clear}
          />
        </Dialog>
      </>
    );
  }
);
