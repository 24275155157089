import axios from 'axios';

import { GoogleIcon, LinkedinIcon } from 'assets';
import { SocialAuthDto } from 'types';
import { OptionsContainer, OptionButton } from './auth.style';
import UniversalSocialauth, {
  Providers,
  Google,
  Linkedin,
} from 'universal-social-auth';
import { ProderT } from 'universal-social-auth/dist/providers';

/**
 * @property {boolean} login -> It define if actions and info be to login or register
 */
type AuthOptionsProps = {
  login?: boolean;
  onSocialAuthSuccess: (
    data: SocialAuthDto,
    provider: 'google' | 'linkedin'
  ) => void;
  onGoogleFailure?: () => void;
};

export const AuthOptions = ({
  login = true,
  onSocialAuthSuccess,
}: AuthOptionsProps) => {
  const buttonLabel = login ? 'Continue' : 'Sign up';

  const options = {
    providers: {
      google: {
        clientId: `${process.env.REACT_APP_PROVIDER_GOOGLE_CLIENTID}`,
        redirectUri: `${process.env.REACT_APP_PROVIDER_GOOGLE_REDIRECTURI}`,
        optionalUrlParams: ['display'],
        display: 'popup',
      },
      linkedin: {
        clientId: `${process.env.REACT_APP_LINKEDIN_CLIENTID}`,
        redirectUri: `${process.env.REACT_APP_LINKEDIN_REDIRECTURI}`,
      },
    },
  };

  const Oauth: UniversalSocialauth = new UniversalSocialauth(axios, options);

  function authProvider(
    provider: 'google' | 'linkedin',
    proData: Record<string, unknown> | null
  ) {
    const pro = proData;

    const ProData = pro || Providers[provider];

    Oauth.authenticate(provider, ProData as ProderT)
      .then((response) => {
        const data = response as SocialAuthDto;
        if (data.code) {
          onSocialAuthSuccess(data, provider);
        }
      })
      .catch((err: unknown) => {
        console.log(err);
      });
  }

  return (
    <>
      <OptionsContainer className="p-fluid">
        <div className="field">
          <OptionButton
            icon={<GoogleIcon />}
            label={buttonLabel + ' with Google'}
            className="p-button-outlined btn-icon justify-content-center"
            onClick={() => authProvider('google', Google)}
          />
        </div>

        <div className="field">
          <OptionButton
            disabled={true}
            icon={<LinkedinIcon />}
            label={buttonLabel + ' with Linkedin'}
            className="p-button-outlined btn-icon justify-content-center"
            onClick={() => authProvider('linkedin', Linkedin)}
          />
        </div>
      </OptionsContainer>
    </>
  );
};
