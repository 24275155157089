import GoalListItemFooter from './goal-list-item-footer';
import { Card } from 'primereact/card';
import { UserGoalSummary } from 'types';
import { GoalItemContainer, GoalTitleStyle } from './goal-list-item.style';

import MenuButton from 'components/ui/MenuButton';
import { classNames } from 'primereact/utils';

type GoalListItemProps = {
  goalSummaryItem: UserGoalSummary;
  onEditGoal: (goal: UserGoalSummary) => void;
  onDuplicateGoal: (goal: UserGoalSummary) => void;
  onSelectGoal: (id: string) => void;
  onDeleteGoal: (goal: UserGoalSummary) => void;
  highlight?: boolean;
};

const GoalListItem = ({
  goalSummaryItem,
  onEditGoal,
  onDuplicateGoal,
  onDeleteGoal,
  onSelectGoal,
  highlight,
}: GoalListItemProps) => {
  return (
    <GoalItemContainer>
      <Card className={classNames(highlight && 'goal-highlight')}>
        <div className="grid relative">
          <div
            className="col-12 cursor-pointer p-0"
            onClick={() => {
              onSelectGoal(goalSummaryItem.id);
            }}
          >
            <div className="block p-3">
              <div className="flex align-items-center font-italic">
                <i className="pi pi-fw pi-user mr-2 hidden" />
                <span className="">{goalSummaryItem.industry.name}</span>
              </div>
            </div>

            <GoalTitleStyle>
              <div className="col-12 text-xl text-dark pt-0 mb-2">
                {goalSummaryItem.attributes.businessChallenge}
              </div>
            </GoalTitleStyle>

            <GoalListItemFooter
              stepsReady={goalSummaryItem.steps_count}
              expertsAvailable={goalSummaryItem.experts_count}
            />

            <MenuButton
              onEditGoal={onEditGoal}
              onDuplicateGoal={onDuplicateGoal}
              onDeleteGoal={onDeleteGoal}
              goal={goalSummaryItem}
            />
          </div>
        </div>
      </Card>
    </GoalItemContainer>
  );
};

export default GoalListItem;
