import React, { useRef } from 'react';
import { Toast, ToastSeverityType } from 'primereact/toast';

import { ToastContext } from '.';
import { TOAST } from '@constants';

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const toast = useRef<null | Toast>(null);

  /**
   * Generic Toast
   * @param severity -> Toast Severity Type
   * @param summary -> Toast Summanry/Title
   * @param detail -> Toast Detail/Description
   */
  const showToast = (
    severity: ToastSeverityType,
    summary: React.ReactNode,
    detail: React.ReactNode
  ): void => {
    toast?.current?.show({
      severity: severity,
      summary: summary,
      detail: detail,
      life: TOAST.life.normal,
    });
  };

  const showSuccessToast = (summary?: string, detail?: string) => {
    toast?.current?.show({
      severity: TOAST.severity.success,
      summary: summary || TOAST.summary.success,
      detail: detail || TOAST.detail.success,
      life: TOAST.life.normal,
    });
  };

  const showErrorToast = (summary?: string, detail?: string | unknown) => {
    let _detail = TOAST.detail.error;
    let _summary = summary || TOAST.summary.error;

    if (detail)
      _detail = detail instanceof Error ? detail.message : String(detail);

    toast?.current?.show({
      severity: TOAST.severity.error,
      summary: _summary,
      detail: _detail,
      life: TOAST.life.normal,
    });
  };

  return (
    <ToastContext.Provider
      value={{ showToast, showErrorToast, showSuccessToast }}
    >
      <Toast ref={toast} />
      {children}
    </ToastContext.Provider>
  );
};
