import { bindActionCreators } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goalsActions, RootState } from 'state';

export const useUserGoals = () => {
  const userGoals = useSelector((state: RootState) => state.userGoals);
  const dispatch = useDispatch();

  const { fetchUserGoals, createUserGoal, deleteUserGoal } = bindActionCreators(
    goalsActions,
    dispatch
  );

  const businessChallenges: string[] = useMemo((): string[] => {
    const _challenges: string[] = [];

    userGoals.forEach((goal) => {
      if (goal.attributes.businessChallenge) {
        _challenges.push(goal.attributes.businessChallenge);
      }
    });

    return _challenges;
  }, [userGoals]);

  return {
    userGoals: [...userGoals],
    fetchUserGoals,
    createUserGoal,
    deleteUserGoal,
    businessChallenges,
  };
};
