import { MAX_BUDGET } from '@constants';
import { ExpertType, StepType } from 'types';
import { CategoryDto, IndustryDto, ProblemSolutionDto } from '.';

export type GoalEngagementType = {
  selectedStep: StepType;
  selectedExpert: ExpertType;
};

export type GoalScope = {
  industry?: IndustryDto;
  category1?: CategoryDto;
  category2?: CategoryDto;
  category3?: CategoryDto;
  problemStatement?: ProblemSolutionDto;
  problemDescription: string;
};

export type GoalBackground = {
  minBudget: number;
  maxBudget: number;
  question1?: string;
  question2?: string;
  question3?: string;
  outcome1?: string;
  outcome2?: string;
  outcome3?: string;
  document?: File;
};

/**
 * Emptys types
 */

export const emptyGoalScope: GoalScope = {
  industry: undefined,
  category1: undefined,
  category2: undefined,
  category3: undefined,
  problemStatement: undefined,
  problemDescription: '',
};

export const emptyGoalBackground: GoalBackground = {
  minBudget: 0,
  maxBudget: MAX_BUDGET,
  question1: '',
  question2: '',
  question3: '',
  outcome1: '',
  outcome2: '',
  outcome3: '',
};
