import AppMenu from './AppMenu';
import { useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'hooks/use-auth';
import { Button } from 'primereact/button';

import { LOGIN_SLUG, PROFILE_SLUG } from '@constants';
import { useUser } from 'hooks';
import { sameSideLogo, userSvg } from 'assets';
import { handleImageError } from 'utilities';

const AppTopbar = (props) => {
  const history = useHistory();
  const searchPanelDesktop = useRef(null);
  const searchPanelMobile = useRef(null);

  const { user } = useUser();
  const { logout } = useAuth();

  useEffect(() => {
    if (props.searchActive) {
      if (window.innerWidth >= 576) searchPanelDesktop.current.focus();
      else searchPanelMobile.current.focus();
    }
  }, [props.searchActive]);

  const handleClickProfile = () => {
    history.push(PROFILE_SLUG);
  };

  const handleOnLogout = () => {
    logout();
    history.push(LOGIN_SLUG);
  };

  return (
    <div className="layout-topbar">
      <div
        className="layout-topbar-wrapper"
        style={{ maxWidth: '1400px', margin: 'auto', padding: '0px 25px' }}
      >
        <div className="layout-topbar-left">
          <button
            tabIndex="0"
            id="logo-link"
            onClick={() => history.push('/')}
            className="layout-topbar-logo p-link"
          >
            {/* TODO: Check why this image doen't load on solution builder page */}
            <img src={sameSideLogo} alt="SameSide" title="SameSide" />
            {/* <img
              src="https://app.sasistage.io/static/media/sameside-logo.7bc917f7.svg"
              alt="SameSide"
              title="SameSide"
            /> */}
          </button>
        </div>
        <AppMenu
          menuMode={props.menuMode}
          sidebarActive={props.sidebarActive}
          sidebarStatic={props.sidebarStatic}
          model={props.menu}
          menuActive={props.menuActive}
          onRootMenuItemClick={props.onRootMenuItemClick}
          onMobileMenuActive={props.onMobileMenuActive}
          onMenuItemClick={props.onMenuItemClick}
          onSidebarMouseOver={props.onSidebarMouseOver}
          onSidebarMouseLeave={props.onSidebarMouseLeave}
          onToggleMenu={props.onToggleMenu}
          resetActiveIndex={props.resetActiveIndex}
          onMenuClick={props.onMenuClick}
        />
        <div className="layout-topbar-right">
          <ul className="layout-topbar-actions">
            <Button
              label="My Goals"
              className="p-button-text min-w-max"
              onClick={() => history.push('/')}
            />

            <li
              className={classNames('topbar-item user-profile', {
                'active-topmenuitem fadeInDown': props.topbarUserMenuActive,
              })}
            >
              <button
                className="p-link avatar_btn"
                style={{ cursor: 'pointer' }}
                onClick={props.onTopbarUserMenuClick}
              >
                <img
                  src={user.profilePath ? user.profilePath : userSvg}
                  alt="user profile"
                  onError={handleImageError}
                />
              </button>

              <ul className="fadeInDown">
                <li className="signout-link" onClick={handleClickProfile}>
                  <button className="p-link">
                    <span>
                      <i className="pi pi-user"></i>
                      Profile
                    </span>
                  </button>
                </li>
                <li
                  className="signout-link p-link"
                  onClick={() => handleOnLogout()}
                >
                  <button className="p-link">
                    <span>
                      <i className="pi pi-sign-out"></i> Sign Out
                    </span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppTopbar;
