import { GridContainer } from './layouts.style';

type ThreeNineLayoutProps = {
  threeComponent: React.ReactNode;
  nineComponent: React.ReactNode;
  line?: boolean;
};

export const ThreeNineLayout = ({
  nineComponent,
  threeComponent,
  line,
}: ThreeNineLayoutProps) => {
  return (
    <>
      <GridContainer className="grid">
        <div className="col-12 md:col-3">{threeComponent}</div>
        <div className="col-12 md:col-9">{nineComponent}</div>
      </GridContainer>
    </>
  );
};
