import { BreakPointsType } from 'types';

export const breakPoints: BreakPointsType = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

/*
Breakpoint        |  Class infix	 |   Dimensions
X-Small           |	      xs	     |     0px
Small	            |       sm  	   |     ≥600px
Medium	          |       md	     |     ≥960px
Large	            |       lg	     |     ≥1280px
Extra large	      |       xl	     |     ≥1920px
*/
