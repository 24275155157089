import { Button } from 'primereact/button';
import styled from 'styled-components';

type ItemProps = {
  isActive?: boolean;
};

export const GoalStepsItemContainer = styled.div<ItemProps>`
  background-color: #fff;

  ${(props) =>
    props.isActive === true &&
    `
    outline: 2px solid var(--green-500);
    background-color: var(--green-50);
    `}

  &:hover {
    transform: scale(1.03);
    transition: 0.3s ease;
  }
`;

export const MenuBusinessChallenge = styled.div`
  background-color: var(--gray-50);
`;

export const StepsSelectedContainer = styled.div`
  max-height: 60.7vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: inherit;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-image: linear-gradient(
      90deg,
      #aade5a 0,
      #96d960 10%,
      #80d365 20%,
      #69ca69 30%,
      #50c16d 40%,
      #34b56f 50%,
      #04a970 60%,
      #009f72 70%,
      #009673 80%,
      #008e75 90%,
      #008778 100%
    );
  }
`;

export const NoStepsSelectedInfo = styled.div`
  max-width: 700px;
`;

export const NoStepsSelectedButton = styled(Button)`
  max-width: 330px;
`;
