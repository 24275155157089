import { useMemo } from 'react';
import { Dialog } from 'primereact/dialog';

import { DoneButton } from 'components';
import { PrivacyPolicy, TermsService } from '.';

type LegalDialogProps = {
  open: boolean;
  onHide: () => void;
  type: 'terms' | 'privacy';
};

export const LegalDialog = ({ onHide, open, type }: LegalDialogProps) => {
  const dialogFooter = () => <DoneButton onDone={onHide} />;

  const switchLegal = useMemo(() => {
    switch (type) {
      case 'privacy':
        return <PrivacyPolicy />;
      case 'terms':
        return <TermsService />;
      default:
        break;
    }
  }, [type]);

  return (
    <Dialog
      className="max-w-dialog dialog-header-h-0 "
      visible={open}
      onHide={onHide}
      footer={dialogFooter()}
    >
      {switchLegal}
    </Dialog>
  );
};
