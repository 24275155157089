import { object } from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { SplitLayout } from 'layouts';
import { SocialAuthDto, UserType } from 'types';
import { useToast, useAuth } from 'hooks';
import { LOGIN_SLUG, PROFESSIONAL_ATTRIBUTE_NAME } from '@constants';
import { createUser } from 'state';
import { googleAuth } from 'state';
import {
  AuthOptions,
  SignupForm,
  AuthTitle,
  AuthMenu,
  LegalDialog,
} from 'components';
import {
  yupConfirmPassword,
  yupEmail,
  yupFirstName,
  yupLastName,
  yupPassword,
  yupPhone,
} from 'utilities';

export const SignupPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { login } = useAuth();
  const { showErrorToast } = useToast();
  const [terms, setTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openLegal, setOpenLegal] = useState(false);
  const [legalType, setLegalType] = useState<'terms' | 'privacy'>('terms');

  const schema = object({
    firstName: yupFirstName,
    lastName: yupLastName,
    password: yupPassword,
    email: yupEmail,
    phone: yupPhone,
    confirmPassword: yupConfirmPassword,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserType>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
  });

  const openTermsServiceDialog = () => {
    setOpenLegal(true);
    setLegalType('terms');
  };

  const openPrivacyPolicyDialog = () => {
    setOpenLegal(true);
    setLegalType('privacy');
  };

  const onHideLegalDialog = () => {
    setOpenLegal(false);
  };

  const onRedirectSignIn = () => {
    history.push(LOGIN_SLUG);
  };

  const onExpertRedirect = () => {};

  const onSubmit = async (user: UserType) => {
    try {
      setLoading(true);
      const response = await dispatch<any>(createUser(user));
      if (response) {
        await login({
          email: user.email,
          password: user.password || '',
          role: PROFESSIONAL_ATTRIBUTE_NAME,
        });
        history.push('/');
      }
    } catch (error) {
      showErrorToast(undefined, error);
      setLoading(false);
    }
  };

  const onSocialAuthSuccess = async (
    data: SocialAuthDto,
    provider: 'google' | 'linkedin'
  ) => {
    try {
      if (provider === 'google')
        await dispatch<any>(googleAuth(data, 'google'));
      else if (provider === 'linkedin')
        await dispatch<any>(googleAuth(data, 'linkedin'));
    } catch (error) {
      showErrorToast('Auth error', error);
    }
  };

  return (
    <>
      <AuthMenu
        label="Sign up now to qualify for our beta free trial."
        buttonRedirectLabel="Expert sign up"
        onRedirect={onExpertRedirect}
      />

      <AuthTitle>Sign up below to create your first goal</AuthTitle>

      <SplitLayout
        leftComponent={
          <SignupForm
            loading={loading}
            errors={errors}
            control={control}
            handleSubmit={handleSubmit}
            terms={terms}
            onSubmit={onSubmit}
            onRedirectSignIn={onRedirectSignIn}
            onAcceptTerms={() => setTerms(!terms)}
            openPrivacyPolicyDialog={openPrivacyPolicyDialog}
            openTermsServiceDialog={openTermsServiceDialog}
          />
        }
        rightComponent={
          <AuthOptions
            onSocialAuthSuccess={onSocialAuthSuccess}
            login={false}
          />
        }
      />

      <LegalDialog
        type={legalType}
        open={openLegal}
        onHide={onHideLegalDialog}
      />
    </>
  );
};
