import { RootState, userActions } from 'state';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

export const useUser = () => {
  const state = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const { updateUser, clearUser } = bindActionCreators(userActions, dispatch);

  return {
    ...state,
    user: state,
    updateUser: updateUser,
    clearUser: clearUser,
  };
};
