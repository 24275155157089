import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';

type SearchFilterProps = {
  placeholder?: string;
  label?: string;
  onChange?: (value: string) => void;
};
export const SearchFilter = ({
  label,
  onChange,
  placeholder = 'Search',
}: SearchFilterProps) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className="p-fluid">
      <div className="field">
        <label>{label}</label>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={value}
            placeholder={placeholder}
            onChange={(e) => setValue(e.target.value)}
          />
        </span>
      </div>
    </div>
  );
};
