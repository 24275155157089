import { useState } from 'react';
import { useHistory } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { useForm } from 'react-hook-form';

import { LoginForm, AuthTitle, AuthOptions, AuthMenu } from '../components';
import { SplitLayout } from '../layouts';
import { SocialAuthDto, UserLogin } from 'types';
import { useToast, useAuth } from 'hooks';
import { googleAuth } from 'state';
import { useDispatch } from 'react-redux';
import {
  PASSWORD_RECOVERY,
  PROFESSIONAL_ATTRIBUTE_NAME,
  SIGN_IN_SLUG,
  SITE_INFO,
} from '@constants';
import { yupEmail, yupPassword, yupUserRole } from 'utilities';

export const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { login } = useAuth();
  const { showErrorToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState<boolean>(true);

  const loginSchema = object({
    password: yupPassword,
    email: yupEmail,
    role: yupUserRole,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserLogin>({
    defaultValues: loginSchema.cast({}),
    resolver: yupResolver(loginSchema),
  });

  const onRedirectSignUp = () => {
    history.push(SIGN_IN_SLUG);
  };

  const onChangeRememberMe = (value: boolean) => {
    setRememberMe(value);
  };

  const onPasswordRecovery = () => {
    history.push(PASSWORD_RECOVERY);
  };

  const onExpertRedirect = () => {
    window.location.replace(SITE_INFO.PROVIDER_SITE_URL);
  };

  const onSubmit = async (user: UserLogin) => {
    try {
      setLoading(true);
      await login({
        email: user.email.trim(),
        password: user.password,
        role: PROFESSIONAL_ATTRIBUTE_NAME,
      });
      history.push('/');
    } catch (error) {
      showErrorToast('Auth error', error);
    } finally {
      setLoading(false);
    }
  };

  const onSocialAuthSuccess = async (
    data: SocialAuthDto,
    provider: 'google' | 'linkedin'
  ) => {
    try {
      if (provider === 'google')
        await dispatch<any>(googleAuth(data, 'google'));
      else if (provider === 'linkedin')
        await dispatch<any>(googleAuth(data, 'linkedin'));
    } catch (error) {
      showErrorToast('Auth error', error);
    }
  };

  return (
    <>
      <AuthMenu
        buttonRedirectLabel="Expert Sign In"
        label="Sign in now to access your next solution."
        onRedirect={onExpertRedirect}
      />

      <AuthTitle>Sign in below</AuthTitle>

      <SplitLayout
        leftComponent={
          <LoginForm
            loading={loading}
            handleSubmit={handleSubmit}
            errors={errors}
            control={control}
            rememberMe={rememberMe}
            onSubmit={onSubmit}
            onRedirectSignUp={onRedirectSignUp}
            onChangeRememberMe={onChangeRememberMe}
            onPasswordRecovery={onPasswordRecovery}
          />
        }
        rightComponent={
          <AuthOptions onSocialAuthSuccess={onSocialAuthSuccess} />
        }
      />
    </>
  );
};
