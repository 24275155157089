import React from 'react';
import { Text } from './selecct-button-item.style';

type SeleccButtonOption = {
  category_name?: string;
  name?: string;
  image?: string;
  imageUrl?: string;
  [key: string]: any;
};

type SelecctButtonItemProps = {
  option: SeleccButtonOption;
};

export const SelecctButtonItem = ({ option }: SelecctButtonItemProps) => {
  return (
    <div className="m-auto flex justify-content-center align-items-center forms_selectbtn">
      {option.image && <img src={option.image} alt="option-icon" />}
      {option.imageUrl && <img src={option.imageUrl} alt="option-icon" />}
      {option.name ? (
        <Text className="ml-2">{option.name}</Text>
      ) : (
        option.category_name && (
          <Text className="ml-2">{option.category_name}</Text>
        )
      )}
    </div>
  );
};
