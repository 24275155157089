import styled from 'styled-components';

export const StepAvailableContainer = styled.div`
  &:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1),
      0px 4px 5px -2px rgba(0, 0, 0, 0.12),
      0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;

    .title {
      color: var(--blue-500);
    }
  }
`;
