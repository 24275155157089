import _ from 'lodash';
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from 'state/reducers';
import thunk from 'redux-thunk';
import { SITE_INFO } from '@constants';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('SaSiState');

    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const persistedState = loadState();

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  devTools: !SITE_INFO.isProductionServer,
  preloadedState: persistedState,
});

export const saveState = (state: RootState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('SaSiState', serializedState);
  } catch (err) {
    //Ignorar
  }
};

store.subscribe(
  _.throttle(() => {
    saveState(store.getState());
  }, 1)
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
