import axios, { AxiosRequestConfig as ARC, AxiosRequestHeaders } from 'axios';
import { ErrorType } from 'types';
import { IHttpClient } from 'types/Interfaces/IHttpClient';
import { IHttpClientRequestParams } from 'types/Interfaces/IHttpClientRequestParams';

axios.defaults.timeout = 30000;

interface AxiosRequestConfig extends ARC {
  headers: AxiosRequestHeaders;
}

export class HttpClient implements IHttpClient {
  get<T>(parameters: IHttpClientRequestParams<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const {
        url,
        requiresToken,
        customToken,
        responseType,
        cancelToken,
        authorizationPrefix,
      } = parameters;

      const options: AxiosRequestConfig = {
        headers: {},
        responseType,
        cancelToken,
      };

      if (requiresToken) {
        const token = customToken;
        options.headers.Authorization = `${authorizationPrefix} ${token}`;
      }

      axios
        .create()
        .get(url, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((error: any) => {
          reject(errorResponseHandler(error));
        });
    });
  }
  post<T, R>(parameters: IHttpClientRequestParams<T>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      const { url, payload, requiresToken, customToken, authorizationPrefix } =
        parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        const token = customToken;
        options.headers.Authorization = `${authorizationPrefix} ${token}`;
      }

      axios
        .create()
        .post(url, payload, options)
        .then((response: any) => {
          resolve(response.data as R);
        })
        .catch((error: any) => {
          reject(errorResponseHandler(error));
        });
    });
  }

  put<T, R>(parameters: IHttpClientRequestParams<T>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      const { url, payload, requiresToken, customToken, authorizationPrefix } =
        parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        const token = customToken;
        options.headers.Authorization = `${authorizationPrefix} ${token}`;
      }

      axios
        .create()
        .put(url, payload, options)
        .then((response: any) => {
          resolve(response.data as R);
        })
        .catch((error: any) => {
          reject(errorResponseHandler(error));
        });
    });
  }

  delete<T>(parameters: IHttpClientRequestParams<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken, customToken, authorizationPrefix } =
        parameters;

      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        const token = customToken;
        options.headers.Authorization = `${authorizationPrefix} ${token}`;
      }

      axios
        .create()
        .delete(url, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((error: any) => {
          reject(errorResponseHandler(error));
        });
    });
  }
}

const errorResponseHandler = (error: any) => {
  const { response } = error;
  if (response) {
    if (response.status >= 500) {
      return new Error(`Error: (${response.status})`);
    } else {
      const _error = response.data as ErrorType;
      return new Error(_error.title || _error.message);
    }
  } else {
    return new Error(error.message);
  }
};

export const httpClient = new HttpClient();
