import { Toolbar } from 'primereact/toolbar';
import styled from 'styled-components';


export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 0;
`;

export const CustomToolbar = styled(Toolbar)`
  border: 0px;
  background-color: inherit;
  padding: 0px;
`;
