import React from 'react';
import { StepsItem } from 'types';
import { ContainerVerticalSteps, StepsLabel } from './vertical-steps.style';

type VerticalStepsProps = {
  activeIndex?: number;
  readOnly?: boolean;
  model: StepsItem[];
  containerClassName?: string;
};

export const VerticalSteps = React.memo(
  ({
    model,
    readOnly = true,
    activeIndex,
    containerClassName,
  }: VerticalStepsProps) => {
    /**
     * @param item -> Menu object
     * @returns class to define menu status
     */
    const getStatusClass = (index?: number): string => {
      if (!readOnly) {
        return '--complete';
      }

      if (activeIndex !== undefined && index !== undefined) {
        if (index === activeIndex) return '--current';
        if (index < activeIndex) return '--complete';
      }

      return '';
    };

    const renderItems = (items: StepsItem[]): React.ReactNode => {
      return (
        <ul className="steps steps--vertical">
          {items.map((item: StepsItem, jIndex) => {
            const className = getStatusClass(item.index);

            if (Object.keys(item).length === 0) {
              return undefined;
            }

            return (
              <li
                key={jIndex}
                className={'steps__item steps__item' + className}
              >
                <StepsLabel
                  className={!readOnly ? 'cursor-pointer' : ''}
                  status={className}
                  onClick={(e) => {
                    if (item.command && !readOnly) {
                      item.command({
                        originalEvent: e,
                        item: item,
                      });
                    }
                  }}
                >
                  <span
                    className={item.index === undefined ? 'p-disabled' : ''}
                  >
                    {item.label}
                  </span>

                  {item.optional && (
                    <span className="goals_optional-label text-gray-400">
                      {' '}
                      (optional)
                    </span>
                  )}
                </StepsLabel>

                {item.items && item.items?.length > 0 && (
                  <div>{renderItems(item.items)}</div>
                )}
              </li>
            );
          })}
        </ul>
      );
    };

    return (
      <>
        <ContainerVerticalSteps className={containerClassName}>
          {renderItems(model)}
        </ContainerVerticalSteps>
      </>
    );
  }
);
