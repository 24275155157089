import { AuthActionEnum } from 'enums';
import { AuthActionType } from 'types/action-types';
import { AuthStateType } from 'types';

const INITIAL_STATE: AuthStateType = {
  token: '',
  tokenType: '',
  expiresIn: '',
  isAuth: false,
  firstAuth: false,
};

export const auth = (
  state: AuthStateType = INITIAL_STATE,
  action: AuthActionType
): AuthStateType => {
  switch (action.type) {
    case AuthActionEnum.LOGIN:
      return { ...state, ...action.payload };
    case AuthActionEnum.UPDATE_FIRST_AUTH:
      const _state = { ...state };
      _state.firstAuth = false;
      return { ..._state };
    case AuthActionEnum.LOGOUT:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};
