import { useHistory, useParams } from 'react-router';
import { useState } from 'react';

import { useToast } from 'hooks';
import { resetPassword, sendEmail } from 'state';
import { useDispatch } from 'react-redux';
import { SendEmailDto } from 'types';
import { LOGIN_SLUG, PROFESSIONAL_ATTRIBUTE_NAME } from '@constants';
import { FORGOT_PASSWORD_EMAIL } from '@constants/email';
import {
  AuthMenu,
  AuthTitle,
  PasswordCreateForm,
  PasswordRecoveryForm,
} from 'components';

export const PasswordRecovery = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { code = undefined } = useParams<{ code: string | undefined }>();
  const { showErrorToast, showSuccessToast } = useToast();

  const [email, setEmail] = useState<string>('');
  const [createPassword, setCreatePassword] = useState<{
    password: string;
    confirmPassword: string;
  }>({
    password: '',
    confirmPassword: '',
  });

  const onSignIn = () => {
    history.push(LOGIN_SLUG);
  };

  const onEmailChange = (email: string) => {
    setEmail(email);
  };

  const onPasswordsChange = (value: string, type: 'p' | 'cp') => {
    if (type === 'p') {
      setCreatePassword({
        ...createPassword,
        password: value,
      });
    }
    if (type === 'cp') {
      setCreatePassword({
        ...createPassword,
        confirmPassword: value,
      });
    }
  };

  const onExpertRedirect = () => {};

  const onSubmit = async () => {
    try {
      if (email.trim()) {
        const data: SendEmailDto = {
          email: email,
          role: PROFESSIONAL_ATTRIBUTE_NAME,
          type: FORGOT_PASSWORD_EMAIL,
        };
        await dispatch<any>(sendEmail(data));
        showSuccessToast('Success to send recovery info to email', email);
        history.push(LOGIN_SLUG);
      }
    } catch (error) {
      showErrorToast(undefined, error);
    }
  };

  const onCreatePassword = async () => {
    if (!code) {
      return;
    }

    if (
      !createPassword.password.trim() ||
      !createPassword.confirmPassword.trim()
    ) {
      showErrorToast('Validation error', 'Empty field(s)');
      return;
    }

    if (
      createPassword.password.trim() !== createPassword.confirmPassword.trim()
    ) {
      showErrorToast('Validation error', "Passwords aren't equals");
      return;
    }

    try {
      await dispatch<any>(
        resetPassword({ code: code, password: createPassword.password })
      );
      showSuccessToast(undefined, 'Updated password');
      history.push(LOGIN_SLUG);
    } catch (error) {
      showErrorToast(undefined, error);
    }
  };

  return (
    <>
      <AuthMenu
        label="Sign in now to access your next solution."
        buttonRedirectLabel="Expert Sign In"
        onRedirect={onExpertRedirect}
      />

      <div className="text-center">
        <AuthTitle>
          {!code ? 'Password recovery' : 'Create new password'}
        </AuthTitle>

        {!code && (
          <div className="mb-5">
            <p>Forgot your password?</p>
            <p>No worries, we’ll send you reset instructions.</p>
          </div>
        )}

        {!code ? (
          <PasswordRecoveryForm
            onSignIn={onSignIn}
            onEmailChange={onEmailChange}
            onSubmit={onSubmit}
          />
        ) : (
          <PasswordCreateForm
            values={createPassword}
            onSubmit={onCreatePassword}
            onSignIn={onSignIn}
            onPasswordsChange={onPasswordsChange}
          />
        )}
      </div>
    </>
  );
};
