import { Avatar } from 'primereact/avatar';
import { TabView } from 'primereact/tabview';
import styled from 'styled-components';

type ChildrenProp = {
  children: React.ReactNode;
};

type ExpertAvatarProps = {
  isSelected: boolean;
};

export const ChatHeader = styled.div`
  margin-top: -2rem;
`;

export const ChatTabView = styled(TabView)<ChildrenProp>`
  .p-tabview-panels {
    height: 100%;
  }
`;

export const ExpertAvatar = styled(Avatar)<ExpertAvatarProps>`
  ${(props) =>
    props.isSelected &&
    `
    padding: 3px;
    outline: 3px solid var(--primary-color);
  `}
`;

export const SendIcon = styled.i`
  transform: rotate(45deg);
  bottom: 50% !important;
  top: 45% !important;
`;
