import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FormContainer } from './login.style';

type PasswordRecoveryFormProps = {
  onSignIn: () => void;
  onSubmit: () => void;
  onEmailChange: (email: string) => void;
};

export const PasswordRecoveryForm = ({
  onEmailChange,
  onSignIn,
  onSubmit,
}: PasswordRecoveryFormProps) => {
  return (
    <>
      <FormContainer>
        <form
          className="p-fluid"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <div className="field">
            <InputText
              required
              name="email"
              type="email"
              placeholder="Enter your email"
              onChange={(e) => onEmailChange(e.target.value)}
            />
          </div>

          <div className="field">
            <Button label="Email me link" type="submit" />
          </div>
        </form>

        <div className="text-center">
          <Button
            className="p-button-text"
            label="Back to sign in"
            icon="pi pi-arrow-left"
            onClick={onSignIn}
          />
        </div>
      </FormContainer>
    </>
  );
};
