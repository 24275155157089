import { mixed, ref, string } from 'yup';

import {
  EXPERT_ATTRIBUTE_NAME,
  PROFESSIONAL_ATTRIBUTE_NAME,
  REGEXS,
} from '@constants';

/**
 * default is important to generate casted schema
 */

export const yupEmail = string()
  .trim()
  .email()
  .required('Email required')
  .default('');

export const yupPassword = string()
  .trim()
  .required('Password is required')
  .default('');

export const yupUserRole = mixed()
  .oneOf([PROFESSIONAL_ATTRIBUTE_NAME, EXPERT_ATTRIBUTE_NAME])
  .required()
  .default(PROFESSIONAL_ATTRIBUTE_NAME);

export const yupPhone = string()
  .trim()
  .required('Phone required')
  .matches(REGEXS.phone, 'Invalid phone format')
  .default('');

export const yupFirstName = string()
  .trim()
  .required('First name required')
  .default('');

export const yupLastName = string()
  .trim()
  .required('Last name required')
  .default('');

export const yupConfirmPassword = string()
  .required('Re-enter the password')
  .oneOf([ref('password')], 'Must be equals to password')
  .default('');
