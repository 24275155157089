import { StepActionEnum } from 'enums';
import { StepType } from 'types';
import { StepActionType } from 'types/action-types';

const INITIAL_STATE: StepType = {
  id: '',
  n_times: 0,
  title: '',
  isChosen: false,
  experts: [],
};

export const step = (
  state: StepType = INITIAL_STATE,
  action: StepActionType
): StepType => {
  switch (action.type) {
    case StepActionEnum.SET_STEP:
      return { ...state, ...action.payload };
    case StepActionEnum.CLEAR_STEP:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};
