import { Dispatch } from 'redux';
import { ExpertActionEnum } from 'enums';
import { ExpertType } from 'types';

export const setExpert = (step: ExpertType) => (dispatch: Dispatch) => {
  dispatch({ type: ExpertActionEnum.SET_EXPERT, payload: step });
};

export const clearExpert = () => (dispatch: Dispatch) => {
  dispatch({ type: ExpertActionEnum.CLEAR_EXPERT });
};
