import { NoStepsSelectedInfoItem } from './no-steps-selected-info-item';
import { NoStepsSelectedInfo } from './goal-steps.style';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';

type NoStepsSelectedProps = {
  addSteps: () => void;
};

export const NoStepsSelected = ({ addSteps }: NoStepsSelectedProps) => {
  return (
    <div className="flex flex-column justify-content-center align-items-center h-full">
      <div className="flex align-items-center mb-2">
        <Skeleton
          shape="rectangle"
          size="2rem"
          className="mr-2"
          borderRadius="2px"
        />
        <div>
          <Skeleton
            height=".7rem"
            width="4rem"
            className="mb-1"
            borderRadius="2px"
          />
          <Skeleton height=".7rem" width="6rem" borderRadius="2px" />
        </div>
      </div>

      <div className="flex align-items-center mb-8">
        <Skeleton
          shape="rectangle"
          size="3rem"
          className="bg-green-skeleton mr-2"
          borderRadius="2px"
        />
        <div>
          <Skeleton
            height=".8rem"
            width="6rem"
            className="bg-green-skeleton mb-1"
            borderRadius="2px"
          />
          <Skeleton
            height=".8rem"
            width="8rem"
            className="bg-green-skeleton"
            borderRadius="2px"
          />
        </div>
      </div>
      <div className="text-center text-black-alpha-90 font-semibold text-2xl md:text-4xl mb-3">
        Create your Solution Stack
      </div>

      <NoStepsSelectedInfo className="grid mt-2 mb-5">
        <NoStepsSelectedInfoItem
          icon="pi pi-search"
          title="Discover steps"
          description="Add steps designed by solution experts, used by likeminded people"
        />
        <NoStepsSelectedInfoItem
          icon="pi pi-comments"
          title="Vet experts"
          description="Review the expters behind each step to determine your partners"
        />
        <NoStepsSelectedInfoItem
          icon="pi pi-stack"
          title="Manage Stack"
          description="Dynamically manage your stack to meet your solution needs"
        />
      </NoStepsSelectedInfo>

      <Button label="Begin now" onClick={addSteps} />

      {/* <NoStepsSelectedButton
        label="Begin now"
        className="w-full"
        onClick={addSteps}
      /> */}
    </div>
  );
};
