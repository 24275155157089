import ComunityTrendItem from './comunity-trend-item';
import { TrendingGoalType } from 'types';
import { ComunityTrendsContainer } from './comunity-trends.style';

type ComunityTrendsProps = {
  trendingGoals: TrendingGoalType[];
};

const ComunityTrends = ({ trendingGoals }: ComunityTrendsProps) => {
  return (
    <ComunityTrendsContainer>
      <div className="p-5">
        <div className="flex align-items-center mb-6">
          <i className="pi pi-circle-fill text-primary text-2xs mr-3"></i>
          <span className="text-xl text-dark">Trending Goals</span>
        </div>

        <div className="overflow-y-auto">
          {trendingGoals.map((item: TrendingGoalType, index: number) => (
            <ComunityTrendItem
              key={index}
              title={item.goalName}
              uses={item.uses}
              activeBuyers={item.activeBuyers}
            />
          ))}
        </div>
      </div>
    </ComunityTrendsContainer>
  );
};

export default ComunityTrends;
