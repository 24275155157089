export type ExpertType = {
  id: string;
  isChosen: boolean;
  expert_id: string;
  category_id: string;
  expertise_id: string;
  description: string;
  image: string;
  name: string;
  designation: string;
  company: string;
  experience: number;
  audio: string;
  questions: string;
  solution_expert_id?: string;
};

/**
 * Empty type
 */

export const emptyExpert: ExpertType = {
  id: '',
  isChosen: false,
  expert_id: '',
  category_id: '',
  expertise_id: '',
  description: '',
  image: '',
  name: '',
  designation: '',
  company: '',
  experience: 0,
  audio: '',
  questions: '',
};
