import { ChatActionEnum } from 'enums';
import { ChatsType, ChatActionType } from 'types';

export const chatReducer = (
  state: ChatsType,
  action: ChatActionType
): ChatsType => {
  switch (action.type) {
    case ChatActionEnum.ADD:
      return { chats: [...state.chats, action.payload] };

    case ChatActionEnum.REMOVE:
      return {
        chats: [
          ...state.chats.filter(
            (chat) =>
              chat.expert_id !== action.payload.expert_id &&
              chat.goal_id !== action.payload.goal_id
          ),
        ],
      };

    case ChatActionEnum.ADD_MESSAGE:
      const _state = { ...state };

      const index = _state.chats.findIndex(
        (chat) =>
          chat.expert_id === action.payload.expert_id &&
          chat.goal_id === action.payload.goal_id
      );

      if (index !== -1) {
        _state.chats[index].messages = [
          ..._state.chats[index].messages,
          action.payload.message,
        ];
        return _state;
      } else {
        return _state;
      }

    default:
      return state;
  }
};
