import PrimeReact from 'primereact/api';
import { classNames } from 'primereact/utils';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { useState, useEffect, useRef } from 'react';

import AppTopbar from './AppTopbar';
import AppConfig from './AppConfig';
import AppRightPanel from './AppRightPanel';
import GoalsDashboardPage from 'pages/goals-dashboard';
import GoalPage from 'pages/goal';
import { Profile } from 'pages';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import InputDemo from 'components/InputDemo';
import { useApplication } from 'hooks/use-application';

const App = (props) => {
  const [resetActiveIndex, setResetActiveIndex] = useState(null);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [sidebarStatic, setSidebarStatic] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState('horizontal');
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle, setInputStyle] = useState('filled');
  const [ripple, setRipple] = useState(false);
  const [rightPanelActive, setRightPanelActive] = useState(false);
  const [colorScheme, setColorScheme] = useState('light');
  const [topbarScheme, setTopbarScheme] = useState('light');
  const [menuScheme, setMenuScheme] = useState('light');
  const [themeScheme, setThemeScheme] = useState('light');
  const [theme, setTheme] = useState('purple');
  const [searchActive, setSearchActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();

  const { isPending } = useApplication();

  const menu = [];

  let menuClick;
  let rightPanelClick;
  let configClick;
  let searchClick;
  let topbarUserMenuClick;

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
  }, [menuMode]);

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setResetActiveIndex(true);
      hideOverlayMenu();
    }
    if (!event.item.items && (isSlim() || isHorizontal())) {
      setMenuActive(false);
    }
  };

  const onMenuClick = (event) => {
    if (menuActive && event.target.className === 'layout-menu-container') {
      setResetActiveIndex(true);
      setMenuActive(false);
    }
    menuClick = true;
  };

  const onMenuModeChange = (menuMode) => {
    setMenuMode(menuMode);
    if (menuMode === 'sidebar') {
      if (sidebarStatic) {
        setSidebarActive(true);
      }
    } else {
      setSidebarActive(false);
      if (topbarScheme !== menuScheme) {
        setMenuScheme(topbarScheme);
      }
    }
    if (topbarScheme === 'dark') {
      setThemeScheme('dark');
    }
  };

  const onColorSchemeChange = (scheme) => {
    setColorScheme(scheme);
    props.setColorScheme(scheme);
  };

  const onThemeSchemeChange = (scheme) => {
    setThemeScheme(scheme);
    setMenuScheme(scheme);
    setTopbarScheme(scheme);
  };

  const onTopbarSchemeChange = (scheme) => {
    setTopbarScheme(scheme);
  };

  const onMenuSchemeChange = (scheme) => {
    setMenuScheme(scheme);
  };

  const onThemeChange = (themeColor) => {
    setTheme(themeColor);
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
    }
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setRightPanelActive(false);

    if (isMobile()) {
      setStaticMenuMobileActive(
        (prevStaticMenuMobileActive) => !prevStaticMenuMobileActive
      );
      if (staticMenuMobileActive) {
        blockBodyScroll();
      } else {
        unblockBodyScroll();
      }
    }
    event.preventDefault();
  };

  const isMobile = () => {
    return window.innerWidth <= 991;
  };

  const isHorizontal = () => {
    return menuMode === 'horizontal';
  };

  const isSlim = () => {
    return menuMode === 'slim';
  };

  const hideOverlayMenu = () => {
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const onRightPanelClick = () => {
    rightPanelClick = true;
  };

  const onRightPanelButtonClick = () => {
    setRightPanelActive((prevState) => !prevState);
    rightPanelClick = true;
  };

  const onConfigClick = () => {
    configClick = true;
  };

  const onConfigButtonClick = () => {
    setConfigActive((prevConfigActive) => !prevConfigActive);
    configClick = true;
  };

  const onTopbarSearchToggle = () => {
    setSearchActive((prevState) => !prevState);
    searchClick = true;
  };

  const onTopbarSearchClick = () => {
    searchClick = true;
  };

  const onTopbarUserMenuClick = () => {
    setTopbarUserMenuActive((prevState) => !prevState);
    topbarUserMenuClick = true;
  };

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRippleChange = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      setSearchActive(false);
      searchClick = false;
    }

    if (!topbarUserMenuClick && topbarUserMenuActive) {
      setTopbarUserMenuActive(false);
      topbarUserMenuClick = false;
    }

    if (!rightPanelClick && rightPanelActive) {
      setRightPanelActive(false);
    }

    if (!configClick && configActive) {
      setConfigActive(false);
    }

    if (!menuClick) {
      if (isSlim() || isHorizontal()) {
        setResetActiveIndex(true);
        setMenuActive(false);
      }

      if (staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    searchClick = false;
    topbarUserMenuClick = false;
    rightPanelClick = false;
    configClick = false;
    menuClick = false;
  };

  const onSidebarMouseOver = () => {
    setSidebarActive(!isMobile());
  };

  const onSidebarMouseLeave = () => {
    setSidebarActive(false);
  };

  const onToggleMenu = (event) => {
    menuClick = true;
    setSidebarStatic((prevState) => !prevState);

    event.preventDefault();
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const layoutClassName = classNames(
    'layout-wrapper',
    {
      'layout-sidebar': menuMode === 'sidebar',
      'layout-static': menuMode === 'sidebar' && sidebarStatic,
      'layout-horizontal': menuMode === 'horizontal',
      'layout-rightpanel-active': rightPanelActive,
      'layout-slim': menuMode === 'slim',
      'layout-mobile-active': staticMenuMobileActive,
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple,
    },
    'layout-menu-' + menuScheme + ' layout-topbar-' + topbarScheme
  );

  return (
    <div
      className={`${layoutClassName} ${isPending ? 'pointer-wrapper' : ''}`}
      onClick={onDocumentClick}
    >
      <div className={isPending ? 'pointer-events-none' : ''}>
        <Tooltip
          ref={copyTooltipRef}
          target=".block-action-copy"
          position="bottom"
          content="Copied to clipboard"
          event="focus"
        />

        <AppTopbar
          topbarScheme={topbarScheme}
          onRightPanelButtonClick={onRightPanelButtonClick}
          searchActive={searchActive}
          onTopbarSearchToggle={onTopbarSearchToggle}
          onTopbarSearchClick={onTopbarSearchClick}
          topbarUserMenuActive={topbarUserMenuActive}
          onTopbarUserMenuClick={onTopbarUserMenuClick}
          menu={menu}
          menuActive={menuActive}
          onRootMenuItemClick={onRootMenuItemClick}
          mobileMenuActive={staticMenuMobileActive}
          onMenuItemClick={onMenuItemClick}
          menuMode={menuMode}
          sidebarStatic={sidebarStatic}
          sidebarActive={sidebarActive}
          onSidebarMouseOver={onSidebarMouseOver}
          onSidebarMouseLeave={onSidebarMouseLeave}
          onToggleMenu={onToggleMenu}
          onMenuButtonClick={onMenuButtonClick}
          resetActiveIndex={resetActiveIndex}
          onMenuClick={onMenuClick}
        />

        <AppRightPanel onRightPanelClick={onRightPanelClick} />

        <AppConfig
          configActive={configActive}
          onConfigButtonClick={onConfigButtonClick}
          onConfigClick={onConfigClick}
          menuMode={menuMode}
          onMenuModeChange={onMenuModeChange}
          ripple={ripple}
          onRippleChange={onRippleChange}
          inputStyle={inputStyle}
          onInputStyleChange={onInputStyleChange}
          colorScheme={colorScheme}
          onColorSchemeChange={onColorSchemeChange}
          topbarScheme={topbarScheme}
          onTopbarSchemeChange={onTopbarSchemeChange}
          menuScheme={menuScheme}
          onMenuSchemeChange={onMenuSchemeChange}
          themeScheme={themeScheme}
          onThemeSchemeChange={onThemeSchemeChange}
          theme={theme}
          onThemeChange={onThemeChange}
        />

        <div className="layout-main align-items-center">
          <div className="layout-content w-full">
            <Switch>
              <Route path="/" exact component={GoalsDashboardPage} />

              <Route path="/profile" component={Profile} />
              <Route path="/inputs" component={InputDemo} />
              <Route path="/goal/:id" component={GoalPage} />
              {/* If path route don't exist, redirect to root (/) */}
              <Redirect to="/" />
            </Switch>
          </div>
        </div>

        <div className="layout-mask modal-in"></div>
      </div>
    </div>
  );
};

export default App;
