import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { profileActions, RootState } from 'state';

export const useProfile = () => {
  const state = useSelector((state: RootState) => state.profile);
  const dispatch = useDispatch();

  const { clearProfileData, updateProfile } = bindActionCreators(
    profileActions,
    dispatch
  );

  return {
    ...state,
    profile: state,
    clearProfileData: clearProfileData,
    updateProfile: updateProfile,
  };
};
