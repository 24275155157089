import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';
import styled from 'styled-components';

type PhotoContainerProps = {
  editMode?: boolean;
};

export const AddonBgInherit = styled.span`
  background-color: inherit;
`;

export const TextInfo = styled.div`
  display: flex;
  align-items: center;
  height: 41px;
  background: #eee;
  padding: 0 15px;
`;

export const MenuNoBorder = styled(Menu)`
  border: none;
  width: 100%;
`;

/**
 * Custom photo input styles
 */

export const CustomBadget = styled(Badge)`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin: -3rem 0px 0 0;
  justify-content: center;

  &:hover {
    transform: scale(1.2);
    transition: 0.3s ease !important;
  }
`;

export const PhotoContainer = styled.div<PhotoContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input[type='file'] {
    display: none;
  }

  @keyframes open {
    0% {
      background-position: 166% -25%, 220% -69%;
    }
    100% {
      background-position: 120% -5%, 200% -40%;
    }
  }

  .custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 4px;
    cursor: pointer;
    // background: linear-gradient(270deg, #3fa1a9, #79f1a4);
    background: #1da66e;
    margin-bottom: 20px;
  }

  .img-wrap {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    dsiplay: initial;
  }

  .img-upload:before {
    font-size: 50px;
    position: absolute;
    padding-top: 110px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #1da66e;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    opacity: 0;
    transition: 0.5s ease;
    background: rgba(255, 255, 255, 0.4);
  }

  .img-upload:after {
    content: 'Upload';
    font-size: 18px;
    position: absolute;
    padding-top: 233px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #777;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    opacity: 0;
    transition: 0.5s ease;
    font-family: Manrope, sans-serif;
    font-weight: 500;
  }

  .img-upload:hover:before,
  .img-upload:hover:after {
    ${(props) => props.editMode && 'opacity: 1;'};
  }

  img {
    width: auto;
    height: 100%;
  }

  label {
    text-transform: uppercase;
    font-weight: 700;
    color: #676767;
  }

  input {
    border-radius: 15px;
    border: 1px solid #b7b7b7;
    padding: 5px 5px 5px 10px;
    font-size: 18px;
    transition: 0.2s;
  }

  input:focus {
    outline: none;
    border: 1px solid #64d488;
  }

  input::placeholder {
    color: #bebebe;
  }
`;

export const ExpertDescription = styled.blockquote`
  max-height: 7rem !important;
  overflow-y: auto;
`;
