import { StepType } from 'types';
import StepAvailableItem from './step-available-item';

type StepsAvailableListProps = {
  stepsAvailable: StepType[];
  onAddStepToSolution: (step: StepType) => void;
};

const StepsAvailableList = ({
  stepsAvailable,
  onAddStepToSolution,
}: StepsAvailableListProps) => {
  return (
    <div className="grid">
      {stepsAvailable.map((s) => (
        <StepAvailableItem
          key={s.id}
          step={s}
          onAddStepToSolution={onAddStepToSolution}
        />
      ))}
    </div>
  );
};

export default StepsAvailableList;
