const SIZES = {
  xs: 12,
  sm: 13,
  base: 18,
  md: 20,
  lg: 23,
  xl: 24,
  '2xl': 30,
  '3xl': 36,
  '4xl': 42,
  '5xl': 48,
};

const WEIGHTS = {
  body: 400,
  heading: 600,
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 500,
  bold: 600,
  bolder: 700,
};

const FONTS = {
  body: '',
  heading: '',
  monospace: '',
};

export const FONT_THEME = {
  sizes: SIZES,
  weights: WEIGHTS,
  fonts: FONTS,
};
