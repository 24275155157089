import { ChatContext } from 'context';
import { useContext } from 'react';
import { ChatType } from 'types';

export const useChat = () => {
  const { chats, addChat, addChatMessage } = useContext(ChatContext);

  const retrieveChat = (
    expert_id: string,
    goal_id: string
  ): ChatType | undefined => {
    return chats.chats.find(
      (chat) => chat.expert_id === expert_id && chat.goal_id === goal_id
    );
  };

  return {
    chats,
    addChat,
    addChatMessage,
    retrieveChat,
  };
};
