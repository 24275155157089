import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import { GoalBackground } from 'types';

type GoalListDialogProps = {
  values: GoalBackground;
  open: boolean;
  isQuestion: boolean;
  handleInputChange: any;
  onHide: () => void;
  onDone?: () => void; // If onDone dont passed, use onHide by default
};

export const GoalListDialog = React.memo(
  ({
    open,
    values,
    onDone,
    onHide,
    isQuestion,
    handleInputChange,
  }: GoalListDialogProps) => {
    /**
     * @returns Label string to text inptus dependig case, wishlist or vetting process
     */
    const getLabel = (): string => {
      if (isQuestion) return 'Question';
      else return 'Expected Outcome';
    };

    const getName = (number: number): string => {
      if (isQuestion) return 'question' + number;
      else return 'outcome' + number;
    };

    /**
     * Placeholder Functions
     */

    const getPlaceholder1 = (): string => {
      if (isQuestion)
        return 'What’s your most common approach to a problem like mine?';
      else return 'Grow team by 25% in 4 weeks';
    };

    const getPlaceholder2 = (): string => {
      if (isQuestion)
        return 'Can you send me a sample case study of ways you’ve approached challenges similar to mine?';
      else return 'Modernize your toolstack';
    };

    const getPlaceholder3 = (): string => {
      if (isQuestion)
        return 'Tell me more about your background and experience';
      else return 'Finding solutions that are Out of the Box';
    };

    /**
     * Templates
     */

    const dialogHeader = () => (
      <div>
        {isQuestion
          ? 'List 3 questions you would ask an expert to start the vetting conversation:'
          : 'List 3 expected outcomes for your goal solution:'}
      </div>
    );

    const dialogFooter = () => (
      <div>
        <Button
          className="p-button"
          icon="pi pi-check"
          label="Done"
          onClick={onDone ? onDone : onHide}
        />
      </div>
    );

    return (
      <>
        <Dialog
          visible={open}
          onHide={onHide}
          header={dialogHeader}
          footer={dialogFooter}
          resizable={false}
          draggable={false}
          className="goals_popup"
        >
          <form className="p-fluid" onSubmit={(e) => e.preventDefault()}>
            <div className="field">
              <label>{getLabel()} 1:</label>
              <InputText
                value={isQuestion ? values.question1 : values.outcome1}
                name={getName(1)}
                placeholder={getPlaceholder1()}
                onChange={handleInputChange}
              />
            </div>
            <div className="field">
              <label>{getLabel()} 2:</label>
              <InputText
                value={isQuestion ? values.question2 : values.outcome2}
                name={getName(2)}
                placeholder={getPlaceholder2()}
                onChange={handleInputChange}
              />
            </div>
            <div className="field">
              <label>{getLabel()} 3:</label>
              <InputText
                value={isQuestion ? values.question3 : values.outcome3}
                name={getName(3)}
                placeholder={getPlaceholder3()}
                onChange={handleInputChange}
              />
            </div>
          </form>
        </Dialog>
      </>
    );
  }
);
