import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { SelectButton } from 'primereact/selectbutton';

import { SelecctButtonItem, ErrorMessage } from 'components';
import { CategoryDto, GoalScope, IndustryDto, ProblemSolutionDto } from 'types';
import { classNames } from 'primereact/utils';
import { useMemo } from 'react';

type GoalScopeFormProps = {
  values: GoalScope;
  submited?: boolean;
  editMode?: boolean;
  industries: IndustryDto[];
  categories1?: CategoryDto[];
  categories2?: CategoryDto[];
  categories3?: CategoryDto[];
  problemSolutions?: ProblemSolutionDto[];
  handleInputChange: any;
};

export const GoalScopeForm = ({
  editMode = false,
  submited = false,
  values,
  industries,
  categories1,
  categories2,
  categories3,
  problemSolutions,
  handleInputChange,
}: GoalScopeFormProps) => {
  const notAllowedClass = (): string => {
    if (editMode) return 'cursor-not-allowed';
    else return '';
  };

  const keywordsTargeting: number = useMemo((): number => {
    if (values.category1 && values.category2 && values.category3) return 3;
    if (values.category1 && values.category2) return 2;
    if (values.category1) return 1;
    return 0;
  }, [values.category1, values.category2, values.category3]);

  return (
    <div className="goals_formContainer">
      <div className="text-center mb-5">
        <h4 className="text-dark">
          Refine the exact scope of your goal by filling in the information
          below
        </h4>
      </div>

      <div className="p-fluid">
        <div className={'field ' + notAllowedClass()}>
          <label>What department do you work in? </label>
          <SelectButton
            className={classNames(submited && !values.industry && 'p-invalid')}
            value={values.industry}
            options={industries}
            itemTemplate={(option) => <SelecctButtonItem option={option} />}
            disabled={editMode}
            onChange={(e) =>
              handleInputChange({
                target: { name: 'industry', value: e.value },
              })
            }
          />
          <ErrorMessage>
            {submited && !values.industry && 'Industry is required'}
          </ErrorMessage>
        </div>

        <hr className="my-4" />

        <div className="field">
          <label>
            In at least two words, what is the core of your business problem?
          </label>
          <div className="grid formgrid">
            <div className="col-12 mb-2 lg:col-4 lg:mb-0">
              <div className={'field ' + notAllowedClass()}>
                <label className="required text-sm mb-1">Category 1 </label>
                <Dropdown
                  filter
                  className={classNames(
                    submited && !values.category1 && 'p-invalid'
                  )}
                  name="category1"
                  placeholder="Select"
                  optionLabel="category_name"
                  filterPlaceholder="Search"
                  disabled={!values.industry || editMode}
                  value={values.category1}
                  options={categories1}
                  onChange={handleInputChange}
                />
                <ErrorMessage>
                  {submited && !values.category1 && 'Category 1 is required'}
                </ErrorMessage>
              </div>
            </div>

            <div className="col-12 mb-2 lg:col-4 lg:mb-0">
              <div className={'field ' + notAllowedClass()}>
                <label className="required text-sm mb-1">Category 2 </label>
                <Dropdown
                  filter
                  className={classNames(
                    submited && !values.category2 && 'p-invalid'
                  )}
                  name="category2"
                  placeholder="Select"
                  optionLabel="category_name"
                  filterPlaceholder="Search"
                  options={categories2}
                  value={values.category2}
                  disabled={!values.category1 || editMode}
                  onChange={handleInputChange}
                />
                <ErrorMessage>
                  {submited && !values.category2 && 'Category 2 is required'}
                </ErrorMessage>
              </div>
            </div>

            <div className="col-12 mb-2 lg:col-4 lg:mb-0">
              <div className={'field ' + notAllowedClass()}>
                <label className="text-sm mb-1">
                  Category 3 <em>(optional)</em>
                </label>
                <Dropdown
                  filter
                  name="category3"
                  placeholder="Select"
                  filterPlaceholder="Search"
                  optionLabel="category_name"
                  options={categories3}
                  value={values.category3}
                  disabled={!values.category2 || editMode}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <small className="text-blue-500">
            <span className="mr-2">
              ({keywordsTargeting}) keywords targeting
            </span>
            <span className="mr-2">
              ({problemSolutions?.length}) business problem statements
            </span>
          </small>
        </div>

        <hr className="my-4" />

        <div className={'field ' + notAllowedClass()}>
          <label>Which problem statement best targets your goal? </label>
          <Dropdown
            filter
            name="problemStatement"
            placeholder="Select"
            filterPlaceholder="Search"
            optionLabel="name"
            className={classNames(
              submited && !values.problemStatement && 'p-invalid'
            )}
            options={problemSolutions}
            disabled={!values.category2 || editMode}
            value={values.problemStatement}
            onChange={handleInputChange}
          />
          <ErrorMessage>
            {submited &&
              !values.problemStatement &&
              'Problem statement is required'}
          </ErrorMessage>
        </div>

        <div className="field">
          <label>Describe your problem in your own words: </label>
          <InputTextarea
            rows={5}
            minLength={70}
            className={classNames(
              submited && !values.problemDescription && 'p-invalid'
            )}
            name="problemDescription"
            placeholder="Type here..."
            value={values.problemDescription}
            onChange={handleInputChange}
          />
          <ErrorMessage>
            {submited &&
              !values.problemDescription.trim() &&
              'Description is required'}
          </ErrorMessage>
          {/* <div className="text-right">
            <span
              className={
                values.problemDescription.length > MIN_PROBLEM_DESCRIPTION
                  ? 'text-green-500'
                  : ''
              }
            >
              Minimum
              <span className="mx-1">
                {values.problemDescription.length}/{MIN_PROBLEM_DESCRIPTION}
              </span>
              characters
            </span>
          </div> */}
        </div>
      </div>
    </div>
  );
};
