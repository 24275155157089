import 'react-app-polyfill/ie11';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import AppWrapper from './AppWrapper';
import { store } from 'state';
import ErrorToastMessageProvider from 'context/error/error';

//import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ErrorToastMessageProvider>
        <Suspense fallback={null}>
          <AppWrapper></AppWrapper>
        </Suspense>
      </ErrorToastMessageProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
