import { Dispatch } from 'redux';
import { StepActionEnum } from 'enums';
import { StepType } from 'types';

export const setStep = (step: StepType) => (dispatch: Dispatch) => {
  dispatch({ type: StepActionEnum.SET_STEP, payload: step });
};

export const clearStep = () => (dispatch: Dispatch) => {
  dispatch({ type: StepActionEnum.CLEAR_STEP });
};
