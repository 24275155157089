import { TwoButtons } from 'components';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { CreatePasswordType } from 'types';
import { TextInfo } from './profile.style';

type ProfileSecurityFormProps = {
  email: string;
  values: CreatePasswordType;
  editMode?: boolean;
  handleInputChange: any;
  onActiveEdit?: () => void;
  onCancel?: () => void;
  onSave?: () => void;
};

export const ProfileSecurityForm = ({
  editMode = false,
  email,
  values,
  handleInputChange,
  onActiveEdit,
  onCancel,
  onSave,
}: ProfileSecurityFormProps) => {
  return (
    <div className="flex flex-column justify-content-between h-full">
      <div className="relative mt-3">
        {!editMode && (
          <div className="absolute right-0 top-0">
            <Button
              icon="pi pi-pencil"
              className="p-component p-button"
              onClick={onActiveEdit}
              label="Edit"
            />
          </div>
        )}

        <div className="grid p-fluid px-6">
          <div className="col-12 field mt-8">
            <label>Email</label>
            {editMode ? (
              <InputText
                className="cursor-not-allowed"
                disabled={true}
                value={email}
              />
            ) : (
              <TextInfo>{email}</TextInfo>
            )}
          </div>

          {editMode && (
            <div className="col-12 md:col-6 field">
              <label>Password</label>
              <Password
                toggleMask={true}
                name="password"
                placeholder="Password"
                autoComplete="new-password"
                value={values.password}
                onChange={handleInputChange}
              />
            </div>
          )}

          {editMode && (
            <div className="col-12 md:col-6 field">
              <label>Re-enter Password</label>
              <Password
                toggleMask={true}
                feedback={false}
                name="confirmPassword"
                placeholder="Confirm Password"
                autoComplete="new-password"
                value={values.confirmPassword}
                onChange={handleInputChange}
              />
            </div>
          )}
        </div>
      </div>

      {editMode && (
        <TwoButtons
          className="goals_btm-btns"
          leftLabel="Cancel"
          rightLabel="Save"
          onLeftClick={onCancel}
          onRightClick={onSave}
        />
      )}
    </div>
  );
};
