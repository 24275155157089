export const SITE_INFO = {
  isProductionMode: process.env.NODE_ENV === 'production',
  isProductionServer: process.env.REACT_APP_SERVER === 'production',
  MAIL_ADDRESS_1: '',
  MAIL_ADDRESS_2: '',

  COMPANY_HOURS: '',
  COMPANY_HOURS_CLOSED_ON: '',

  EXTERNAL_CONTACT: '',
  LOCAL_CONTACT: '',
  EMAIL_CONTACT: '',
  TEXT_CONTACT: '',

  INSTAGRAM_LINK: '',
  FACEBOOK_LINK: '',

  SITE_URL:
    process.env.REACT_APP_PROFESSIONAL_BASE_URL ?? 'http://localhost:3000',
  PROVIDER_SITE_URL:
    process.env.REACT_APP_PROVIDER_BASE_URL ?? 'http://localhost:8089',

  GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID,

  APIS: {
    USER_APP: {
      URL: `${
        process.env.REACT_APP_USER_APP_API_URL ?? 'http://localhost:80011'
      }${process.env.REACT_APP_USER_APP_API_TAG ?? '/api'}`,
    },
    GOALS_APP: {
      URL: `${
        process.env.REACT_APP_GOALS_APP_API_URL ?? 'http://localhost:80012'
      }${process.env.REACT_APP_GOALS_APP_API_TAG ?? '/api'}`,
    },
    CHAT_APP: {
      URL: `${
        process.env.REACT_APP_CHAT_APP_API_URL ?? 'http://localhost:80012'
      }${process.env.REACT_APP_CHAT_APP_API_TAG ?? '/api'}`,
    },
  },
};
