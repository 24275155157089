import { ProfileActionEnum } from 'enums';
import { ProfileActionType } from 'types/action-types';
import { ProfileType } from 'types';

const INITIAL_STATE: ProfileType = {
  profilePath: '',
  businessType: '',
  zipCode: '',
  companyTitle: '',
  expertCompanyLogo: '',
  operatingIndustry: '',
  title: '',
};

export const profile = (
  state: ProfileType = INITIAL_STATE,
  action: ProfileActionType
): ProfileType => {
  switch (action.type) {
    case ProfileActionEnum.SET_PROFILE:
      return { ...state, ...action.payload };
    case ProfileActionEnum.CLEAR_PROFILE:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};
