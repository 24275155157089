import { UserGoalsActionEnum } from 'enums';
import { UserGoalSummary } from 'types';
import { UserGoalsActionType } from 'types/action-types';

const INITIAL_STATE: UserGoalSummary[] = [];

export const userGoals = (
  state: UserGoalSummary[] = INITIAL_STATE,
  action: UserGoalsActionType
): UserGoalSummary[] => {
  let _state: UserGoalSummary[];
  let _userGoalIndex: number;
  switch (action.type) {
    case UserGoalsActionEnum.SET_USER_GOALS:
      return [...action.payload];
    case UserGoalsActionEnum.ADD_USER_GOAL:
      _state = { ...state };
      return [...[action.payload], ...state];
    case UserGoalsActionEnum.UPDATE_USER_GOAL:
      _state = [...state];
      _userGoalIndex = _state.findIndex((g) => g.id === action.payload.id);
      const keywords = _state[_userGoalIndex].keywords;
      _state[_userGoalIndex] = { ...action.payload, keywords };
      return [..._state];
    case UserGoalsActionEnum.REMOVE_USER_GOAL:
      _state = [...state];
      _userGoalIndex = _state.findIndex((g) => g.id === action.payload);
      _state.splice(_userGoalIndex, 1);
      return [..._state];
    case UserGoalsActionEnum.CLEAR_USER_GOALS:
      return [...INITIAL_STATE];
    default:
      return state;
  }
};
