import { Dispatch } from '@reduxjs/toolkit';
import { Button } from 'primereact/button';
import { ExpertType } from 'types';
import ExpertSelectedItem from './expert-selected-item';

type ExpertsSelectedListProps = {
  expertsSelected: ExpertType[];
  expertSelected: ExpertType;
  onChooseExpertToMessage: (expert: ExpertType) => (dispatch: Dispatch) => void;
  onSelectMoreExperts: () => void;
};

const ExpertsSelectedList = ({
  expertsSelected,
  expertSelected,
  onChooseExpertToMessage,
  onSelectMoreExperts,
}: ExpertsSelectedListProps) => {
  return (
    <div>
      <small>Experts</small>
      <hr />
      <div className="flex md:flex-column">
        {expertsSelected.map((expert, index) => (
          <ExpertSelectedItem
            key={index}
            expert={expert}
            expertSelected={expertSelected}
            onChooseExpertToMessage={onChooseExpertToMessage}
          />
        ))}
        <div className="text-center ml-2 md:ml-0 ">
          <Button icon="pi pi-plus m-auto" onClick={onSelectMoreExperts} />
        </div>
      </div>
    </div>
  );
};

export default ExpertsSelectedList;
