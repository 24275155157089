import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormHandleSubmit,
} from 'react-hook-form';

import { AuthRedirectButton } from './auth-redirect-button';
import { FormContainer } from './login.style';
import { UserType } from 'types';
import { ErrorMessage } from 'components';

type SignupFormProps = {
  terms: boolean;
  errors: FieldErrors<UserType>;
  control: Control<UserType, any>;
  handleSubmit: UseFormHandleSubmit<UserType>;
  loading: boolean;
  openPrivacyPolicyDialog: () => void;
  openTermsServiceDialog: () => void;
  onSubmit: (user: UserType) => void;
  onAcceptTerms: () => void;
  onRedirectSignIn: () => void;
};

export const SignupForm = ({
  terms,
  errors,
  control,
  loading,
  handleSubmit,
  openTermsServiceDialog,
  openPrivacyPolicyDialog,
  onRedirectSignIn,
  onAcceptTerms,
  onSubmit,
}: SignupFormProps) => {
  return (
    <>
      <FormContainer>
        <form
          className="p-fluid"
          autoComplete="off"
          onSubmit={handleSubmit((user) => {
            onSubmit(user);
          })}
        >
          <div className="field">
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  className={classNames(errors.firstName && 'p-invalid')}
                  placeholder="First name"
                  autoComplete="new"
                />
              )}
            />
            {errors.firstName && (
              <ErrorMessage>{errors.firstName.message}</ErrorMessage>
            )}
          </div>

          <div className="field">
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  className={classNames(errors.lastName && 'p-invalid')}
                  placeholder="Last name"
                  autoComplete="new"
                />
              )}
            />
            {errors.lastName && (
              <ErrorMessage>{errors.lastName.message}</ErrorMessage>
            )}
          </div>

          <div className="field">
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  className={classNames(errors.phone && 'p-invalid')}
                  placeholder="Phone number"
                  type="tel"
                />
              )}
            />
            {errors.phone && (
              <ErrorMessage>{errors.phone.message}</ErrorMessage>
            )}
          </div>

          <div className="field">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  className={classNames(errors.email && 'p-invalid')}
                  type="email"
                  autoComplete="new"
                  placeholder="Email"
                />
              )}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </div>

          <div className="field">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Password
                  {...field}
                  className={classNames(errors.password && 'p-invalid')}
                  placeholder="Password"
                  autoComplete="new-password"
                  toggleMask={true}
                  ref={(ref) => field.ref({ focus: () => {} })}
                />
              )}
            />
            {errors.password && (
              <ErrorMessage>{errors.password.message}</ErrorMessage>
            )}
          </div>

          <div className="field">
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <Password
                  {...field}
                  className={classNames(errors.confirmPassword && 'p-invalid')}
                  placeholder="Confirm password"
                  toggleMask={true}
                  feedback={false}
                  autoComplete="new-password"
                  ref={(ref) => field.ref({ focus: () => {} })}
                />
              )}
            />
            {errors.confirmPassword && (
              <ErrorMessage>{errors.confirmPassword.message}</ErrorMessage>
            )}
          </div>

          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <div className="field-checkbox py-3">
            <Checkbox name="terms" checked={terms} onChange={onAcceptTerms} />
            <label className="text-left agreement-checkbox">
              By selecting this checkbox, I have reviewed and agree to the{' '}
              <a onClick={openTermsServiceDialog} className="p-link underline">
                Terms of Service
              </a>
              {' and '}
              <a onClick={openPrivacyPolicyDialog} className="p-link underline">
                Privacy Policy
              </a>
            </label>
          </div>

          <div className="field">
            <Button
              disabled={!terms || loading}
              type="submit"
              label="Sign up"
            />
          </div>
        </form>

        <AuthRedirectButton
          message="Already have an account?"
          linkLabel="Sign in here"
          onRedirect={onRedirectSignIn}
        />
      </FormContainer>
    </>
  );
};
