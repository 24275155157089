/**
 * SIZES
 * In bytes
 */
export const DOCS_MAX_SIZE = 5242880;

/**
 * Extension accepts
 */
export const DOCS_ACCEPT = `
  .pdf,
  .doc,
  .docx,
  .xml,
  application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
