import { UserActionEnum } from 'enums';
import { UserActionType } from 'types/action-types';
import { UserType } from 'types';

const INITIAL_STATE: UserType = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  last_login_at: '',
  linkedinId: '',
  phone: '',
  profilePath: '',
};

export const user = (
  state: UserType = INITIAL_STATE,
  action: UserActionType
): UserType => {
  switch (action.type) {
    case UserActionEnum.SET_USER:
      return { ...state, ...action.payload };
    case UserActionEnum.CLEAR_USER:
      return { ...state, ...INITIAL_STATE };
    default:
      return state;
  }
};
