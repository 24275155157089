import { Dispatch } from 'redux';

import { loginUrl, resetPasswordUrl, socialAuth } from 'apis';
import { httpClient } from 'state/actions/http-client';
import { IHttpClientRequestParams } from 'types/Interfaces/IHttpClientRequestParams';
import {
  SocialAuthDto,
  PostLoginDto,
  PostLoginResponseDto,
  ResetPasswordDto,
} from 'types';
import {
  AuthActionType,
  UserActionType,
  ProfileActionType,
  UserGoalsActionType,
  TrendingDataActionType,
} from 'types/action-types';
import {
  UserActionEnum,
  AuthActionEnum,
  ProfileActionEnum,
  UserGoalsActionEnum,
  TrendingDataActionEnum,
} from 'enums';

export const login =
  (data: PostLoginDto) =>
  async (
    dispatch: Dispatch<AuthActionType | ProfileActionType | UserActionType>
  ) => {
    try {
      const parameters: IHttpClientRequestParams = {
        url: loginUrl,
        requiresToken: false,
        payload: data,
      };

      const resp = await httpClient.post<PostLoginDto, PostLoginResponseDto>(
        parameters
      );

      dispatch({ type: AuthActionEnum.LOGIN, payload: resp.auth });
      dispatch({ type: UserActionEnum.SET_USER, payload: resp.user });
      dispatch({ type: ProfileActionEnum.SET_PROFILE, payload: resp.profile });
    } catch (error) {
      throw error;
    }
  };

export const googleAuth =
  (data: SocialAuthDto, social: 'google' | 'linkedin') =>
  async (
    dispatch: Dispatch<AuthActionType | ProfileActionType | UserActionType>
  ) => {
    try {
      const parameters: IHttpClientRequestParams = {
        url: socialAuth + social,
        requiresToken: false,
        payload: data,
      };

      const resp = await httpClient.post<SocialAuthDto, PostLoginResponseDto>(
        parameters
      );

      dispatch({ type: AuthActionEnum.LOGIN, payload: resp.auth });
      dispatch({ type: UserActionEnum.SET_USER, payload: resp.user });
      dispatch({ type: ProfileActionEnum.SET_PROFILE, payload: resp.profile });
      return resp;
    } catch (error) {
      throw error;
    }
  };

export const resetPassword =
  (data: ResetPasswordDto) => async (dispatch: Dispatch) => {
    try {
      const parameters: IHttpClientRequestParams = {
        url: resetPasswordUrl,
        requiresToken: false,
        payload: data,
      };

      const resp = await httpClient.post<ResetPasswordDto, any>(parameters);

      return resp;
    } catch (error) {
      throw error;
    }
  };

export const logout =
  () =>
  (
    dispath: Dispatch<
      | AuthActionType
      | ProfileActionType
      | UserActionType
      | UserGoalsActionType
      | TrendingDataActionType
    >
  ) => {
    try {
      dispath({ type: AuthActionEnum.LOGOUT });
      dispath({ type: ProfileActionEnum.CLEAR_PROFILE });
      dispath({ type: UserActionEnum.CLEAR_USER });
      dispath({ type: UserGoalsActionEnum.CLEAR_USER_GOALS });
      dispath({ type: TrendingDataActionEnum.CLEAR_TRENDING_DATA });
    } catch (error) {
      throw error;
    }
  };
