import React from 'react';
import { IconImageWrapper } from './goal-list-item.style';
import { ExpertsAvailableIcon, StepsReadyIcon } from 'assets';

type GoalListItemFooterProps = {
  stepsReady: number;
  expertsAvailable: number;
};

const GoalListItemFooter = ({
  stepsReady,
  expertsAvailable,
}: GoalListItemFooterProps) => {
  return (
    <div className="flex flex-row flex-wrap card-container surface-50">
      <div className="flex align-items-center justify-content-start w-auto m-2">
        <IconImageWrapper>
          <StepsReadyIcon />
          {/* <img
            src={StepsReadyIcon}
            alt="steps ready"
            className="inline mr-1 w-full"
          /> */}
        </IconImageWrapper>
        <div className="inline p-1 ml-2 font-bold text-lg">{stepsReady}</div>
        <div className="inline p-1 pl-0">{' steps ready'}</div>
      </div>

      <div className="flex align-items-center justify-content-start w-auto m-2">
        <IconImageWrapper>
          <ExpertsAvailableIcon />
        </IconImageWrapper>
        <div className="inline p-1 ml-2 font-bold text-lg">{expertsAvailable}</div>
        <div className="inline p-1 pl-0">{' experts available'}</div>
      </div>
    </div>
  );
};

export default GoalListItemFooter;
