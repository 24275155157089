import { SITE_INFO } from '@constants';

const baseUrl = SITE_INFO.APIS.GOALS_APP.URL;

export const fetchUserGoalsUrl = '/goal/n';
export const userGoalUrl = '/goal';

export const matchSolutionPlanUrl = '/search/n_match';

export const professionalSolutionSteps = '/professional-solution-steps/n';

export const solutionExpertsUrl = '/experts-category-steps/n';

export const listIndustriesUrl = baseUrl + '/category/n';

export const listProblemSolutionByCategoriesUrl =
  baseUrl + '/category/n_categoryGoals';
