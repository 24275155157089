import { StepType } from 'types';
import { StepAvailableContainer } from './steps-selection.style';

type StepAvailableItemProps = {
  step: StepType;
  onAddStepToSolution: (step: StepType) => void;
};
const StepAvailableItem = ({
  step,
  onAddStepToSolution,
}: StepAvailableItemProps) => {
  const { title, n_times } = step;
  return (
    <div className="col-6 md:col-4">
      <StepAvailableContainer
        className="flex flex-column cursor-pointer justify-content-between h-full shadow-2 border-round text-center p-3"
        onClick={() => onAddStepToSolution(step)}
      >
        <div className="title font-bold">{title}</div>

        <div className="text-gray-400">
          <small className="mr-1">Used by</small>
          <i className="pi pi-users" />
          <small className="mx-1">{n_times}</small>
          <small>similar people</small>
        </div>
      </StepAvailableContainer>
    </div>
  );
};

export default StepAvailableItem;
