export const severity = {
  success: 'success',
  info: 'info',
  warn: 'warn',
  error: 'error',
};

export const summary = {
  success: 'Success',
  info: 'Information',
  warn: 'Warning',
  error: 'Error',
};

export const detail = {
  success: '',
  info: '',
  warn: '',
  error: '',
};

export const life = {
  small: 1000,
  normal: 3000,
  long: 5000,
};

export const TOAST = {
  severity,
  summary,
  detail,
  life,
};
