import { Button } from 'primereact/button';

type DoneButtonProps = {
  onDone?: (e?: any) => void;
};

export const DoneButton = ({ onDone }: DoneButtonProps) => {
  return (
    <Button
      icon="pi pi-check"
      className="p-button-success"
      label="Done"
      onClick={onDone}
    />
  );
};
