import styled from 'styled-components';

export const TermsServiceContainer = styled.div`
  .title {
    text-align: center;
  }
  h1 {
    color: #111827;
    font-size: 2.25em;
    font-weight: 800;
    line-height: 1.1111111;
    margin-bottom: 0.8888889em;
    margin-top: 0;
  }
  p {
    margin-bottom: 1.25em;
    margin-top: 1.25em;
  }
  h3 {
    color: #111827;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 0.6em;
    margin-top: 1.6em;
  }
`;

export const PrivacyPolicyContainer = styled.div`
  .title {
    text-align: center;
  }
  h1 {
    color: #111827;
    font-size: 2.25em;
    font-weight: 800;
    line-height: 1.1111111;
    margin-bottom: 0.8888889em;
    margin-top: 0;
  }
  p {
    margin-bottom: 1.25em;
    margin-top: 1.25em;
  }
  h3 {
    color: #111827;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1.6;
    margin-bottom: 0.6em;
    margin-top: 1.6em;
  }
`;
