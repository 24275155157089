import { useContext } from 'react';
import { ToastContext } from 'context';

export const useToast = () => {
  const { showToast, showErrorToast, showSuccessToast } =
    useContext(ToastContext);

  return {
    showToast,
    showErrorToast,
    showSuccessToast,
  };
};
