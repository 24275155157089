import { createContext } from 'react';

type ToastContextProps = {
  showToast: (severity: string, summary: string, detail: string) => void;
  showSuccessToast: (summary?: string, detail?: string) => void;
  showErrorToast: (summary?: string, detail?: string | unknown) => void;
};

export const ToastContext = createContext<ToastContextProps>(
  {} as ToastContextProps
);
