import { IS_ADDING_EXPERTS, IS_MESSAGING } from '@constants';
import { Dispatch } from '@reduxjs/toolkit';
import { ExpertType, MessageDto, StepType } from 'types';
import { SolutionBuilderStateType } from 'types';
import ExpertsMessaging from '../experts-messaging/experts-messaging';
import ExpertsSelection from './experts-selection';
import NoExpertsFound from './no-experts-found';
import { useSolutionBuilder } from 'hooks';

type ExpertsEngagementProps = {
  chosenStep: StepType;
  allExperts: ExpertType[];
  messages: MessageDto[];
  expertsAvailable: ExpertType[];
  expertsSelected: ExpertType[];
  selectedExpert: ExpertType;
  openExpertDeleteDialog: (expert: ExpertType) => void;
  openExpertProfileDialog: (expert: ExpertType) => void;
  openExpertsFilterDialog: () => void;
  onSendMessage: (message: string) => void;
  onAddExpert: (expert: ExpertType) => void;
  onRemoveExpert: (expert: ExpertType) => void;
  onChooseExpertToMessage: (expert: ExpertType) => (dispatch: Dispatch) => void;
};

export const ExpertsEngagement = ({
  onAddExpert,
  onRemoveExpert,
  messages,
  chosenStep,
  allExperts,
  expertsSelected,
  openExpertsFilterDialog,
  onChooseExpertToMessage,
  selectedExpert,
  openExpertProfileDialog,
  onSendMessage,
  openExpertDeleteDialog,
}: ExpertsEngagementProps) => {
  const { title: stepTitle } = chosenStep;
  const { state, setBuilderState } = useSolutionBuilder();

  const expertsStateComponent = ({ value }: SolutionBuilderStateType) => {
    switch (value) {
      case IS_ADDING_EXPERTS:
        return (
          <ExpertsSelection
            stepTitle={stepTitle}
            allExperts={allExperts}
            onAddExpert={onAddExpert}
            openExpertDeleteDialog={openExpertDeleteDialog}
            onDoneSelecting={setBuilderState}
            openExpertsFilterDialog={openExpertsFilterDialog}
            openExpertProfileDialog={openExpertProfileDialog}
          />
        );
      case IS_MESSAGING:
        return (
          <ExpertsMessaging
            stepTitle={stepTitle}
            messages={messages}
            onRemoveExpert={onRemoveExpert}
            selectedExpert={selectedExpert}
            expertsSelected={expertsSelected}
            onSelectMoreExperts={setBuilderState}
            onChooseExpertToMessage={onChooseExpertToMessage}
            onSendMessage={onSendMessage}
            openExpertProfileDialog={openExpertProfileDialog}
          />
        );
      default:
        return <NoExpertsFound />;
    }
  };

  return (
    <div className="flex flex-column h-full p-5">
      {/* <div className="flex font-bold text-gray-400 text-base mb-3">
        <i className="pi pi-comments text-2xl mr-2"></i>
        <span>Team Messaging</span>
      </div> */}
      <h2>Messaging</h2>
      {expertsStateComponent(state)}
    </div>
  );
};

export default ExpertsEngagement;
