import { FONT_THEME } from '@constants';
import styled from 'styled-components';

export const GoalItemContainer = styled.div`
  margin: 1rem 0 2rem 0;
  .p-card-body {
    padding: 0;
  }

  .p-card-content {
    padding: 14px 14px 0 14px;
  }

  .col-11 {
    padding: 0;
    border-right: 1px solid #eee;
  }

  &: last-child {
    margin: 2rem 0 3rem 0;
  }
  &: first-child {
    margin: 5px 0 3rem 0;
  }
`;

export const GoalTitleStyle = styled.div`
  font-weight: bold;
  font-size: ${FONT_THEME.sizes.md};
`;

export const IndustryContainer = styled.span`
  display: inline;
  color: red;
  width: 100%;

  > div {
    display: inline-block;
  }
`;

export const IconImageWrapper = styled.div`
  width: 11px;
`;
