import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { appActions, RootState } from 'state';

export const useApplication = () => {
  const state = useSelector((state: RootState) => state.applicationState);
  const dispatch = useDispatch();

  const { setIsPending, clearAppState } = bindActionCreators(
    appActions,
    dispatch
  );

  return {
    isPending: state.isPending,
    setIsPending,
    clearAppState,
  };
};
