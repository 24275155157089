import { Button } from 'primereact/button';
import React from 'react';

type NoDelectedDisplayProps = {
  buttonIcon: string;
  title?: React.ReactNode | string;
  buttonLabel?: string;
  onSelect: () => void;
};

export const NoSelectedDisplay = ({
  buttonIcon = 'pi pi-plus',
  title,
  buttonLabel,
  onSelect,
}: NoDelectedDisplayProps) => {
  return (
    <div className="flex flex-column justify-content-center align-items-center h-full">
      <div className="text-center text-base font-normal mb-4">{title}</div>
      <Button
        label={buttonLabel}
        icon={buttonIcon + ' p-1 bg-green-100 border-round'}
        className="p-button-text"
        onClick={() => onSelect()}
      />
    </div>
  );
};
