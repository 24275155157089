import { Dispatch } from 'redux';
import { ApplicationStatesActionEnum } from 'enums';
import { ApplicationStatesType } from 'types';

export const setIsPending =
  (state: ApplicationStatesType) => (dispatch: Dispatch) => {
    dispatch({
      type: ApplicationStatesActionEnum.SET_IS_PENDING,
      payload: state,
    });
  };

export const clearAppState = () => (dispatch: Dispatch) => {
  dispatch({ type: ApplicationStatesActionEnum.CLEAR_STATE });
};
