import { breakPoints } from '@constants';
import styled from 'styled-components';

export const MainCardContainer = styled.div`
  > div {
    /**
      * height: 1px property for fix tmp the next problem
      * https://stackoverflow.com/a/21836870/15725189
    **/
    height: 1px;

    min-height: 83vh;
  }

  @media screen and (max-width: ${breakPoints.sm}px) {
    > div {
      height: fit-content;
    }
  }
`;
