import React from 'react';
import { classNames } from 'primereact/utils';

type Props = {
  spacing?: boolean;
  border?: boolean;
  threeComponent: React.ReactNode;
  eightComponent: React.ReactNode;
};

export const FourEightLayout = ({
  threeComponent,
  eightComponent,
  spacing = true,
  border = false,
}: Props) => {
  return (
    <div className={classNames('grid h-full', !spacing && 'm-0')}>
      <div
        className={classNames(
          'col-12 md:col-4',
          !spacing && 'p-0',
          border && 'md:border-right-1',
          border && 'surface-border'
        )}
      >
        {threeComponent}
      </div>
      <div className={classNames('col-12 md:col-8', !spacing && 'p-0')}>
        {eightComponent}
      </div>
    </div>
  );
};
