type Props = {
  icon?: string;
  title: string;
  description: string;
};

export const NoStepsSelectedInfoItem = ({
  icon,
  title,
  description,
}: Props) => {
  return (
    <div className="col-12 md:col-4 text-base">
      <div className="font-semibold text-black-alpha-90">
        <i className={icon + ' mr-2'} />
        {title}
      </div>
      <div className="mt-2">{description}</div>
    </div>
  );
};
