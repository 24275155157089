import { httpClient } from 'state/actions/http-client';
import { IHttpClientRequestParams } from 'types/Interfaces/IHttpClientRequestParams';
import { Dispatch } from 'redux';
import { RootState } from 'state';

import { userUrl, createUserUrl, updateSecurityUrl } from 'apis';
import { UserActionType } from 'types/action-types';
import { UserActionEnum } from 'enums';
import { UserCreateDto, UserType } from 'types';

export const updateUser =
  (data: UserType) =>
  async (dispatch: Dispatch<UserActionType>, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;

      const formData = new FormData();

      formData.append('firstName', data.firstName);
      formData.append('lastName', data.lastName);
      formData.append('phone', data.phone);
      formData.append('linkedinId', data.linkedinId || '');
      formData.append('profilePath', data.profilePath || '');

      const parameters: IHttpClientRequestParams = {
        url: userUrl,
        requiresToken: true,
        payload: formData,
        customToken: token,
        authorizationPrefix: tokenType,
      };
      const resp = await httpClient.post<UserType, UserType>(parameters);
      dispatch({ type: UserActionEnum.SET_USER, payload: resp });
    } catch (error) {
      throw error;
    }
  };

export const createUser =
  (data: UserType) =>
  async (dispatch: Dispatch<UserActionType>, getState: () => RootState) => {
    const body: UserCreateDto = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      password: data.password || '',
      phone: data.phone || '',
      role: 'professional',
    };

    try {
      const parameters: IHttpClientRequestParams = {
        url: createUserUrl,
        requiresToken: false,
        payload: body,
      };

      const resp = await httpClient.post<UserCreateDto, UserType>(parameters);
      return resp;
    } catch (error) {
      throw error;
    }
  };

export const updateSecurity =
  (data: UserType) => async (dispatch: Dispatch, getState: () => RootState) => {
    const formData = new FormData();

    formData.append('firstName', data.firstName);
    formData.append('lastName', data.lastName);
    formData.append('profilePath', data.profilePath || '');
    formData.append('phone', data.phone);
    formData.append('secondaryPhone', data.phone);
    formData.append('linkedinId', data.linkedinId || '');
    formData.append('linkedin', data.linkedinId || '');
    formData.append('password', data.password || '');

    try {
      const { token, tokenType } = getState().auth;

      const parameters: IHttpClientRequestParams = {
        url: updateSecurityUrl,
        requiresToken: true,
        payload: formData,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const resp = await httpClient.post<UserCreateDto, UserType>(parameters);
      return resp;
    } catch (error) {
      throw error;
    }
  };

export const clearUser = () => (dispath: Dispatch<UserActionType>) => {
  dispath({ type: UserActionEnum.CLEAR_USER });
};
