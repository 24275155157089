import { Toast } from 'primereact/toast';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';

interface Props {
  children: React.ReactNode;
}

export type ErrorToastMessageContextType = {
  showError: (error: unknown) => void;
  toasErrorMessage: React.ReactNode;
};

export const ErrorToastMessagesContext = createContext(
  {} as ErrorToastMessageContextType
);

export default function ErrorToastMessageProvider({ children }: Props) {
  const [error, setError] = useState<string | null>(null);
  const toasErrorMessage = useRef<Toast>();

  useEffect(() => {
    if (error) {
      toasErrorMessage?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000,
      });
    }
  }, [error]);

  const showError = (error: unknown) => {
    if (error instanceof Error) setError(error.message);
    setError(String(error));
  };

  return (
    <ErrorToastMessagesContext.Provider
      value={{ showError: showError, toasErrorMessage: toasErrorMessage }}
    >
      {children}
    </ErrorToastMessagesContext.Provider>
  );
}

export const useErrorToastMessage = () => useContext(ErrorToastMessagesContext);
