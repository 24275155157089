import { breakPoints, FONT_THEME } from '../../@constants';
import { Button } from 'primereact/button';
import styled from 'styled-components';

/**
 * Auth menu styles
 */

export const MenuContainer = styled.div`
  width: 100% !important;

  @media screen and (max-width: ${breakPoints.sm}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

/**
 * Auth title styles
 */

export const AuthTitle = styled.div`
  font-size: ${FONT_THEME.sizes.md}px;
  font-weight: 700;
  color: #1D1D21;
  text-align: center;
  padding: 40px 0px;

  @media screen and (max-width: ${breakPoints.sm}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

/**
 * Buttons options styles
 */

export const OptionsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: ${breakPoints.sm}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const OptionButton = styled(Button)`
  width: 100%;
  border-color: gray !important;
  width: 370px !important;

  @media screen and (max-width: ${breakPoints.sm}px) {
    width: 100% !important;
  }
`;
