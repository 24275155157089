import {
  HAS_NO_STEPS_FOUND,
  HAS_NO_STEPS_SELECTED,
  HAS_STEPS_SELECTED,
} from '@constants';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { goalsActions, RootState } from 'state';
import { StepType } from 'types';
import { GoalEngagementStepsStateType } from 'types/goal-engagement-state';

const getCurrentStatus = (
  totalSteps: StepType[]
): GoalEngagementStepsStateType => {
  if (totalSteps.length === 0) {
    return { value: HAS_NO_STEPS_FOUND };
  } else if (!!totalSteps.find((s) => s.isChosen)) {
    return { value: HAS_STEPS_SELECTED };
  } else {
    return { value: HAS_NO_STEPS_SELECTED };
  }
};

export const useGoal = () => {
  const goal = useSelector((state: RootState) => state.goal);
  const _step = useSelector((state: RootState) => state.step);
  const step = goal.steps.find((s) => s.id === _step.id);
  const dispatch = useDispatch();

  const {
    fetchUserGoals,
    fetchGoalStepsExperts,
    addStepToGoal,
    removeStepFromGoal,
    clearGoalSteps,
    removeExpertFromStep,
    addExpertToStep,
    deleteUserGoal,
  } = bindActionCreators(goalsActions, dispatch);

  const stepsSelected = goal.steps.filter((s) => s.isChosen);

  const stepsNotSelected = goal.steps.filter((s) => !s.isChosen);

  const currentStepsState = getCurrentStatus(goal.steps);

  const expertsSelected = step?.experts.filter((e) => e.isChosen) ?? [];

  const expertsNotSelected = step?.experts.filter((e) => !e.isChosen) ?? [];

  const allExperts = step?.experts ?? [];

  return {
    ...goal,
    fetchUserGoals,
    fetchGoalStepsExperts,
    addStepToGoal,
    removeStepFromGoal,
    stepsSelected,
    stepsNotSelected,
    currentStepsState,
    clearGoalSteps,
    removeExpertFromStep,
    addExpertToStep,
    expertsSelected,
    expertsNotSelected,
    allExperts,
    deleteUserGoal,
  };
};
