import { CustomBadget, PhotoContainer } from './profile.style';

type ImgUploadProps = {
  src: any;
  name?: string;
  editMode?: boolean;
  onChange: (value?: string | File) => void;
};

// https://codepen.io/OlgaKoplik/pen/ZdyKGY
export const ImgUpload = ({
  src,
  name,
  onChange,
  editMode = true,
}: ImgUploadProps) => (
  <PhotoContainer editMode={editMode}>
    <label htmlFor="photo-upload" className="custom-file-upload fas">
      <div className="img-wrap img-upload pi pi-upload block">
        <img src={src} alt="profile" />
      </div>

      {editMode && (
        <input
          name={name}
          id="photo-upload"
          type="file"
          onChange={(e) => {
            if (e.target.files) {
              onChange(e.target.files[0]);
            }
          }}
        />
      )}

      {editMode && (
        <CustomBadget
          className="absolute pointer transition-colors bg-primary"
          value={<li className="pi pi-pencil"></li>}
        />
      )}
    </label>
  </PhotoContainer>
);
