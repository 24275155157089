import { SITE_INFO } from '@constants';

const BASE_URL = SITE_INFO.APIS.USER_APP.URL;

export const loginUrl = BASE_URL + '/n_login';
export const socialAuth = BASE_URL + '/sociallogin/';
export const profileUrl = '/profile/n';
export const userUrl = BASE_URL + '/user/n';
export const updateSecurityUrl = BASE_URL + '/loginupdate';

export const createUserUrl = BASE_URL + '/register';
export const resetPasswordUrl = BASE_URL + '/reset';
