import { Dispatch } from 'redux';

import { listProblemSolutionByCategoriesUrl } from 'apis';
import { RootState, httpClient } from 'state';
import {
  IHttpClientRequestParams,
  ListByCategoriesParams,
  ListProblemSolutionDto,
} from 'types';

export const listProblemSolutionByCategories =
  (body: ListByCategoriesParams) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const { token, tokenType } = getState().auth;
      let pathParams = `?primary=${body.primary}&secondary=${body.secondary}`;
      if (body.tertiary) pathParams += `&tertiary=${body.tertiary}`;

      const parameters: IHttpClientRequestParams = {
        url: listProblemSolutionByCategoriesUrl + pathParams,
        requiresToken: true,
        customToken: token,
        authorizationPrefix: tokenType,
      };

      const resp = await httpClient.get<ListProblemSolutionDto>(parameters);

      return resp.problem_solutions;
    } catch (error) {
      throw error;
    }
  };
