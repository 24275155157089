import { Svg } from 'components/ui/svg/svg';
export const StepsReadyIcon = ({ color = 'currentColor', ...restProps }) => {
  return (
    <Svg {...restProps} viewBox="0 -4 24 24">
      <g fill={color}>
        <path d="m11.3 13.7c0-.53-.47-1-1-1h-7.3c-.53 0-1 .47-1 1v5.3c0 .53.47 1 1 1h7.3c.53 0 1-.47 1-1zm10.7 0c0-.53-.47-1-1-1h-7.3c-.53 0-1 .47-1 1v5.3c0 .53.47 1 1 1h7.3c.53 0 1-.47 1-1zm-7.8.5h6.3v4.3h-6.3zm-10.7 0h6.3v4.3h-6.3zm18.5-9.2c0-.53-.47-1-1-1h-7.3c-.53 0-1 .47-1 1v5.3c0 .53.47 1 1 1h7.3c.53 0 1-.47 1-1zm-10.7 0c0-.53-.47-1-1-1h-7.3c-.53 0-1 .47-1 1v5.3c0 .53.47 1 1 1h7.3c.53 0 1-.47 1-1zm2.9.5h6.3v4.3h-6.3zm-10.7 0h6.3v4.3h-6.3z" />
      </g>
    </Svg>
  );
};
